import React, { useState, useEffect } from "react";
import { Typography, Icon } from "@formant/ui-sdk";
import "./Header.css";
import { Badge, Popover } from "@mui/material";
import APIActions from "../Utility/APIActions";
import SoftwareVersions, { UNKNOWN_SOFTWARE_VERSION, softwareNameLookup, unknownSoftwareVersions } from "../../ts/interfaces/SoftwareVersions";

// Assets
import BackIcon from "../../Assets/Icons/Extended Map/Back.png";
import SearchIcon from "../../Assets/Icons/Robots Inner Page/Group 2324.png";
import NotificationIcon from "../../Assets/Icons/Robots Inner Page/Group 2323.png";
import ProfileIcon from "../../Assets/Icons/Robots Inner Page/Group 3.png";
import SettingsIcon from "../../Assets/Icons/Extended Map/Group 2322.png";

interface HeaderProps {
  handleBackButton?: () => void,
  currentVersions?: SoftwareVersions,
  handleEventsButton?: () => void,
  robotDisplayName?: string,
  eventsCount?: number,
}

export default function Header(props: HeaderProps) {
  const [latestSoftwareVersions, setLatestSoftwareVersions] = useState<SoftwareVersions>(unknownSoftwareVersions);
  //const [currentSoftwareVersions, setCurrentSoftwareVersions] = useState<SoftwareVersions>(props.currentVersions);
  const [popoverAnchorElement, setPopoverAnchorElement] = useState<HTMLElement | null>(null);
  const popoverOpen = Boolean(popoverAnchorElement);
  const handlePopoverClose = () => { setPopoverAnchorElement(null); }
  const handlePopoverOpen = (event) => { setPopoverAnchorElement(event.currentTarget); }

  const queryForDesiredSoftwareVersions = async() => {
    const databaseVersions = await APIActions.getDesiredSoftwareVersions();
    setLatestSoftwareVersions(databaseVersions);
  }

  useEffect(() => {
    queryForDesiredSoftwareVersions();
  }, [])

  let headerClassName = "Header";
  let backButtonContent = <></>
  if (props.handleBackButton !== undefined) {
    headerClassName = "HeaderDetailed";
    backButtonContent = <img className="HeaderIcon" onClick={() => props.handleBackButton()} src={BackIcon} alt="Missing Asset" style={{cursor: "pointer"}}></img>
  }

  let robotNameContent = <Typography className="HeaderIcon" variant="h1" sx={{ color: "white", fontSize: 32, justifySelf: "left", marginLeft: 5 }}>Dashboard</Typography>
  if (props.robotDisplayName) {
    robotNameContent = <Typography className="HeaderIcon" variant="h1" sx={{ color: "white", fontSize: 32 }}>{props.robotDisplayName}</Typography>
  }

  let eventsButtonContent = <img className="HeaderIconDeactivated" src={NotificationIcon} alt="Missing Asset"></img>
  if (props.handleEventsButton !== undefined) {
    const badgeContent = props.eventsCount ? props.eventsCount : 0;
    eventsButtonContent = (
      <Badge badgeContent={badgeContent} color="warning" overlap="circular">
      <img className="HeaderIcon" 
           onClick={() => props.handleEventsButton()} 
           src={NotificationIcon} 
           alt="Missing Asset" 
           style={{cursor: "pointer"}}>
      </img>
      </Badge>
    );
  }

  const versionIsWrong = (version) => {
    return props.currentVersions[version] === UNKNOWN_SOFTWARE_VERSION || props.currentVersions[version] !== latestSoftwareVersions[version]
  }

  let versionContent = <Typography>No versions reported.</Typography>
  const versionsGiven = Object.keys(props.currentVersions);
  if (versionsGiven.length > 0) {
    versionContent = (
      <div className="VersionPopoverContent">
        {versionsGiven.map((version, index) => { return (
          <div className="VersionRow" key={index}>
            <Typography className="VersionLabel" sx={{pr: '0.1rem'}}>{softwareNameLookup[version]} version:</Typography>
            <Typography className="CurrentVersionText" sx={{fontWeight: 'bold'}}>{props.currentVersions[version]}</Typography>
            {versionIsWrong(version) && <>
              <Icon name="error" sx={{display: "inline", scale: "70%"}}/>
              <Typography className="DesiredVersionText">{latestSoftwareVersions[version]} available</Typography>
            </>}
          </div>
        )})}
      </div>
    );
  }

  const badgeContent = versionsGiven.filter((version) => { return versionIsWrong(version) }).length;
  let versionButtonContent = <img className="HeaderIconDeactivated" src={SearchIcon} alt="Missing Asset"></img>
  versionButtonContent = (
    <Badge badgeContent={badgeContent} color="error" overlap="circular">
      <img 
        className="HeaderIcon" 
        src={SettingsIcon} 
        alt="Missing Asset" 
        style={{cursor: "pointer"}}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}>
      </img>
      <Popover 
        id="header-version-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={popoverOpen}
        anchorEl={popoverAnchorElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
          {versionContent}
      </Popover>
    </Badge>
  );
  
  return (
    <div className={headerClassName}>
      {backButtonContent}
      {robotNameContent}
      {versionButtonContent}
      {eventsButtonContent}
      <img className="HeaderIconDeactivated" src={ProfileIcon} alt="Missing Asset"></img>
    </div>
  )
}