import React, { useState, useEffect, useRef } from "react";
import "./OverallMap.css";
import mapboxgl, { GeoJSONSource, Map, Marker, Popup, LngLat } from "mapbox-gl";
import SharedPropsInterface from "../../ts/interfaces/SharedPropsInterface";
import APIActions from "../Utility/APIActions";
import BotMarker from "../../ts/interfaces/BotMarker";
import { Typography } from "@formant/ui-sdk";
import { useNavigate } from 'react-router-dom';
import Region from "../../ts/interfaces/Region";
import RtcHeartbeat from "../../ts/interfaces/RtcHeartbeat";
import DeviceInfo from "../../ts/interfaces/DeviceInfo";
import BotScaling from "../Utility/BotScaling";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { getTelemetryJSON } from "../Utility/DeviceFetch";

//Assets
import CutterIcon from "../../Assets/Icons/Robots Inner Page/Mask group-3.png";
import PingIcon from "../../Assets/Icons/Robots Inner Page/Mask group-2.png";
import ChargeIconOrange from "../../Assets/Icons/Robots Inner Page/Mask group-orange.png";

type Position = number[]

/**
 * Map displaying bot orientation and selectable regions
 * 
 * @param {SharedPropsInterface} sharedProps - Properties shared across OverallRobotView
 * @returns {JSX.Element} React functional component
 */
export default function OverallMap(sharedProps: SharedPropsInterface): JSX.Element {
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || ""; 
  const defaultLongitude = 0;
  const defaultLatitude = 0;
  const defaultZoom = 0;
  const defaultMapStyle = "mapbox://styles/mapbox/satellite-v9";
  const botMarkerPopupOffset = [25, -150];
  const alleyTextRenderThreshold = 21;
  const alleyLabelWidth = 75;
  const alleyLabelHeight = 25;
  const botImageHeight = 954;
  const botImageWidth = 1541;
  const startBotScaling = 20.2;
  const heartbeatIntervalTimer = 1000;
  const heartbeatThreshold = 5000;

  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<Map | null>(null);
  const popup = useRef<Popup>(new Popup({ 
    offset: 10,
    closeButton: false,
    closeOnClick: false
  }));
  const deviceInfoRef = useRef<DeviceInfo[]>(sharedProps.deviceInfo);
  const poiLngLat = useRef<LngLat>(null); // TODO: type this better and give default value?

  const [markers, setMarkers] = useState<BotMarker[]>([]);
  const [devicesLoaded, setDevicesLoaded] = useState<boolean>(false);
  const [deviceInfoIndex, setDeviceInfoIndex] = useState<number>(0);
  const [completedPaths, setCompletedPaths] = useState([]);
  const [jobCompletionPercentage, setJobCompletionPercentage] = useState<string>(null);
  const [poiDialogOpen, setPoiDialogOpen] = useState<boolean>(false);
  
  const navigate = useNavigate();

  useEffect(() => {
    //TODO: As aprt of ROS-354 move listeners to separate module so detail/overall don't have to re-establish each time
    cleanRealtimeListeners();
  }, []);

  useEffect(() => {
    // Allow bot marker info popup to follow the mouse
    document.addEventListener("mousemove", (event) => {
      const element = document.getElementById("popup");
      if(element) {
        element.style.left = String(event.pageX + botMarkerPopupOffset[0]) + "px";
        element.style.top = String(event.pageY + botMarkerPopupOffset[1]) + "px";
      }
    });
  });

  // Handle device info updates
  useEffect(() => {
    deviceInfoRef.current = sharedProps.deviceInfo;
    initializeRealtimeLocationChannels();
    // Update map locations when device info changes
    updateMapLocations();
    // Update Job Status
    updateJob();
    updateJobCompletionPercentage();
    drawAlleyPaths();
    // If device info array populated and initial zoom not yet completed,
    if (sharedProps.deviceInfo.find((info) => info.latitude && info.longitude) && !devicesLoaded) {
      if (map.current && !map.current.isZooming()) {
        zoomFitAllBots();
        setDevicesLoaded(true);
      }
    }
  }, [sharedProps.deviceInfoFetched]);

  // Handle field/region updates
  useEffect(() => {
    // Update field or region state if changed in sharedFilter
    updateFieldRegion();
    // Update bot markers
    updateMarkers();
  }, [sharedProps.sharedFilter.field, sharedProps.sharedFilter.region]);

  // Handle updates to completed paths
  useEffect(() => {
    if (sharedProps.currentJob.length > 0) {
      updateJobCompletionPercentage();
    }
  }, [completedPaths]);

  // Initialize map on page load
  useEffect(() => {
    if (map.current || !mapContainer.current) return; // initialize map only once
    let initialMapStyle = sharedProps.mapStyle;
    if (sharedProps.mapStyle === "") {
      initialMapStyle = defaultMapStyle;
    }
    sharedProps.setMapStyle(initialMapStyle);
    map.current = new Map({
      container: mapContainer.current,
      attributionControl: false,
      style: initialMapStyle,
      center: [defaultLongitude, defaultLatitude],
      zoom: defaultZoom,
    });
    map.current?.on('load', function () {
      // Create map sources and layers
      createSourcesAndLayers();
      if (sharedProps.sharedFilter.field.FieldContractId !== 0 && sharedProps.sharedFilter.region.RegionId !== 0) {
        updateRegionSource(sharedProps.sharedFilter.field.FieldContractId, sharedProps.sharedFilter.region);
      }
      // Map zoom handler
      //TODO: update to calculate scaling based on latitude and move to separate module so same code can be used
      //in detail view
      map.current?.on('zoom', () => {
        adjustBotMarkerZoom();
      });
      
    });
  });

  useEffect(() => {
    map.current?.off("click", handleMapControlClick);
    map.current?.on("click", handleMapControlClick);
  }, [sharedProps.sharedFilter.region]);

  const handleMapControlClick = (e) => {
    if (e.originalEvent.ctrlKey) {
      const poiCreationAllowed = sharedProps.sharedFilter.region.RegionId !== 0;
      if (poiCreationAllowed) {
        e.preventDefault();
        poiLngLat.current = e.lngLat;
        setPoiDialogOpen(true);
      }
    }
  }

  const adjustBotMarkerZoom = (zoom = map.current?.getZoom()) => {
    const newScale = BotScaling.computeNewZoom(zoom,  map.current?.getCenter().lat, startBotScaling)

    if (newScale != undefined) {
      markers.forEach((marker) => {
        const botmarkerElement = marker.marker.getElement();
        botmarkerElement.style.height = botImageHeight * newScale+ "px";
        botmarkerElement.style.width = botImageWidth * newScale + "px";
        botmarkerElement.style.fontSize = 8 * newScale * 100 + "px";
      })
    }
  }

  const cleanRealtimeListeners = () => {
    sharedProps.deviceInfo.forEach((info) => {
      if (info.realtimeGpsChannel && info.realtimeGpsChannel.listeners.length > 0) {
        for (let i = 0; i < info.realtimeGpsChannel.listeners.length; i++) {
          info.realtimeGpsChannel.removeListener(info.realtimeGpsChannel.listeners[i]);
        }
      }
      if (info.realtimeOdomChannel && info.realtimeOdomChannel.listeners.length > 0) {
        for (let i = 0; i < info.realtimeOdomChannel.listeners.length; i++) {
          info.realtimeOdomChannel.removeListener(info.realtimeOdomChannel.listeners[i]);
        }
      }
      if (info.realtimePathChannel && info.realtimePathChannel.listeners.length > 0) {
        for (let i = 0; i < info.realtimePathChannel.listeners.length; i++) {
          info.realtimePathChannel.removeListener(info.realtimePathChannel.listeners[i]);
        }
      }
    });
  }

  const initializeRealtimeLocationChannels = () => {
    sharedProps.deviceInfo.forEach((info) => {
      if(info.realtimeGpsChannel){
        if (info.realtimeGpsChannel.listeners.length <= 0) {
          info.realtimeGpsChannel.addListener((message) => {
            const data = JSON.parse(message);
            updateBotPosition(info, data.longitude, data.latitude);
          });
        }
      }
      if (info.realtimeOdomChannel) {
        if (info.realtimeOdomChannel.listeners.length <= 0) {
          info.realtimeOdomChannel.addListener((message) => {
            const data = JSON.parse(JSON.parse(message));
            updateYawRealtime(info, data.twist.twist.angular.z);
          });
        }
      }
      if(info.realtimePathChannel && info.realtimePathChannel.listeners.length <= 0) {
        info.realtimePathChannel.addListener(() => {
          updateJobCompletionPercentage();
          drawAlleyPaths();
        });
      }
    });
  }

  const updateBotPosition = (info: DeviceInfo, longitude: number, latitude: number) => {
    const marker: BotMarker = markers.find((marker) => marker.name === info.device.name);
    if (marker) {
      marker.marker.setLngLat([longitude, latitude]);
    }
  }

  const updateYawRealtime = (info: DeviceInfo, angle: number) => {
    const marker: BotMarker = markers.find((marker) => marker.name === info.device.name);
    if (marker) {
      const newAngle = angle ? angle : 0; 
      const markerAngle = ((360 - (newAngle * (180/Math.PI)))) % 360;
      marker.marker.setRotation(markerAngle);
    }
  }

  /**
   * Generate icons to be used in map
   */
  const generateMapIcons = () => {
    // Generate icon for alley path labels
    const bytePerPixel = 4

    if (!map.current?.hasImage('alley_path_label_white')) {
      const whiteLabelData = new Uint8Array(alleyLabelWidth * alleyLabelHeight * bytePerPixel);
      for (let i = 0; i < alleyLabelWidth * alleyLabelHeight; i++) {
        const offset = i * bytePerPixel;

        whiteLabelData[offset + 0] = 255; // red
        whiteLabelData[offset + 1] = 255; // green
        whiteLabelData[offset + 2] = 255; // blue
        whiteLabelData[offset + 3] = 255; // alpha
      }
      map.current?.addImage('alley_path_label_white', { width: alleyLabelWidth, height: alleyLabelHeight, data: whiteLabelData});
    }
    if (!map.current?.hasImage('alley_path_label_green')) {
      const greenLabelData = new Uint8Array(alleyLabelWidth * alleyLabelHeight * bytePerPixel);
      for (let i = 0; i < alleyLabelWidth * alleyLabelHeight; i++) {
        const offset = i * bytePerPixel;
        
        greenLabelData[offset + 0] = 0; // red
        greenLabelData[offset + 1] = 255; // green
        greenLabelData[offset + 2] = 0; // blue
        greenLabelData[offset + 3] = 255; // alpha
      }
      map.current?.addImage('alley_path_label_green', { width: alleyLabelWidth, height: alleyLabelHeight, data: greenLabelData});
    }
  }

  /**
   * Fetch current job from selected field
   * 
   * @async
   */
  const updateJob = async() => {
    if (sharedProps.sharedFilter.field.FieldContractId !== 0) {
      const fetchedJob = await APIActions.getJobsByFieldContract(sharedProps.sharedFilter.field.FieldContractId);
      sharedProps.setCurrentJob(fetchedJob);
    } else {
      sharedProps.setCurrentJob([]);
    }
  }

  /**
   * Fetch job completion percentage
   * 
   * @async
   */
  const updateJobCompletionPercentage = async() => {
    try {
      if (sharedProps.sharedFilter.field.FieldContractId !== 0) {
        const completionPercentage = await APIActions.getJobCompletionPercentage(sharedProps.sharedFilter.field.FieldContractId);
        setJobCompletionPercentage(completionPercentage);
      } else {
        setJobCompletionPercentage(null);
      }
    } catch(e) {
      setJobCompletionPercentage(null);
    }
  }

  /**
   * Fetch and update list of completed paths
   * 
   * @async
   * @returns {Promise<Job[]>} List of completed alley paths
   */
  const updateCompletedAlleyPaths = async() => {
    let jobPaths = [];
    if (sharedProps.currentJob.length > 0 && sharedProps.sharedFilter.field.FieldContractId !== 0) {
      try {
        jobPaths = await APIActions.getCompletedPolyPathIds(sharedProps.sharedFilter.field.FieldContractId);
      } catch (e) {
        console.warn(e);
      }
    }
    setCompletedPaths(jobPaths);
    return jobPaths;
  }

  /**
   * Check if field or region state has changed and return field/region name
   * 
   * @async
   * @returns {Promise<string | void>} Promise of either region name or no return
   */
  const updateFieldRegion = async(): Promise<string | void> => {
    const sharedField = sharedProps.sharedFilter.field;
    const sharedRegion = sharedProps.sharedFilter.region;
    if (sharedField.FieldContractId !== 0 && map.current) {
      const fieldStyle = await fetchFieldStyle(sharedField.FieldContractId);
      map.current?.setStyle(fieldStyle);
      sharedProps.setMapStyle(fieldStyle);
      map.current?.on('styledata', function () {
        createSourcesAndLayers();
      });
      if (sharedRegion.RegionId !== 0) {
        updateRegionSource(sharedField.FieldContractId, sharedRegion);
      }
    }
  }

  const drawAlleyPaths = async () => {
    try {
      const fieldContractId = sharedProps.sharedFilter.field.FieldContractId;
      const region = sharedProps.sharedFilter.region;

      if (fieldContractId !== 0 && region.RegionId !== 0) {
        // get api data of targeted region alley paths
        const alleyPathData = await APIActions.getAlleyPaths(fieldContractId, region.RegionId);
        const alleyPathCoordinates = alleyPathData.coordinates as Position[][];
        const alleyPathNumbers = await APIActions.getAlleyPathNumbers(fieldContractId, region.RegionId);
        const alleyPathNumbersForRegion = alleyPathNumbers[region.Name];
        const alleyPathsCompleted = await updateCompletedAlleyPaths();

        // Set data for alley paths
        const alleyPathFeatures = []
        alleyPathCoordinates.forEach((coords, index) => {
          const alleyPathNumber: string = alleyPathNumbersForRegion ? alleyPathNumbersForRegion[index].RowNumber : index + 1;
          const description = 'Alley ' + alleyPathNumber;
          const isCompleted = alleyPathNumbersForRegion && alleyPathsCompleted.find((path) => path.polyPathId === alleyPathNumbersForRegion[index].PolyPathId) ? true : false;
          alleyPathFeatures.push({
            'type': 'Feature',
            'properties': {
              'description': description,
              'completed': isCompleted
            },
            'geometry': {
              'type': 'LineString',
              'coordinates': coords
            }
          })
        });
        (map.current?.getSource('alley-path-lines') as GeoJSONSource).setData({
          type: "FeatureCollection",
          features: alleyPathFeatures
        });
      }
    } catch (e){
      console.warn(e);
    }
  }

  /**
   * Fetch geojson data for specified region in specified field
   * 
   * @async
   * @param {number} fieldContractId - field contract id
   * @param {Region} region - region
   */
  const updateRegionSource = async(fieldContractId: number, region: Region) => {
    // Generate icon for alley path labels
    generateMapIcons();

    // Draw alley paths
    drawAlleyPaths();

    try {
      // get api data of targeted region crop rows
      const cropRowData = await APIActions.getCropRows(fieldContractId, region.RegionId);
      const cropRowCoordinates = cropRowData.coordinates as Position[][];

      // Set data for crop rows
      const cropRowFeatures = [];
      cropRowCoordinates.forEach((coords) => {
        cropRowFeatures.push({
          'type': 'Feature',
          'properties': {},
          'geometry': {
            'type': 'LineString',
            'coordinates': coords
          }
        })
      });
      (map.current?.getSource('crop-row-lines') as GeoJSONSource).setData({
        type: "FeatureCollection",
        features: cropRowFeatures
      });
    } catch (e) {
      console.warn(e);
    }

    try {
      // get api data of targeted region
      const perimeter_data = await APIActions.getPerimeter(fieldContractId, region.RegionId);
      const perimeter_type = perimeter_data.type as "LineString";
      const perimeter_coordinates = perimeter_data.coordinates as Position[];

      // Set data for perimeter
      (map.current?.getSource('perimeter-lines') as GeoJSONSource).setData({
        type: "FeatureCollection",
        features: [{
          type: "Feature",
          properties: {},
          geometry: {
            type: perimeter_type,
            coordinates: perimeter_coordinates
          }
        }]
      });

      // zoom map to fit region
      const bounds = new mapboxgl.LngLatBounds(
        perimeter_coordinates[0] as mapboxgl.LngLatLike,
        perimeter_coordinates[0] as mapboxgl.LngLatLike
      );
      perimeter_coordinates.forEach((point) => {
        bounds.extend(point as mapboxgl.LngLatLike);
      })
      map.current?.fitBounds(bounds, {
        padding: 20
      });
      setDevicesLoaded(true);
    } catch (e) {
      console.warn(e);
    }

    try {
      // get api data for targeted region's detail pins
      const pin_data = await APIActions.getDetailPins(fieldContractId, region.RegionId);
      // Set data and handlers for pins
      (map.current?.getSource('detail-pins') as GeoJSONSource).setData(pin_data);
      // Handle detail pin popups
      map.current.on('mouseenter', 'detail-pins-layer', (e) => {
        map.current.getCanvas().style.cursor = 'pointer';
        const coordinates = e.lngLat;
        const description = e.features[0].properties.Name;
        popup.current.setLngLat([coordinates.lng, coordinates.lat])
          .setHTML(description)
          .addTo(map.current)
      });
      map.current.on('mousemove', 'detail-pins-layer', (e) => {
        const coordinates = e.lngLat;
        popup.current.setLngLat([coordinates.lng, coordinates.lat]);
      });
      map.current.on('mouseleave', 'detail-pins-layer', () => {
        map.current.getCanvas().style.cursor = '';
        popup.current.remove();
      });
    } catch (e) {
      console.warn(e);
    }

    try {
      // get api data for targeted region's home path
      const home_data = await APIActions.getHome(fieldContractId, region.RegionId);
      const home_type = "LineString";
      const home_coordinates = home_data.coordinates as Position[];

      // Set data for home lines
      (map.current?.getSource('home-lines') as GeoJSONSource).setData({
        type: "FeatureCollection",
        features: [{
          type: "Feature",
          properties: {},
          geometry: {
            type: home_type,
            coordinates: home_coordinates
          }
        }]
      });
    } catch (e) {
      console.warn(e);
    }

    try {
      // get api data for targeted region's manual zones
      const manualZoneData = await APIActions.getManualZones(fieldContractId, region.RegionId);
      const manualZoneCoordinates = manualZoneData.coordinates as Position[][];

      // Set data for manual zones
      (map.current?.getSource('manual-zone-lines') as GeoJSONSource).setData({
        type: "FeatureCollection",
        features: [{
          type: "Feature",
          properties: {},
          geometry: {
            type: "MultiLineString",
            coordinates: manualZoneCoordinates
          }
        }]
      });
    } catch (e) {
      console.warn(e);
    }

    try {
      // get api data for targeted region's manual zones
      const onRampData = await APIActions.getOnRamps(fieldContractId, region.RegionId);
      const onRampCoordinates = onRampData.coordinates as Position[][];

      // Set data for manual zones
      (map.current?.getSource('on-ramp-lines') as GeoJSONSource).setData({
        type: "FeatureCollection",
        features: [{
          type: "Feature",
          properties: {},
          geometry: {
            type: "MultiLineString",
            coordinates: onRampCoordinates
          }
        }]
      });
    } catch (e) {
      console.warn(e);
    }

    try {
      // get api data for targeted region's highways
      const highwayData = await APIActions.getHighways(fieldContractId, region.RegionId);
      const highwayCoordinates = highwayData.coordinates as Position[][];

      // Set data for manual zones
      (map.current?.getSource('highway-lines') as GeoJSONSource).setData({
        type: "FeatureCollection",
        features: [{
          type: "Feature",
          properties: {},
          geometry: {
            type: "MultiLineString",
            coordinates: highwayCoordinates
          }
        }]
      });
    } catch (e) {
      console.warn(e);
    }
  }

  /**
   * Iterate through every device and update map based on device info
   */
  const updateMapLocations = () => {
    sharedProps.deviceInfo.forEach((info) => {
      if (!info.realtimeOdomChannel || (info.realtimeOdomChannel && info.realtimeOdomChannel.listeners.length <= 0)) {
        // fetch odometry info from device if they are rendered on map
        const markerIndex = markers.findIndex((marker) => marker.name === info.device.name);
        if (info.odometryURL !== "" && markerIndex !== -1) {
          updateYaw(info.odometryURL, markers[markerIndex].marker);
        }
      }
    });
    // Update bot markers
    updateMarkers();
  }

  /**
   * Update all the bot markers, accounting for device filters
   */
  const updateMarkers = () => {
    const newMarkersArray: BotMarker[] = markers;
    sharedProps.deviceInfo.forEach((info) => {
      const markerIndex = markers.findIndex((marker) => marker.name === info.device.name);
      // Delete markers that have been filtered out
      if((info.isOnline && !sharedProps.sharedFilter.connection_status.includes("online") ||
        !info.isOnline && !sharedProps.sharedFilter.connection_status.includes("offline")) &&
        markerIndex !== -1) {
        deleteMarker(newMarkersArray, info.device.name);
      }
      // Markers match filter
      else if((info.isOnline && sharedProps.sharedFilter.connection_status.includes("online") ||
        !info.isOnline && sharedProps.sharedFilter.connection_status.includes("offline")) &&
        info.latitude !== null && info.longitude !== null) {
        if (markerIndex === -1) {
          // Create new marker if one does not exist
          createMarker(newMarkersArray, info.device.name, info.robotNumber, info.device.id, info.longitude, info.latitude);
          adjustBotMarkerZoom(startBotScaling);
        } else if (!info.realtimeGpsChannel || (info.realtimeGpsChannel && info.realtimeGpsChannel.listeners.length <= 0)) {
          // Update position
          newMarkersArray[markerIndex].marker.setLngLat([info.longitude, info.latitude]);
        }
      }
    });
    setMarkers(newMarkersArray);
  }

  /**
   * Fetch the odometry information and convert to degree value to rotate bot on map to
   * 
   * @async
   * @param {string} odometryURL - JSON link to odometry info
   * @param {Marker} marker - device marker on map
   */
  const updateYaw = async(odometryURL: string, marker: Marker) => {
    const json = await getTelemetryJSON(odometryURL);
    const markerAngle = ((360 - (json.twist.twist.angular.z * (180 / Math.PI)))) % 360;
    marker.setRotation(markerAngle);
  }

  /**
   * Zoom map to fit all bots on the map for overall view
   */
  const zoomFitAllBots = () => {
    const bounds = new mapboxgl.LngLatBounds();
    sharedProps.deviceInfo.forEach((info) => {
      if (info.latitude && info.longitude) {
        bounds.extend([info.longitude, info.latitude]);
      }
    });

    if (!bounds.isEmpty()) {
      map.current?.fitBounds(bounds, {
        padding: 20
      });
    } else {
      console.warn("zoomFitAllBots(): Bounds is empty, zoom failed");
    }
  }

  /**
   * Create sources and layers for rendering geoJson data
   */
  //TODO: Breakup into smaller functions and move to separate module so can be used
  //in overall and detail view
  const createSourcesAndLayers = () => {
    // geojson source for fetched alley path lines
    if (!map.current?.getSource('alley-path-lines')) {
      map.current?.addSource('alley-path-lines', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': []
        }
      });
    }
    if (!map.current?.getLayer('alley-path-lines-layer')) {
      map.current?.addLayer({
        'id': 'alley-path-lines-layer',
        'type': 'line',
        'source': 'alley-path-lines',
        'paint': {
          'line-color': [
            'case', 
            ['==', ['get', 'completed'], true],
            '#00ff00',
            'white'
          ],
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            20, ['number', 1],
            24, ['number', 10]
          ],
        }
      });
    }
    if (!map.current?.getLayer('alley-path-text-layer')) {
      map.current?.addLayer({
        'id': 'alley-path-text-layer',
        'type': 'symbol',
        'source': 'alley-path-lines',
        'minzoom': alleyTextRenderThreshold,
        'layout': {
          'icon-image': [
            'case',
            ['==', ['get', 'completed'], true],
            'alley_path_label_green',
            'alley_path_label_white'
          ],
          'icon-allow-overlap': true,
          'symbol-placement': 'line',
          'symbol-spacing': [
            'interpolate',
            ['linear'],
            ['zoom'],
            21, ['number', 200],
            22, ['number', 400]
          ],
          'text-field': ['get', 'description'],
          'text-size': 16
        }
      });
    }

    // geojson source for fetched waypoint lines
    if (!map.current?.getSource('crop-row-lines')) {
      map.current?.addSource('crop-row-lines', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': []
        }
      });
    }
    if (!map.current?.getLayer('crop-row-lines-layer')) {
      map.current?.addLayer({
        'id': 'crop-row-lines-layer',
        'type': 'line',
        'source': 'crop-row-lines',
        'paint': {
          'line-color': '#e200ff',
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            20, ['number', 1],
            24, ['number', 7]
          ],
        }
      });
    }

    // geojson source for fetched perimeter coordinate data
    if (!map.current?.getSource('perimeter-lines')) {
      map.current?.addSource('perimeter-lines', {
        type: 'geojson',
        data: {
          type: "LineString",
          coordinates: []
        }
      });
    }
    if (!map.current?.getLayer('perimeter-lines-layer')) {
      map.current?.addLayer({
        'id': 'perimeter-lines-layer',
        'type': 'line',
        'source': 'perimeter-lines',
        'paint': {
          'line-color': '#ffba42',
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            20, ['number', 3],
            24, ['number', 7]
          ],
          'line-dasharray': [2, 1]
        }
      });
    }

    //geojson source for fetched detail pins data
    if (!map.current?.getSource('detail-pins')) {
      map.current?.addSource('detail-pins', {
        type: 'geojson',
        data: {
          type: "LineString",
          coordinates: []
        }
      });
    }
    if (!map.current?.getLayer('detail-pins-layer')) {
      map.current?.addLayer({
        'id': 'detail-pins-layer',
        'type': 'circle',
        'source': 'detail-pins',
        'paint': {
          'circle-color': 'red',
          'circle-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            20, ['number', 5],
            24, ['number', 20]
          ]
        }
      })
    }

    //geojson source for bots' home path
    if (!map.current?.getSource('home-lines')) {
      map.current?.addSource('home-lines', {
        type: 'geojson',
        data: {
          type: 'LineString',
          coordinates: []
        }
      })
    }
    if (!map.current?.getLayer('home-lines-layer')) {
      map.current?.addLayer({
        'id': 'home-lines-layer',
        'type': 'line',
        'source': 'home-lines',
        'paint': {
          'line-color': 'yellow',
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            20, ['number', 1],
            24, ['number', 10]
          ],
        }
      })
    }

    //geojson source for manual zones
    if (!map.current?.getSource('manual-zone-lines')) {
      map.current?.addSource('manual-zone-lines', {
        type: 'geojson',
        data: {
          type: 'LineString',
          coordinates: []
        }
      })
    }
    if (!map.current?.getLayer('manual-zone-lines-layer')) {
      map.current?.addLayer({
        'id': 'manual-zone-lines-layer',
        'type': 'line',
        'source': 'manual-zone-lines',
        'paint': {
          'line-color': '#2d7cc3',
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            20, ['number', 1],
            24, ['number', 7]
          ],
        }
      })
    }
    if (!map.current?.getLayer('manual-zone-fill-layer')) {
      map.current?.addLayer({
        'id': 'manual-zone-fill-layer',
        'type': 'fill',
        'source': 'manual-zone-lines',
        'paint': {
          'fill-color': '#2d7cc3',
          'fill-opacity': 0.5,
        }
      })
    }

    //geojson source for on ramps
    if (!map.current?.getSource('on-ramp-lines')) {
      map.current?.addSource('on-ramp-lines', {
        type: 'geojson',
        data: {
          type: 'LineString',
          coordinates: []
        }
      })
    }
    if (!map.current?.getLayer('on-ramp-lines-layer')) {
      map.current?.addLayer({
        'id': 'on-ramp-lines-layer',
        'type': 'line',
        'source': 'on-ramp-lines',
        'paint': {
          'line-color': '#000000',
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            20, ['number', 1],
            24, ['number', 7]
          ],
        }
      })
    }

    //geojson source for highways
    if (!map.current?.getSource('highway-lines')) {
      map.current?.addSource('highway-lines', {
        type: 'geojson',
        data: {
          type: 'MultiLineString',
          coordinates: []
        }
      })
    }
    if (!map.current?.getLayer('highway-lines-layer')) {
      map.current?.addLayer({
        'id': 'highway-lines-layer',
        'type': 'line',
        'source': 'highway-lines',
        'paint': {
          'line-color': '#ff0000',
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            20, ['number', 1],
            24, ['number', 7]
          ],
          'line-dasharray': [2, 1],
        }
      })
    }

    // Order Layers
    map.current?.moveLayer('manual-zone-fill-layer');
    map.current?.moveLayer('manual-zone-lines-layer');
    map.current?.moveLayer('detail-pins-layer');
  }

  /**
   * Fetch field style for passed field
   * 
   * @async
   * @param {number} fieldContractId - field name
   * @return {Promise<string>} mapbox style for field
   */
  const fetchFieldStyle = async (fieldContractId: number): Promise<string> => {
    let ret = defaultMapStyle;
    const data = await APIActions.getFieldMapboxStyle(fieldContractId);
    if (data.includes("mapbox://styles")) {
      ret = data;
    }
    return ret;
  }

  /**
   * Return bot marker HTML element based on passed name, robot number
   * 
   * @param {string} name - Robot name
   * @param {number} robotNumber - Robot number
   * @param {string} deviceId - Robot deviceId
   * @return {HTMLElement} Bot marker HTML element
   */
  const getBotMarkerElement = (name: string, robotNumber: number, deviceId: string): HTMLElement => {
    const element = document.createElement('div');
    element.className = "OverAllBotMarker";
    element.style.cursor = "pointer";
    element.onmouseover = () => botMarkerPopupHandler(true, name);
    element.onmouseout = () => botMarkerPopupHandler(false);
    element.ondblclick = () => navigate('/detailedView?deviceId=' + deviceId);
    if (name) {
      element.innerText = robotNumber === 0 ? name.slice(name.length - 3, name.length) : String(robotNumber);
    }
    return element;
  }

  /**
   * Create a new bot marker
   * 
   * @param {BotMarker[]} markerArray - array of bot markers
   * @param {string} name - bot name to create marker for
   * @param {number} robotNumber - Robot number for marker
   * @param {string} deviceId - Robot device id
   * @param {number} longitude - longitude coordinate of bot
   * @param {number} latitude - latitude coordinate of bot
   */
  const createMarker = (markerArray: BotMarker[], name: string, robotNumber: number, deviceId: string, longitude: number, latitude: number) => {
    if (map.current) {
      const marker = new mapboxgl.Marker(getBotMarkerElement(name, robotNumber, deviceId))
        .setLngLat([longitude, latitude])
        .addTo(map.current)
      const newMarker: BotMarker = { name: name, marker: marker };
      markerArray.push(newMarker);
    }
  }

  /**
   * Delete a bot marker
   * 
   * @param {BotMarker[]} markerArray - array of bot markers
   * @param {string} name - bot name to delete marker for
   */
  const deleteMarker = (markerArray: BotMarker[], name: string) => {
    const markerIndex = markers.findIndex(marker => marker.name === name);
    markerArray[markerIndex].marker.remove();
    markerArray.splice(markerIndex, 1);
  }

  /**
   * Handle bot info popup by rendering popup when mouse hovers over bot on map
   * 
   * @async
   * @param {boolean} mouseIsOverMarker - onmouseover is called
   * @param {string} robotName - (optional) name of robot mouse is hovering over
   */
  const botMarkerPopupHandler = (mouseIsOverMarker: boolean, robotName?: string) => {
    const popupElement = document.getElementById("popup");
    if (mouseIsOverMarker) {
      const markerDeviceInfo = sharedProps.deviceInfo.findIndex((info) => info.device.name === robotName);
      setDeviceInfoIndex(markerDeviceInfo);
      popupElement.style.display = "grid";
    } else {
      popupElement.style.display = "none";
    }
  }

  /**
   * Create display string from device info for bot popup
   * 
   * @param {DeviceInfo} info - device info
   * @returns {string} - bot popup display text
   */
  const popupBotNameDisplay = (info: DeviceInfo) => {
    const robotNumberDisplay = info.robotNumber === 0 ? "Robot --" : "Robot " + info.robotNumber;
    let alleyNumberDisplay = " - Alley ###";
    if (info.path !== "") {
      alleyNumberDisplay = " - Alley " + JSON.parse(info.path).path_name;
    }
    return robotNumberDisplay + alleyNumberDisplay;
  }

  /**
   * Create display string for job completion percentage
   * 
   * @returns {string} - job completion percentage text
   */
  const jobCompletionPercentageDisplay = () => {
    let percentage = "N/A";
    if (jobCompletionPercentage) {
      percentage = jobCompletionPercentage.substring(0, jobCompletionPercentage.indexOf('.') + 3) + "%";
    }
    return "Job Completion: " + percentage;
  }

  const PointOfInterestPopup = () => {
    const nameText = useRef<string>("");
    const notesText = useRef<string>("");
    const [parametersAreInvalid, setParametersAreInvalid] = useState<boolean>(false);

    const handleClose = () => {
      setParametersAreInvalid(false);
      setPoiDialogOpen(false);
    }

    const handleSubmit = () => {
      setParametersAreInvalid(false);
      const lngLat = poiLngLat.current;
      const currentRegion = sharedProps.sharedFilter.region;
      // TODO: add currentUser to the posted data somehow (add to notes value?)
      //const currentUser = Authentication.getCurrentUser();
      //const currentField = sharedProps.sharedFilter.field;
      const notesValue = notesText.current === "" ? null : notesText.current;
      const nameValue = nameText.current === "" ? null : nameText.current;
      if (!nameValue) {
        setParametersAreInvalid(true);
      } else {
        // TODO: do we need to await this action or is it fine to let it run in the background?
        const response = APIActions.postPointOfInterest(currentRegion.RegionId, lngLat.lng, lngLat.lat, nameValue, notesValue);
        console.log(response);
        updateRegionSource(sharedProps.sharedFilter.field.FieldContractId, currentRegion);
        setPoiDialogOpen(false);
      }
    }

    return (
      <Dialog fullWidth maxWidth="md" open={poiDialogOpen} onClose={handleClose}>
        <DialogTitle>Creating new Pin at ({poiLngLat.current?.lng}, {poiLngLat.current?.lat})</DialogTitle>
        <DialogContent>
          {parametersAreInvalid ?
            <TextField error helperText="You must provide a name for your Point of Interest" label="Pin Name" fullWidth onChange={(e) => { nameText.current = e.target.value; }} sx={{mt: "1rem"}}></TextField>
          :
            <TextField label="Pin Name" fullWidth onChange={(e) => { nameText.current = e.target.value; }} sx={{mt: "1rem"}}></TextField>
          }
          <TextField label="Notes" fullWidth onChange={(e) => { notesText.current = e.target.value; }} sx={{mt: "1rem"}}></TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Submit</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <div className="OverallMap">
      <div ref={mapContainer} className="map-container"/>
      <Typography variant="h6" sx={{ color: "white", fontSize: 16 }}>{jobCompletionPercentageDisplay()}</Typography>
      {sharedProps.deviceInfo[deviceInfoIndex] &&
        <div className="BotMarkerPopup" id="popup">
          <Typography variant="h6" sx={{ color: "black", fontSize: 12 }}>{popupBotNameDisplay(sharedProps.deviceInfo[deviceInfoIndex])}</Typography>
          <div className="BotMarkerIconContainer">
            <button className={sharedProps.deviceInfo[deviceInfoIndex].cutterStatus.includes("On") ? "CutterButtonOn" : "CutterButtonOff"}>
              <img src={CutterIcon} alt="Missing Asset" style={{ height: "25px", backgroundColor: "#ffba42" }}></img>
              <Typography variant="h6" sx={sharedProps.deviceInfo[deviceInfoIndex].cutterStatus.includes("On") ? { color: "white", fontSize: 12 } : { color: "#ffba42", fontSize: 12 }}>{sharedProps.deviceInfo[deviceInfoIndex].cutterStatus}</Typography>
            </button>
            <button className="PingButton">
              <img src={PingIcon} alt="Missing Asset" style={{ height: "25px", backgroundColor: "#ffba42" }}></img>
              <Typography variant="h6" sx={{ color: "#ffba42", fontSize: 12 }}>{sharedProps.deviceInfo[deviceInfoIndex] && sharedProps.deviceInfo[deviceInfoIndex].pingMs !== 0 ? "Ping: " + sharedProps.deviceInfo[deviceInfoIndex].pingMs + "ms" : "Ping: N/A"}</Typography>
            </button>
            <button className="Odometer">
              <Typography variant="h6" sx={{ color: "white", fontSize: 18 }}>Speed</Typography>
              <div className="OdometerTextContainer">
                <Typography variant="h6" sx={{ color: "white", fontSize: 24, justifySelf: 'end' }}>0.0</Typography>
                <Typography variant="h6" sx={{ color: "white", fontSize: 10, justifySelf: 'start' }}>MPH</Typography>
              </div>
            </button>
            <button className="BotMarkerPopupBattery">
              <img src={ChargeIconOrange} alt="Missing Asset" style={{ height: "25px" }}></img>
              <Typography variant="h6" sx={{ color: "#ffba42", fontSize: 12 }}>{"Power: " + ((sharedProps.deviceInfo[deviceInfoIndex] && sharedProps.deviceInfo[deviceInfoIndex].battery !== 0) ? Math.round(sharedProps.deviceInfo[deviceInfoIndex].battery) + "%" : "N/A")}</Typography>
            </button>
          </div>
        </div>
      }
      {PointOfInterestPopup()}
    </div>
    
  );
}