import React, { useState, useRef, useEffect } from "react";
import "./OverallRobotView.css";
import RobotFilteringMenus from "../../Components/RobotFilteringMenus/RobotFilteringMenus";
import OverallMap from "../../Components/OverallMap/OverallMap";
import RobotCards from "../../Components/RobotCards/RobotCards";
import SharedPropsInterface from "../../ts/interfaces/SharedPropsInterface";
import { Typography, Switch, Icon } from "@formant/ui-sdk";
import { TextField, CircularProgress } from "@mui/material";
import { Authentication, Device, Fleet } from "@formant/data-sdk";
import FieldRegionButtons from "../../Components/FieldRegionButtons/FieldRegionButtons";
import PauseRobotsButton from "../../Components/PauseRobotsButton/PauseRobotsButton";
import Checkbox from "@mui/material/Checkbox";
import { BotState } from "../../ts/interfaces/BotState";
import BotStateRow from "../../ts/interfaces/BotStateRow";
import DeviceInfo from "../../ts/interfaces/DeviceInfo";
import BotEvent from "../../ts/interfaces/BotEvent";
import { BotStateRequest } from "../../ts/interfaces/BotStateRequest";
import BotStateUtility from "../../Components/Utility/BotStateUtility";
import APIActions from "../../Components/Utility/APIActions";
import DeviceRow from "../../ts/interfaces/DeviceRow";
import SideBar from "../../Components/Menus/SideBar";
import Header from "../../Components/Menus/Header";
import { floor } from "lodash";
import EventsSnackbar from "../../Components/UI/EventsSnackbar";
import SeverityNames from "../../Components/EventsDialogBox/SeverityNames";
import EventsDialogBox from "../../Components/EventsDialogBox/EventsDialogBox";
import { eventIsAcknowledged } from "../../ts/interfaces/BotEvent";

/**
 * Overall bot view, including robot cards, overall map, and menu buttons
 * 
 * @returns {JSX.Element} React functional component
 */
export default function OverallRobotView(sharedProps: SharedPropsInterface): JSX.Element {
  const dualMonitorMapViewUrl = "/dualMonitorMapView";
  const newWindowWidth = 1000;
  const newWindowHeight = 1000;
  const pendingStateChangesTimeout = 15;

  const mapChannelRef = useRef(new BroadcastChannel("dualMonitorChannelMap"));
  const mapChannel = mapChannelRef.current;
  const cardChannelRef = useRef(new BroadcastChannel("dualMonitorChannelCards"));
  const cardChannel = cardChannelRef.current;

  const dualMonitorMapViewWindow = useRef<Window>();
  const sharedPropsRef = useRef<SharedPropsInterface>(sharedProps);

  const [dualMonitorMode, setDualMonitorMode] = useState<boolean>(false);
  const [displayBotClaimPrompt, setDisplayBotClaimPrompt] = useState<boolean>(false);
  const [displayInitialBotClaimPrompt, setDisplayInitialBotClaimPrompt] = useState<boolean>(false);
  const [botStateRows, setBotStateRows] = useState<BotStateRow[]>([]);
  const [pendingStateChanges, setPendingStateChanges] = useState<string[]>([]);
  const [statesInitialized, setStatesInitialized] = useState<string[]>([]);
  const [devices, setDevices] = useState<Device[]>([]);
  const [onlineDevices, setOnlineDevices] = useState<Device[]>([]);
  const [deviceRows, setDeviceRows] = useState<DeviceRow[]>([]);
  const [filterText, setFilterText] = useState<string>("");
  const [botClaimSortOption, setBotClaimSortOption] = useState<string>("");
  const [botClaimSortAscending, setBotClaimSortAscending] = useState<boolean>(false);
  const [tagsAreLoaded, setTagsAreLoaded] = useState<boolean>(false);
  const [deviceTags, setDeviceTags] = useState({});
  const [eventDialogOpen, setEventDialogOpen] = useState<boolean>(false);

  // Initialize broadcast channel message received handler and collect devices from Formant
  useEffect(() => {
    cardChannel.onmessage = (event) => {
      const message = event.data.message;
      switch (message) {
        case "map-init":
          mapChannel.postMessage({
            message: "map-init",
            sharedFilter: sharedPropsRef.current.sharedFilter,
            mapStyle: sharedPropsRef.current.mapStyle,
          });
          break;
        case "update-filter":
          sharedProps.setSharedFilter(event.data.sharedFilter);
          sharedProps.setMapStyle(event.data.mapStyle);
          break;
      }
    }
    Authentication.waitTilAuthenticated().then(
      function(isAuthenticated) {
        if (isAuthenticated) {
          Fleet.getDevices().then(
            function(devices) { setDevices(devices) }
          );
          Fleet.getOnlineDevices().then(
            function(devices) { setOnlineDevices(devices) }
          );
        }
      }
    );
  }, []);

  // Handle initial bot selection
  useEffect(() => {
    if (devices.length > 0 && sharedProps.selectedDevices.length <= 0) {
      updateDeviceRows();
      setDisplayInitialBotClaimPrompt(true);
    }
  }, [onlineDevices])

  const updateDeviceRows = () => {
    const newDeviceRows = [] as DeviceRow[];
    devices.map((device) => {
      const deviceOnline = onlineDevices.some((d) => { return d.id === device.id });
      const checked = selectedDeviceInfo.some((info) => { return info.id === device.id });
      newDeviceRows.push({
        id: device.id,
        checked: checked,
        botName: device.name,
        device: device,
        isOnline: deviceOnline,
      })
    });
    setDeviceRows(newDeviceRows);
  }

  useEffect(() => {
    updateDeviceRowsWithTags();
  }, [displayInitialBotClaimPrompt]);

  const updateDeviceRowsWithTags = async() => {
    setTagsAreLoaded(false);
    const newDeviceTags = {...deviceTags};
    for (const oldDeviceRow of deviceRows) {
      try {
        const configDocument = await oldDeviceRow.device.getConfiguration();
        const tags = Object.keys(configDocument.tags);
        newDeviceTags[oldDeviceRow.id] = tags;
      } catch (e) {
        console.warn(e);
      }
    }
    setDeviceTags(newDeviceTags);
    setTagsAreLoaded(true);
  }

  // Set shared props reference
  useEffect(() => {
    sharedPropsRef.current = sharedProps;
  }, [sharedProps]);

  // Initialize batch settings
  useEffect(() => {
    if (displayBotClaimPrompt) {
      initializeBatchSettings();
    }
  }, [displayBotClaimPrompt]);

  useEffect(() => {
    if (sharedProps.deviceInfo.length > 0 && statesInitialized.length < sharedProps.deviceInfo.length) {
      const newStatesInitialized = statesInitialized.slice();
      sharedProps.deviceInfo.map((info) => {
        const initialized = newStatesInitialized.findIndex((id) => id === info.id) >= 0;
        if (info.realtimeRosCommandChannel && !initialized) {
          try {
            BotStateUtility.sendStateRequest(info, info.botOwner);
            newStatesInitialized.push(info.id);
          } catch (e) {
            console.warn("Error requesting state from device (" + info.device.name + "): " + e);
          }
        }
      });
      setStatesInitialized(newStatesInitialized);
    }
  }, [sharedProps.deviceInfo]);

  // Handle device state change
  useEffect(() => {
    if (pendingStateChanges.length > 0) {
      let pendingStateChangesTimeoutSeconds = pendingStateChangesTimeout;
      const pendingStateChangesCopy = pendingStateChanges.slice();
      // Check if state has been updated every second for 15 seconds
      const pendingStateChangesInterval = setInterval(() => {
        if (pendingStateChangesTimeoutSeconds > 0) {
          pendingStateChangesCopy.map((id, index) => {
            const deviceInfo = sharedProps.deviceInfo.find((info) => info.id === id);
            const targetBotState = botStateRows.find((row) => row.id === id).targetBotState;
            // Remove bot id from list when it has changed
            if (deviceInfo.botState === targetBotState) {
              pendingStateChangesCopy.splice(index, 1);
              // Handle state actions for bot
              handleStateActions(deviceInfo, targetBotState);
            }
            // End interval timer when all changed devices have been updated
            if (pendingStateChangesCopy.length <= 0) {
              pendingStateChangesTimeoutSeconds = 0;
            }
          });
          pendingStateChangesTimeoutSeconds -= 1;
        }
        if (pendingStateChangesTimeoutSeconds <= 0) {
          // Close batch settings on success, otherwise warn user of devices that failed to update
          if (pendingStateChangesCopy.length <= 0) {
            setDisplayBotClaimPrompt(false);
          } else {
            initializeBatchSettings();
            console.warn("The following device ids failed to update state: " + pendingStateChangesCopy);
          }
          setPendingStateChanges([]);
          clearInterval(pendingStateChangesInterval);
        }
      }, 1000);
    }
  }, [pendingStateChanges])

  // Handle filter update broadcast for connection filter
  useEffect(() => {
    mapChannel.postMessage({
      message: "update-filter",
      sharedFilter: sharedPropsRef.current.sharedFilter
    });
  }, [sharedProps.sharedFilter.connection_status])

  const initializeBatchSettings = () => {
    const newBotStateRows = [] as BotStateRow[];
    sharedProps.deviceInfo.map((info) => {
      if (info.botState) {
        newBotStateRows.push({
          id: info.id,
          checked: false,
          botName: info.device.name,
          currentBotState: info.botState,
          targetBotState: info.botState,
          botOwner: info.botOwner,
        })
      }
    });
    setBotStateRows(newBotStateRows);
  }

  // Initialize RTC listener for EventArray
  useEffect(() => {
    cleanRealtimeListeners();
    initializeRealtimeEventArrayChannel();
  }, [sharedProps.deviceInfo]);

  const cleanRealtimeListeners = () => {
    sharedProps.deviceInfo.forEach((info) => {
      if (info.realtimeEventArrayChannel && info.realtimeEventArrayChannel.listeners.length > 0) {
        for (let i = 0; i < info.realtimeEventArrayChannel.listeners.length; i++) {
          info.realtimeEventArrayChannel.removeListener(info.realtimeEventArrayChannel.listeners[i]);
        }
      }
    });
  }

  const initializeRealtimeEventArrayChannel = () => {
    sharedProps.deviceInfo.forEach((info) => {
      if (info.realtimeEventArrayChannel) {
        if (info.realtimeEventArrayChannel.listeners.length <= 0) {
          info.realtimeEventArrayChannel.addListener((message) => {
            const data = JSON.parse(message);
            const eventRobotNumber = data.header.robot_id.slice(5);
            if (eventRobotNumber === info.robotNumber) {
              const currentTimestamp = new Date(Date.now());
              updateBotEventsWithRealtimeData(info, data, currentTimestamp);
            }
          });
        }
      }
    });
  }

  const updateBotEventsWithRealtimeData = (info, jsonData, timestamp) => {
    const events = [];
    jsonData.events.forEach((event) => {
      const newEvent: BotEvent = {
        stamp: event.stamp,
        timestamp: new Date(event.stamp.sec * 1000 + floor(event.stamp.nanosec / 1000000)),
        pathId: event.path_id,
        name: event.event_name,
        severity: event.severity,
        description: event.description,
        data: event.event_data,
      };
      // info-level events should not be visible in rosie
      if (newEvent.severity !== SeverityNames.info) {
        events.push(newEvent);
      }
    });
    const newDeviceInfoList = sharedProps.deviceInfo.map((i) => {
      if (i.id !== info.id) {
        return i;
      } else {
        return {
          ...i,
          events: events,
          telemetryTimestamps: {
            ...i.telemetryTimestamps,
            eventArray: timestamp,
          }
        }
      }
    });
    sharedProps.setDeviceInfo(newDeviceInfoList);
  }

  const switchHandler = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setDualMonitorMode(checked);
    if (checked) {
      dualMonitorMapViewWindow.current = window.open(process.env.REACT_APP_SITE_URL + dualMonitorMapViewUrl + '/?auth=' + Authentication.token, "dualMonitorMode", "popup width=" + newWindowWidth + "px height=" + newWindowHeight + "px");
      sharedProps.setIsDualMonitorInstance(true);
    } else if (dualMonitorMapViewWindow.current) {
      dualMonitorMapViewWindow.current.close();
      sharedProps.setIsDualMonitorInstance(false);
    }
  };

  const setYamlButtonHandler = async () => {
    const currentUser = Authentication.getCurrentUser();
    const currentField = sharedProps.sharedFilter.field;
    const currentRegion = sharedProps.sharedFilter.region;
    const fieldHomePath = await APIActions.getHome(currentField.FieldContractId, currentRegion.RegionId);
    const claimedBots = [];
    sharedProps.deviceInfo.map((info) => {
      if(info.botOwner === currentUser.firstName + " " + currentUser.lastName) {
        claimedBots.push("robot" + info.robotNumber);
      }
    });
    const res = await APIActions.setRmfYaml(
      currentField, 
      fieldHomePath.coordinates[0][1], 
      fieldHomePath.coordinates[0][0],
      claimedBots
    );
    console.log(res);
  }

  const newJobButtonHandler = async () => {
    try {
      const fieldContractId = sharedProps.sharedFilter.field.FieldContractId;
      const res = await APIActions.postJob(fieldContractId);
      console.log(res);
      window.alert("Successfully created new job!\nJob ID: " + res.JobId);
    } catch (e) {
      console.warn("Failed to create new job: " + e);
    }
    
  }

  /**
   * Handle content render depending on whether or not dual monitor mode is set
   * 
   * @return {JSX.Element} rendered element 
   */
  const viewContainer = () => {
    let element = (
      <div className="OverallViewContentsContainer">
        <div className="RobotCardsFilteringMenuContainer">
          <div className="RobotCardsTopBar">
            <div className="CardSettingsContainer">
              <button className="BatchSettingsButton" disabled={sharedProps.deviceInfo.length <= 0} onClick={() => setDisplayBotClaimPrompt(true)}>
                <Typography sx={{ color: "#10385c", fontSize: 20, fontWeight: 'bold' }}>Batch Settings</Typography>
              </button>
              <div className="DualMonitorSwitchContainer">
                <Typography variant="h6" sx={{ color: "white", fontSize: 14, alignSelf: "center", marginLeft: 3 }}>Dual Monitor Mode</Typography>
                <Switch disabled={!Authentication.isAuthenticated()} onChange={(event, checked) => switchHandler(event, checked)} sx={{ alignSelf: "center", justifySelf: "left" }}/>
              </div>
            </div>
            <button className="ChangeSelectionButton" onClick={() => { updateDeviceRows(); setDisplayInitialBotClaimPrompt(true); }}>
              <Typography sx={{ color: "#10385c", fontSize: 20, fontWeight: 'bold' }}>Change Robot Selection</Typography>
            </button>
            <RobotFilteringMenus {...sharedProps} />
          </div>
          <RobotCards {...sharedProps} />
        </div>
        <div className="OverallMapContainer">
          <FieldRegionButtons {...sharedProps} />
          <div className="SetYamlButtonContainer">
            <button className="SetYamlButton"
              disabled={sharedProps.deviceInfo.length <= 0 || sharedProps.sharedFilter.field.FieldContractId === 0 || sharedProps.sharedFilter.region.RegionId === 0}
              onClick={() => setYamlButtonHandler()}  
            >
              <Typography variant="h6" sx={{ color: "white", fontSize: 18, alignSelf: "center" }}>Set RMF Yaml</Typography>
            </button>
            <button className="NewJobButton"
              disabled={sharedProps.sharedFilter.field.FieldContractId === 0}
              onClick={() => newJobButtonHandler()}  
            >
              <Typography variant="h6" sx={{ color: "white", fontSize: 18, alignSelf: "center" }}>New Job</Typography>
            </button>
          </div>
          <OverallMap {...sharedProps} />
          <div className="PauseAllRobotsContainer">
            <PauseRobotsButton {...sharedProps}/>
          </div>
        </div>
      </div>
    );
    if (dualMonitorMode) {
      element = (
        <div className="OverallViewContentsContainerDualMonitor">
          <div className="RobotCardsFilteringMenuContainer">
            <div className="RobotCardsTopBar">
              <div className="CardSettingsContainer">
                <button className="BatchSettingsButton" disabled={sharedProps.deviceInfo.length <= 0} onClick={() => setDisplayBotClaimPrompt(true)}>
                  <Typography sx={{ color: "#10385c", fontSize: 20, fontWeight: 'bold' }}>Batch Settings</Typography>
                </button>
                <div className="DualMonitorSwitchContainer">
                  <Typography variant="h6" sx={{ color: "white", fontSize: 14, alignSelf: "center", marginLeft: 3 }}>Dual Monitor Mode</Typography>
                  <Switch disabled={!Authentication.isAuthenticated()} onChange={(event, checked) => switchHandler(event, checked)} sx={{ alignSelf: "center", justifySelf: "left" }}/>
                </div>
              </div>
              <RobotFilteringMenus {...sharedProps} />
            </div>
            <RobotCards {...sharedProps} />
          </div>
        </div>
      );
    }
    return element;
  };

  const handleStateActions = (deviceInfo: DeviceInfo, targetState: BotState) => {
    switch (targetState) {
      case BotState.RUNNING:
        startRMF(deviceInfo);
        break;
    }
  }

  const handleCheckboxBotStateRow = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const newBotStateRows = botStateRows.slice();
    newBotStateRows.find((row) => row.id === id).checked = !botStateRows.find((row) => row.id === id).checked;
    setBotStateRows(newBotStateRows);
  };

  const handleCheckAllBotStateRow = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newBotStateRows = botStateRows.slice();
    newBotStateRows.map((row) => {
      row.checked = event.target.checked;
    });
    setBotStateRows(newBotStateRows);
  };

  const handleCheckboxDeviceRow = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const newDeviceRows = deviceRows.slice();
    newDeviceRows.find((row) => row.id === id).checked = !deviceRows.find((row) => row.id === id).checked;
    setDeviceRows(newDeviceRows);
  };

  const handleBotStateClick = (botState: BotState, selectedRow: BotStateRow, disabled: boolean) => {
    if (!disabled) {
      const newBotStateRows = botStateRows.slice();
      newBotStateRows.map((row) => {
        if (row.id === selectedRow.id) {
          row.targetBotState = botState;
          row.checked = true;
        }
      });
      setBotStateRows(newBotStateRows);
    }
  };

  const startRMF = async(deviceInfo: DeviceInfo) => {
    try {
      if (deviceInfo.realtimeRmfChannel) {
        const currentFieldContractId = sharedProps.sharedFilter.field.FieldContractId;
        const currentJob = await APIActions.getJobsByFieldContract(currentFieldContractId);
        const currentJobId = currentJob[0].jobsId;
        const res = await APIActions.postRmf(currentFieldContractId, currentJobId);
        console.log(res);
      }
    } catch (e) {
      console.warn(deviceInfo.device.name + " rmfChannel Error: " + e);
    }
  }

  const displayBotStateText = (botState: BotState, row: BotStateRow, index: number) => {
    const disabled = (
      pendingStateChanges.length > 0 ||
      (
        BotStateUtility.mapBotStateRequest(row.currentBotState, botState) === BotStateRequest.NONE &&
        row.currentBotState !== botState
      )
    );
    return (
      <Typography 
        key={index}
        className="StateText"
        variant="h6"
        onClick={() => handleBotStateClick(botState, row, disabled)}
        sx={{
          color: botState === row.currentBotState ? "#00ff00" : botState === row.targetBotState ? "#ffba42" : "#ffffff",
          fontSize: "14px",
          alignSelf: "center",
          cursor: botState === row.targetBotState || disabled ? "default" : "pointer",
          textDecoration: botState === row.targetBotState ? "underline" : "none",
          opacity: disabled ? 0.3 : 1,
          userSelect: "none",
        }}>
        {botState.toString()}
      </Typography>
    );
  };

  const displayBotOwnerText = (botOwner: string) => {
    const ownerExists = botOwner !== 'None';
    return (
      <Typography 
        className="TableText"
        variant="h6" 
        sx={{
          color: ownerExists ? "white" : "grey",
          fontSize: "16px",
          alignSelf: "center",
          fontStyle: ownerExists ? "normal" : "italic",
        }}>
        {botOwner}
      </Typography>
    );
  }

  const saveBotClaimSettings = () => {
    const newPendingStateChanges = pendingStateChanges.slice();
    botStateRows.map((row) => {
      if (row.checked) {
        const deviceInfo = sharedProps.deviceInfo.find((info) => info.id === row.id);
        const currentField = sharedProps.sharedFilter.field;
        const currentRegion = sharedProps.sharedFilter.region;
        const ownerName = Authentication.getCurrentUser().firstName + ' ' + Authentication.getCurrentUser().lastName;
        BotStateUtility.sendStateRequest(deviceInfo, ownerName, currentField, row);
        if (currentField.FieldContractId !== 0 && currentRegion.RegionId !== 0) {
          setGeofence(deviceInfo, currentField.FieldContractId, currentRegion.RegionId);
        }
        newPendingStateChanges.push(deviceInfo.id);
      }
    });
    setPendingStateChanges(newPendingStateChanges);
  }

  const submitInitialBotClaimSettings = () => {
    const selectedDevices = [];
    deviceRows.map((row) => {
      if (row.checked) {
        selectedDevices.push(row.device);
      }
    });
    sharedProps.setSelectedDevices(selectedDevices);
    setDisplayInitialBotClaimPrompt(false);
  }

  const setGeofence = async(deviceInfo: DeviceInfo, fieldContractId: number, regionId: number) => {
    const fenceCoordinates = await APIActions.getPerimeter(fieldContractId, regionId);
    const geofenceCoordinates = [];
    fenceCoordinates.coordinates.map((coords) => {
      geofenceCoordinates.push({
        lat_deg: coords[1],
        lon_deg: coords[0],
        hgt_m: 0.0,
      });
    });
    const data = {
      field_id: String(fieldContractId),
      coords: geofenceCoordinates
    }
    // Reformat string for ROS publish data
    const dataString = JSON.stringify(data)
      .replace(/"([^"]+)":/g, '$1:')
      .replace(/\uFFFF/g, '\\"')
      .replaceAll('"', "'")
      .replaceAll(':', ': ')
      .replaceAll(',', ', ')
    // Send ros data to bot
    console.log("Data sent: " + dataString);
    const ROS_COMMAND = "ros2 topic pub /geofence greenfield/msg/Geofence ";
    deviceInfo.realtimeRosCommandChannel.send(ROS_COMMAND + '"' + dataString + '"');
  }

  const generateTagsText = (deviceRow: DeviceRow) => {
    const currentDeviceTags = deviceTags[deviceRow.id];
    if (currentDeviceTags && currentDeviceTags.length > 0) {
      return currentDeviceTags.join(",");
    }
    return "n/a";
  }

  const filterDeviceRows = (event) => {
    setFilterText(String(event.target.value).toLowerCase());
  }

  const updateBotClaimSortOptions = (sortType: "name" | "tags" | "online") => {
    if (botClaimSortOption !== sortType) {
      setBotClaimSortOption(sortType);
      setBotClaimSortAscending(false);
    } else {
      if (!botClaimSortAscending) {
        setBotClaimSortAscending(true);
      } else {
        setBotClaimSortOption("none");
        setBotClaimSortAscending(false);
      }
    }
  }

  const sortDeviceRows = (deviceRows: DeviceRow[]) => {
    const sortOptionAvailable = botClaimSortOption !== "none";
    if (sortOptionAvailable) {
      deviceRows.sort((a, b) => {
        switch (botClaimSortOption) {
          case "name":
            if (botClaimSortAscending) {
              return (a.botName.toLowerCase() > b.botName.toLowerCase() ? 1 : -1);
            } else {
              return (a.botName.toLowerCase() < b.botName.toLowerCase() ? 1 : -1);
            }
          case "tags":
            if (botClaimSortAscending) {
              return (generateTagsText(a).toLowerCase() > generateTagsText(b).toLowerCase() ? 1 : -1);
            } else {
              return (generateTagsText(a).toLowerCase() < generateTagsText(b).toLowerCase() ? 1 : -1);
            }
          case "online":
            if (botClaimSortAscending) {
              return (a.isOnline > b.isOnline ? 1 : -1);
            } else {
              return (a.isOnline < b.isOnline ? 1 : -1);
            }
        }
      });
    }
    return deviceRows
  }

  const getSortOptionIconName = (sortOption) => {
    // define possible values of iconName here so Icon props can see the return value is valid
    let iconName: "arrow" | "chevron-down" | "chevron-up" = "arrow";
    if (botClaimSortOption === sortOption) {
      if (botClaimSortAscending) {
        iconName = "chevron-up";
      } else {
        iconName = "chevron-down";
      }
    }
    return iconName;
  }

  const initialBotClaimPopup = () => {
    const filteredRows = deviceRows.filter((row) => { 
      return (
        row.device.name.toLowerCase().indexOf(filterText) >= 0 || 
        Object.keys(row.device.tags).some((t) => { return t.toLowerCase().indexOf(filterText) >= 0 })
      ); 
    });
    const rowsToDisplay = sortDeviceRows(filteredRows);
    return (
      <div className="OwnershipPromptContainer">
        <div className="OwnershipPromptBackground"/>
        <div className="InitialBotClaimPrompt">
          <Typography className="OwnershipPromptText" variant="h1" sx={{ color: "white", fontSize: 32 }}>
            {"Initial Bot Claim"}
          </Typography>
          <TextField 
            variant="standard"
            label="Filter Devices"
            InputProps={{startAdornment: (<Icon name="filter"></Icon>)}}
            onChange={filterDeviceRows}/>
          <div className="InitialBotClaimTable">
            <div className="InitialBotClaimTableHeader">
                <div></div>
                <div className="BotClaimTableSortOption" onClick={() => { updateBotClaimSortOptions("name") }}>
                  <Icon name={getSortOptionIconName("name")}/>
                  <Typography  variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "bold", alignSelf: "left", display: "inline", pl: "0.5rem" }}>Bot Name</Typography>
                </div>
                { tagsAreLoaded ?
                  <div className="BotClaimTableSortOption" onClick={() => { updateBotClaimSortOptions("tags") }}>
                    <Icon name={getSortOptionIconName("tags")}/>
                    <Typography variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "bold", alignSelf: "left", display: "inline", pl: "0.5rem" }}>Tags</Typography>
                  </div>
                  :
                  <CircularProgress size={"1.5rem"} sx={{ ml: "1.5rem" }}/>
                }
                <div className="BotClaimTableSortOption" onClick={() => { updateBotClaimSortOptions("online") }}>
                  <Icon name={getSortOptionIconName("online")}/>
                  <Typography variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "bold", alignSelf: "left", display: "inline", pl: "0.5rem" }}>Online?</Typography>
                </div>
            </div>
            <div className="InitialBotClaimTableRowContainer">
              {rowsToDisplay.map((row) => 
                <div className="InitialBotClaimTableRow" key={row.id}>
                  <Checkbox
                    sx={{
                      color: "#bebebe",
                      '&.Mui-checked': {
                        color: "#ffba42",
                      }
                    }}
                    checked={row.checked}
                    onChange={(event) => handleCheckboxDeviceRow(event, row.id)}
                  />
                  <Typography className={ row.isOnline? "TableText" : "TableTextOffline" } variant="h6" sx={{ color: "white", fontSize: "16px", alignSelf: "left" }}>{row.botName}</Typography>
                  <Typography className="TableText" variant="h6">{generateTagsText(row)}</Typography>
                  { row.isOnline ? <Icon name="eye"/> : <Icon name="eye_closed"/>}
                </div>
              )}
            </div>
          </div>

          <div className="InitialOwnershipPromptButtonContainer">
            <button className="InitialOwnershipPromptButtonYes" onClick={() => submitInitialBotClaimSettings()}>
              <Typography variant="h6" sx={{ color: "#10385c", fontSize: 18, fontWeight: "bold" }}>Submit</Typography>
            </button>
          </div>
        </div>
      </div>
    );
  }

  const selectedDeviceInfo = sharedProps.deviceInfo.filter((info) => { return sharedProps.selectedDevices.some((device) => { return device.id === info.id; }); });
  let totalEventCount = 0;
  selectedDeviceInfo.forEach((info) => {
    const unacknowledgedEvents = info.events.filter((event) => { return !(eventIsAcknowledged(event, info.robotNumber, sharedProps.acknowledgedEvents)); });
    totalEventCount += unacknowledgedEvents.length;
  });

  return (
    <div className="MainContainer">
      {displayBotClaimPrompt && sharedProps.deviceInfo &&
        <div className="OwnershipPromptContainer">
          <div className="OwnershipPromptBackground" onClick={() => setDisplayBotClaimPrompt(false)}/>
          <div className="OwnershipPrompt">
            <Typography className="OwnershipPromptText" variant="h1" sx={{ color: "white", fontSize: 32 }}>
              {"Batch Settings"}
            </Typography>
            <Typography className="OwnershipPromptInstructionText" variant="h1" sx={{ color: "white", fontSize: 22 }}>
              {"Select Bots to Claim"}
            </Typography>
            <div className="BotClaimTableContainer">
              <div className="BotClaimTable">
                <div className="BotClaimTableHeader">
                    <Checkbox 
                      sx={{
                        color: "#bebebe",
                        '&.Mui-checked': {
                          color: "#ffba42",
                        }
                      }}
                      onChange={handleCheckAllBotStateRow}
                      disabled={pendingStateChanges.length > 0}
                    />
                    <Typography className="TableText" variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "bold", alignSelf: "left" }}>Bot Name</Typography>
                    <Typography className="TableText" variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "bold", alignSelf: "left" }}>State</Typography>
                    <Typography className="TableText" variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "bold", alignSelf: "left" }}>Owner</Typography>
                </div>
                <div className="BotClaimTableRowContainer">
                  {botStateRows.map((row) => 
                    <div className="BotClaimTableRow" key={row.id}>
                      <Checkbox
                        sx={{
                          color: "#bebebe",
                          '&.Mui-checked': {
                            color: "#ffba42",
                            opacity: pendingStateChanges.length > 0 ? 0.3 : 1,
                          }
                        }}
                        checked={row.checked}
                        onChange={(event) => handleCheckboxBotStateRow(event, row.id)}
                        disabled={pendingStateChanges.length > 0}
                      />
                      <Typography className="TableText" variant="h6" sx={{ color: "white", fontSize: "16px", alignSelf: "left" }}>{row.botName}</Typography>
                      <div className="StateList">
                        {Object.values(BotState).map((value, index) => displayBotStateText(value, row, index))}
                      </div>
                      {displayBotOwnerText(row.botOwner)}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="OwnershipPromptButtonContainer">
              <button className="OwnershipPromptButtonNo" onClick={() => setDisplayBotClaimPrompt(false)}>
                <Typography variant="h6" sx={{ color: "#ffba42", fontSize: 18, fontWeight: "bold" }}>Cancel</Typography>
              </button>
              <button className="OwnershipPromptButtonYes" onClick={() => saveBotClaimSettings()} disabled={pendingStateChanges.length > 0}>
                {(pendingStateChanges.length <= 0) 
                  ? <Typography variant="h6" sx={{ color: "#10385c", fontSize: 18, fontWeight: "bold" }}>Save</Typography>
                  : <div className="SaveBatchSettingsSpinner"></div>
                }
              </button>
            </div>
          </div>
        </div>
      }
      {displayInitialBotClaimPrompt &&
        initialBotClaimPopup()
      }
      <SideBar/>
      <Header 
        currentVersions={sharedProps.appVersions}
        handleEventsButton={() => { setEventDialogOpen(true); }}
        eventsCount={totalEventCount}/>
      <EventsDialogBox
        dialogIsOpen={eventDialogOpen} 
        handleClose={() => { setEventDialogOpen(false); }} 
        deviceInfo={sharedProps.deviceInfo} 
        selectedDevices={sharedProps.selectedDevices}
        acknowledgedEvents={sharedProps.acknowledgedEvents}
        setAcknowledgedEvents={sharedProps.setAcknowledgedEvents}/>
      {sharedProps.deviceInfo ? 
        <EventsSnackbar 
          deviceInfo={sharedProps.deviceInfo} 
          selectedDevices={sharedProps.selectedDevices}
          acknowledgedEvents={sharedProps.acknowledgedEvents}
          setAcknowledgedEvents={sharedProps.setAcknowledgedEvents}/> 
      : <></>}
      <div className="ContentContainer">
        {viewContainer()}
      </div>
    </div>
  );
}