export enum BotStateRequest {
    NONE = 0,
    E_STOP_ACTIVATE = 1,
    E_STOP_RELEASE = 2,
    CLAIM_TELEOP_CONTROL = 3,
    RELEASE_TELEOP_CONTROL = 4,
    TELEOP_OVERRIDE_RC_CONTROL = 5,
    TELEOP_OVERRIDE_TELEOP_CONTROL = 6,
    ASSIGN_TO_FIELD = 7,
    RELEASE_FROM_FIELD = 8,
    START_AUTO_RUNNING = 9,
    STOP_AUTO_RUNNING = 10,
}