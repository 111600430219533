import yaml from 'js-yaml';
import Field from '../../ts/interfaces/Field';
import SoftwareVersions, { UNKNOWN_SOFTWARE_VERSION, softwareNameLookup, unknownSoftwareVersions } from '../../ts/interfaces/SoftwareVersions';

/**
 * Contains helper functions for fetching data from the Greenfield API
 */
export default class APIActions {
  private static API_URL = process.env.REACT_APP_API_URL;
  private static FIELDS_AND_REGIONS_URI = "/FieldsAndRegions";

  /**
   * Fetches fields and regions JSON
   * 
   * @async
   * @returns Waypoints JSON
   */
  public static async getFieldsAndRegionsJSON() {
    const res = await fetch(APIActions.API_URL + APIActions.FIELDS_AND_REGIONS_URI);
    const json = await res.json();
    return json;
  }

  /**
   * Fetches alley paths JSON
   * 
   * @async
   * @param {number} fieldContractId 
   * @param {number} regionId 
   * @returns Alley paths JSON
   */
  public static async getAlleyPaths(fieldContractId: number, regionId: number) {
    const res = await fetch(APIActions.API_URL + '/AlleyPaths?fieldContractId=' + fieldContractId + '&regionId=' + regionId);
    const json = await res.json();
    return json;
  }

  /**
   * Fetches crop rows JSON
   * 
   * @async
   * @param {number} fieldContractId 
   * @param {number} regionId 
   * @returns Crop rows JSON
   */
  public static async getCropRows(fieldContractId: number, regionId: number) {
    const res = await fetch(APIActions.API_URL + '/CropRows?fieldContractId=' + fieldContractId + '&regionId=' + regionId);
    const json = await res.json();
    return json;
  }

  /**
   * Fetches Fence JSON
   * 
   * @async
   * @param {number} fieldContractId 
   * @param {number} regionId 
   * @returns Fence JSON
   */
  public static async getPerimeter(fieldContractId: number, regionId: number) {
    const res = await fetch(APIActions.API_URL + '/Perimeter?fieldContractId=' + fieldContractId + '&regionId=' + regionId);
    const json = await res.json();
    return json;
  }

  /**
   * Fetches mapbox style for passed field
   * 
   * @async
   * @param {number} fieldContractId 
   * @returns {Promise<string>} Mapbox style for passed field
   */
  public static async getFieldMapboxStyle(fieldContractId: number): Promise<string> {
    const res = await fetch(APIActions.API_URL + '/FieldMapboxStyle?fieldContractId=' + fieldContractId);
    const text = await res.text();
    return text;
  }

  public static async getClosestAlleyPath(fieldContractId: number, regionId: number, latitude: number, longitude: number) {
    const res = await fetch(APIActions.API_URL + '/Waypoints/closestalleypath?fieldContractId=' + fieldContractId + "&regionId=" + regionId + "&latitude=" + latitude + "&longitude=" + longitude);
    const json = await res.json();
    return json;
  }

  public static async getRmfAlleyPath(polyPathId: number) {
    const res = await fetch(APIActions.API_URL + '/RmfAlleyPath/' + String(polyPathId));
    const points = await res.text();
    const yamlData = yaml.load(points);
    return yamlData;
  }

  public static async getDetailPins(fieldContractId: number, regionId: number) {
    const res = await fetch(APIActions.API_URL + '/DetailPins?fieldContractId=' + fieldContractId + '&regionId=' + regionId);
    const json = await res.json();
    return json;
  }

  public static async getAlleyPathNumbers(fieldContractId: number, regionId: number) {
    const res = await fetch(APIActions.API_URL + '/AlleyPathOrder?fieldContractId=' + fieldContractId + '&regionId=' + regionId);
    const json = await res.json();
    return json;
  }

  public static async getHome(fieldContractId: number, regionId: number) {
    const res = await fetch(APIActions.API_URL + '/Home?fieldContractId=' + fieldContractId + '&regionId=' + regionId);
    const json = await res.json();
    return json;
  }

  public static async getJobsByFieldContract(fieldContractId: number) {
    const res = await fetch(APIActions.API_URL + '/Jobs/ByFieldContract?fieldContractId=' + String(fieldContractId));
    const json = await res.json();
    return json;
  }

  public static async getJobCompletionPercentage(fieldContractId: number) {
    const res = await fetch(APIActions.API_URL + '/Jobs/PercentageComplete?fieldContractId=' + String(fieldContractId));
    const text = await res.text();
    return text;
  }

  public static async getCompletedPolyPathIds(fieldContractId: number) {
    const res = await fetch(APIActions.API_URL + '/Jobs/CompletedPolyPathIdsByField?fieldContractId=' + String(fieldContractId));
    const json = await res.json();
    return json;
  }

  public static async getManualZones(fieldContractId: number, regionId: number) {
    const res = await fetch(APIActions.API_URL + '/ManualZones?fieldContractId=' + fieldContractId + '&regionId=' + regionId);
    const json = await res.json();
    return json;
  }

  public static async getOnRamps(fieldContractId: number, regionId: number) {
    const res = await fetch(APIActions.API_URL + '/OnRamps?fieldContractId=' + fieldContractId + '&regionId=' + regionId);
    const json = await res.json();
    return json;
  }

  public static async getHighways(fieldContractId: number, regionId: number) {
    const res = await fetch(APIActions.API_URL + '/Highways?fieldContractId=' + fieldContractId + '&regionId=' + regionId);
    const json = await res.json();
    return json;
  }

  public static async getDesiredSoftwareVersions(): Promise<SoftwareVersions> {
    const desiredSoftwareVersions = {...unknownSoftwareVersions};
    const latestVersionResponse = await fetch(APIActions.API_URL + '/LatestSoftwareVersion');
    const latestVersionJson = await latestVersionResponse.json();
    if (latestVersionJson && latestVersionJson.length > 0) {
      latestVersionJson.forEach((row) => {
        switch(row.softwareName) {
          case softwareNameLookup.weedbot:
            desiredSoftwareVersions.weedbot = row.softwareVersion
            break;
          case softwareNameLookup.mcu:
            desiredSoftwareVersions.mcu = row.softwareVersion
            break;
          case softwareNameLookup.pixhawk:
            desiredSoftwareVersions.pixhawk = row.softwareVersion
            break;
          case softwareNameLookup.rosie:
            desiredSoftwareVersions.rosie = row.softwareVersion
            break;
          case softwareNameLookup.services:
            desiredSoftwareVersions.services = row.softwareVersion
            break;
          case softwareNameLookup.formantAdapter:
            desiredSoftwareVersions.formantAdapter = row.softwareVersion
            break;
          case softwareNameLookup.formantAgent:
            desiredSoftwareVersions.formantAgent = row.softwareVersion
            break;
        }
      });
    }
    return desiredSoftwareVersions;
  }

  public static async getServicesVersion(): Promise<string> {
    const res = await fetch(APIActions.API_URL + "/About");
    const body = await res.text()
    const version = String(body) ?? UNKNOWN_SOFTWARE_VERSION
    return version;
  }

  public static async setRmfYaml(field: Field, longitude: number, latitude: number, botNames: string[]) {
    const data = {
      'fieldName': field.FieldName,
      'fieldContractId': field.FieldContractId,
      'originLat': latitude,
      'originLong': longitude,
      'botNames': botNames
    };
    const res = await fetch(process.env.REACT_APP_RMF_URL + "/set_rmf_map_yaml", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const json = await res.json();
    return json;
  }

  public static async postJob(fieldContractId: number) {
    const res = await fetch(APIActions.API_URL + '/Jobs?fieldContractId=' + fieldContractId, {
      method: 'POST'
    });
    const json = await res.json();
    return json;
  }

  public static async postRmf(fieldContractId: number, jobId: number) {
    const data = {
      'task_type': 'Row',
      'start_time': 0,
      'description': {
        'start_job': true,
        'job_id': jobId,
        'field_contract_id': fieldContractId
      }
    };
    const res = await fetch(process.env.REACT_APP_RMF_URL + "/submit_task", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const json = await res.json();
    return json;
  }

  //TODO: update the postOptions with body and remove url params once that method is supported
  public static async postPointOfInterest(regionId: number, lng: number, lat: number, name: string, notes?: string) {
    const escapedName = name.replaceAll(" ", "%20");
    let requestUrl = APIActions.API_URL + "/DetailPins?regionId=" + regionId + "&lng=" + lng + "&lat=" + lat + "&name=" + escapedName;
    if (notes) {
      const escapedNotes = notes.replaceAll(" ", "%20");
      requestUrl += "&notes=" + escapedNotes;
    }
    const postOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
    const res = await fetch(requestUrl, postOptions);
    const json = await res.json();
    return json;
  }
}