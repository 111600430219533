import React from "react";
import "./SideBar.css";

// Assets
import MainLogo from "../../Assets/Icons/Extended Map/Mask group.png";
import PowerIcon from "../../Assets/Icons/Extended Map/Group 103.png";
/* commenting out other assets until we know if they are needed
import SettingsIcon from "../../Assets/Icons/Extended Map/Group 2322.png";
import HomeIcon from "../../Assets/Icons/Extended Map/Group 96.png";
import FlagIcon from "../../Assets/Icons/Extended Map/Group 2320.png";
import PieChartIcon from "../../Assets/Icons/Extended Map/Group 2321.png";
import DownArrowIcon from "../../Assets/Icons/Extended Map/Vector.png";
import SearchIcon from "../../Assets/Icons/Robots Inner Page/Group 2324.png";
import NotificationIcon from "../../Assets/Icons/Robots Inner Page/Group 2323.png";
import ProfileIcon from "../../Assets/Icons/Robots Inner Page/Group 3.png";
*/

export default function SideBar(): JSX.Element {
  return (
    <div className="SideBar">
      <div>
        <img className="MainLogo" src={MainLogo} alt="Missing Asset"></img>
      </div>
      <img className="LogoutButton" src={PowerIcon} alt="Missing Asset"></img>
    </div>
  )
}