import React, { useEffect, useState, useRef } from "react";
import "./DualMonitorMapView.css";
import OverallMap from "../../Components/OverallMap/OverallMap";
import SharedPropsInterface from "../../ts/interfaces/SharedPropsInterface";
import { useNavigate } from "react-router-dom";
import { Typography } from "@formant/ui-sdk";
import { Authentication } from "@formant/data-sdk";
import APIActions from "../../Components/Utility/APIActions";

// Assets
import FieldRegionButtons from "../../Components/FieldRegionButtons/FieldRegionButtons";
import PauseRobotsButton from "../../Components/PauseRobotsButton/PauseRobotsButton";
import DeviceInfo from "../../ts/interfaces/DeviceInfo";

/**
 * Overall bot view, including robot cards, overall map, and menu buttons
 * 
 * @returns {JSX.Element} React functional component
 */
export default function DualMonitorMapView(sharedProps: SharedPropsInterface): JSX.Element {
  const mapChannelRef = useRef(new BroadcastChannel("dualMonitorChannelMap"));
  const mapChannel = mapChannelRef.current;
  const cardChannelRef = useRef(new BroadcastChannel("dualMonitorChannelCards"));
  const cardChannel = cardChannelRef.current;
  const navigate = useNavigate();

  const deviceInfo = useRef<DeviceInfo[]>([]);

  const [initialized, setInitialized] = useState<boolean>(false);

  // Initialize broadcast channel on message received event
  useEffect(() => {
    mapChannel.onmessage = (event) => {
      const message = event.data.message;
      switch (message) {
        case "map-init":
          if (event.data.sharedFilter) {
            sharedProps.setSharedFilter(event.data.sharedFilter);
          }
          if (event.data.mapStyle) {
            sharedProps.setMapStyle(event.data.mapStyle);
          }
          setInitialized(true);
          break;
        case "update-filter":
          if (event.data.sharedFilter) {
            sharedProps.setSharedFilter(event.data.sharedFilter);
          }
          break;
        case "detailed-view":
          if (deviceInfo.current.find((info) => info.device.id === event.data.deviceId)) {
            navigate('/detailedView?deviceId=' + event.data.deviceId);
          }
          break;
      }
    };
    sharedProps.setIsDualMonitorInstance(true);
    cardChannel.postMessage({message: "map-init"});
  }, []);

  // Post message with updated filter props when field and region are updated
  useEffect(() => {
    if (initialized) {
      cardChannel.postMessage({
        message: "update-filter",
        sharedFilter: sharedProps.sharedFilter,
        mapStyle: sharedProps.mapStyle
      });
    }
  }, [sharedProps.sharedFilter.field.FieldContractId, sharedProps.sharedFilter.region.RegionId]);

  // Update device info ref with sharedProps.deviceInfo update for event handlers
  useEffect(() => {
    deviceInfo.current = sharedProps.deviceInfo;
  }, [sharedProps.deviceInfo]);

  const setYamlButtonHandler = async () => {
    const currentUser = Authentication.getCurrentUser();
    const currentField = sharedProps.sharedFilter.field;
    const currentRegion = sharedProps.sharedFilter.region;
    const fieldHomePath = await APIActions.getHome(currentField.FieldContractId, currentRegion.RegionId);
    const claimedBots = [];
    sharedProps.deviceInfo.map((info) => {
      if(info.botOwner === currentUser.firstName + " " + currentUser.lastName) {
        claimedBots.push("robot" + info.robotNumber);
      }
    });
    const res = await APIActions.setRmfYaml(
      currentField, 
      fieldHomePath.coordinates[0][1], 
      fieldHomePath.coordinates[0][0],
      claimedBots
    );
    console.log(res);
  }

  return (
    <div className="OverallMapContainerDualMonitor">
      <FieldRegionButtons {...sharedProps} />
      <div className="SetYamlButtonContainer">
        <button className="SetYamlButton"
          disabled={sharedProps.deviceInfo.length <= 0 || sharedProps.sharedFilter.field.FieldContractId === 0 || sharedProps.sharedFilter.region.RegionId === 0}
          onClick={() => setYamlButtonHandler()}  
        >
          <Typography variant="h6" sx={{ color: "white", fontSize: 18, alignSelf: "center" }}>Set RMF Yaml</Typography>
        </button>
      </div>
      <OverallMap {...sharedProps} />
      <div className="PauseAllRobotsContainer">
        <PauseRobotsButton {...sharedProps}/>
      </div>
    </div>
  );
}