import React, { useCallback, useEffect, useRef, useState } from 'react';
import FilterOptions from "./ts/interfaces/FilterOptions";
import DeviceInfo from "./ts/interfaces/DeviceInfo";
import { Authentication, Device, Fleet } from "@formant/data-sdk";
import DeviceFetch from "./Components/Utility/DeviceFetch";
import { SortOption } from "./ts/enums/SortOption";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import OverallRobotView from './Pages/OverallRobotView/OverallRobotView';
import DetailedRobotView from './Pages/DetailedRobotView/DetailedRobotView';
import DualMonitorMapView from './Pages/DualMonitorMapView/DualMonitorMapView';
import Job from './ts/interfaces/Job';
import APIActions from './Components/Utility/APIActions';
import SoftwareVersions, { UNKNOWN_SOFTWARE_VERSION } from './ts/interfaces/SoftwareVersions';
import { AcknowledgedEvent, eventsMatch, EVENT_ACKNOWLEDGEMENT_TIMEOUT, BotEventWithRobotNumber } from "./ts/interfaces/BotEvent";

function App() {
  const fetchDeviceInfoRefreshTimer = 2500;
  const rosieVersion = process.env.REACT_APP_VERSION || UNKNOWN_SOFTWARE_VERSION;
  const versions = {
    rosie: rosieVersion,
    services: UNKNOWN_SOFTWARE_VERSION,
  }

  const heartbeatCounterThreshold = 5;

  const [sharedFilter, setSharedFilter] = useState<FilterOptions>({
    group: "",
    connection_status: ["offline", "online"],
    field: {FieldName: "", FieldContractId: 0},
    region: {Name: "", RegionId: 0},
    sortOption: SortOption.SORT_BY_ROBOT_NUMBER,
  });
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo[]>([]);
  const [mapStyle, setMapStyle] = useState<string>("");
  const [isDualMonitorInstance, setIsDualMonitorInstance] = useState<boolean>(false);
  const [currentJob, setCurrentJob] = useState<Job[]>([])
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [currentSoftwareVersions, setCurrentSoftwareVersions] = useState<SoftwareVersions>(versions);
  const [acknowledgedEvents, setAcknowledgedEvents] = useState<AcknowledgedEvent[]>([]);

  /*const sharedProps: SharedPropsInterface = {
    sharedFilter: sharedFilter,
    setSharedFilter: setSharedFilter,
    deviceInfo: deviceInfo,
    setDeviceInfo: setDeviceInfo,
    mapStyle: mapStyle,
    setMapStyle: setMapStyle,
    isDualMonitorInstance: isDualMonitorInstance,
    setIsDualMonitorInstance: setIsDualMonitorInstance,
    currentJob: currentJob,
    setCurrentJob: setCurrentJob,
    selectedDevices: selectedDevices,
    setSelectedDevices: setSelectedDevices,
    appVersions: versions,
    desiredSoftwareVersions: desiredSoftwareVersions,
  };*/

  const [deviceInfoFetched, setDeviceInfoFetched] = useState<boolean>(false);

  const deviceInfoCallback = useCallback((info: DeviceInfo[], fetched: boolean) => {
    if (info) {
      setDeviceInfo(info);
    }
    if (fetched !== null) {
      setDeviceInfoFetched(fetched);
    }
  }, []);

  const selectedDevicesRef = useRef<Device[]>(selectedDevices);
  selectedDevicesRef.current = selectedDevices;
  const deviceInfoFetchedRef = useRef<boolean>(deviceInfoFetched);
  deviceInfoFetchedRef.current = deviceInfoFetched;
  const deviceInfoRef = useRef<DeviceInfo[]>(deviceInfo);
  deviceInfoRef.current = deviceInfo;
  const isUpdatingRef = useRef<boolean>(false);
  const heartbeatRef = useRef<number>(0);
  const allBotEventsRef = useRef<BotEventWithRobotNumber[]>([]);

  useEffect(() => {
    Authentication.waitTilAuthenticated().then(
      function (isAuthenticated) {
        if (isAuthenticated) {
          console.log("Listening for refresh");
          Authentication.listenForRefresh();
        }
      }
    )
  }, [])

  // Update devices in set interval
  useEffect(() => {
    const deviceInfoFetchInterval = setInterval(() => {
      if (!isUpdatingRef.current) {
        isUpdatingRef.current = true;
        heartbeatRef.current = 0;
        fetchDeviceInfo();
      }
      heartbeatRef.current += 1;
      if (heartbeatRef.current > heartbeatCounterThreshold) {
        heartbeatRef.current = 0;
        isUpdatingRef.current = false;
      }
    }, fetchDeviceInfoRefreshTimer);
    return function cleanup() {
      clearInterval(deviceInfoFetchInterval)
    }
  }, []);

  // Query for desired software versions and the current microservice version
  useEffect(() => {
    retrieveCurrentServicesVersion();
  }, [])

  /**
   * Fetch device info data
   * 
   * @async
   */
  const fetchDeviceInfo = async () => {
    if (!Authentication.isAuthenticated()) {
      await Authentication.waitTilAuthenticated();
    }
    if (selectedDevicesRef.current && selectedDevicesRef.current.length > 0) {
      const onlineDevices = await Fleet.getOnlineDevices();
      const newDeviceInfo: DeviceInfo[] = [];
      const allBotEvents: BotEventWithRobotNumber[] = [];
      for (const device of selectedDevicesRef.current) {
        const deviceIsOnline = onlineDevices.findIndex((onlineDevice) => onlineDevice.id === device.id) >= 0;
        const existingDeviceInfo = deviceInfoRef.current.find((info) => info.id === device.id);
        if (!existingDeviceInfo) {
          device.on("disconnect", () => {
            console.debug("Device " + device.name + " disconnected");
            const deviceInfo = deviceInfoRef.current.find((info) => info.id === device.id);
            if (deviceInfo) {
              DeviceFetch.cleanRealtimeListeners(deviceInfo);
            }
          });
        }
        const newInfo = await DeviceFetch.updateDeviceInfo(device, deviceIsOnline, existingDeviceInfo);
        newDeviceInfo.push(newInfo);
        allBotEvents.push(...newInfo.events.map((e) => { return {event: e, robotNumber: newInfo.robotNumber}; }));
      }
      deviceInfoCallback(newDeviceInfo, !deviceInfoFetchedRef.current);
      allBotEventsRef.current = [...allBotEvents];
    }
    isUpdatingRef.current = false;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const indexesToUnacknowledge = [];
      acknowledgedEvents.forEach((acknowledgedEvent, index) => {
        const existingIndex = allBotEventsRef.current.findIndex((e) => { return eventsMatch(acknowledgedEvent.event, e.event); });
        const nowTimestamp = new Date(Date.now());
        const timeDifference = nowTimestamp.getTime() - acknowledgedEvent.timestamp.getTime();
        // un-acknowledge event if it is not in the list of known events OR if it has been too long since it was acknowledged
        if ((existingIndex === -1) || (timeDifference > EVENT_ACKNOWLEDGEMENT_TIMEOUT)) {
          indexesToUnacknowledge.push(index);
        }
      });
      if (indexesToUnacknowledge && indexesToUnacknowledge.length > 0) {
        indexesToUnacknowledge.reverse();
        const newAcknowledgedEvents = [...acknowledgedEvents];
        indexesToUnacknowledge.forEach((index) => {
          newAcknowledgedEvents.splice(index, 1);
        });
        setAcknowledgedEvents(newAcknowledgedEvents);
      }
    }, 1000);
    return () => { clearInterval(interval); }
  }, [JSON.stringify(acknowledgedEvents)]);

  const retrieveCurrentServicesVersion = async() => {
    const currentServicesVersion = await APIActions.getServicesVersion();
    const newSoftwareVersions = {...versions};
    newSoftwareVersions.services = currentServicesVersion;
    setCurrentSoftwareVersions(newSoftwareVersions);
  }

  // TODO: can we move header and sidebar to this file so we don't need to pass appVersions as a prop?
  return (
    <Router>
      <Routes>
        <Route 
          path='/' 
          element={
            <OverallRobotView {
              ...{
                sharedFilter: sharedFilter,
                setSharedFilter: setSharedFilter,
                deviceInfo: deviceInfo,
                setDeviceInfo: setDeviceInfo,
                mapStyle: mapStyle,
                setMapStyle: setMapStyle,
                isDualMonitorInstance: isDualMonitorInstance,
                setIsDualMonitorInstance: setIsDualMonitorInstance,
                currentJob: currentJob,
                setCurrentJob: setCurrentJob,
                selectedDevices: selectedDevices,
                setSelectedDevices: setSelectedDevices,
                deviceInfoFetched: deviceInfoFetched,
                appVersions: currentSoftwareVersions,
                acknowledgedEvents: acknowledgedEvents,
                setAcknowledgedEvents: setAcknowledgedEvents,
              }
            }/>
          } 
        />
        <Route 
          path='/detailedView' 
          element={
            <DetailedRobotView {
              ...{
                sharedFilter: sharedFilter,
                setSharedFilter: setSharedFilter,
                deviceInfo: deviceInfo,
                setDeviceInfo: setDeviceInfo,
                mapStyle: mapStyle,
                setMapStyle: setMapStyle,
                isDualMonitorInstance: isDualMonitorInstance,
                setIsDualMonitorInstance: setIsDualMonitorInstance,
                currentJob: currentJob,
                setCurrentJob: setCurrentJob,
                selectedDevices: selectedDevices,
                setSelectedDevices: setSelectedDevices,
                deviceInfoFetched: deviceInfoFetched,
                appVersions: currentSoftwareVersions,
                acknowledgedEvents: acknowledgedEvents,
                setAcknowledgedEvents: setAcknowledgedEvents,
              }
            }/>
          } />
        <Route 
          path='/dualMonitorMapView' 
          element={
            <DualMonitorMapView {
              ...{
                sharedFilter: sharedFilter,
                setSharedFilter: setSharedFilter,
                deviceInfo: deviceInfo,
                setDeviceInfo: setDeviceInfo,
                mapStyle: mapStyle,
                setMapStyle: setMapStyle,
                isDualMonitorInstance: isDualMonitorInstance,
                setIsDualMonitorInstance: setIsDualMonitorInstance,
                currentJob: currentJob,
                setCurrentJob: setCurrentJob,
                selectedDevices: selectedDevices,
                setSelectedDevices: setSelectedDevices,
                deviceInfoFetched: deviceInfoFetched,
                appVersions: currentSoftwareVersions,
                acknowledgedEvents: acknowledgedEvents,
                setAcknowledgedEvents: setAcknowledgedEvents,
              }
            }/>
          } />
      </Routes>
    </Router>
 );
}

export default App;
