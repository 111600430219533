export const softwareNameLookup = {
  weedbot: "WeedbotApp",
  mcu: "McuApp",
  pixhawk: "INS",
  formantAgent: "FormantAgent",
  formantAdapter: "FormantAdapter",
  rosie: "ROSIE",
  services: "Grf.Services",
}

export const UNKNOWN_SOFTWARE_VERSION = "unknown";
export const MISSING_SOFTWARE_VERSION = "missing";

export const unknownSoftwareVersions = {
  weedbot: UNKNOWN_SOFTWARE_VERSION,
  mcu: UNKNOWN_SOFTWARE_VERSION,
  pixhawk: UNKNOWN_SOFTWARE_VERSION,
  formantAgent: UNKNOWN_SOFTWARE_VERSION,
  formantAdapter: UNKNOWN_SOFTWARE_VERSION,
  rosie: UNKNOWN_SOFTWARE_VERSION,
  services: UNKNOWN_SOFTWARE_VERSION,
};

export default interface SoftwareVersions {
  weedbot?: string,
  mcu?: string,
  pixhawk?: string,
  formantAgent?: string,
  formantAdapter?: string,
  rosie?: string,
  services?: string,
}