import { BotState } from "../../ts/interfaces/BotState";
import { BotStateRequest } from "../../ts/interfaces/BotStateRequest";
import DeviceInfo from "../../ts/interfaces/DeviceInfo";
import Field from "../../ts/interfaces/Field";
import BotStateRow from "../../ts/interfaces/BotStateRow";

export default class BotStateUtility {
  public static mapBotStateRequest(currentBotState: BotState, targetBotState: BotState): number {
    let botStateRequest = BotStateRequest.NONE;

    // All states can activate e-stop
    if (targetBotState === BotState.E_STOP) {
      botStateRequest = BotStateRequest.E_STOP_ACTIVATE;
    } else {
      // Map each state interaction together to fetch the proper Bot State Request to send
      switch (currentBotState) {
        case BotState.E_STOP: {
          switch (targetBotState) {
            case BotState.IDLE:
              botStateRequest = BotStateRequest.E_STOP_RELEASE;
              break;
          }
          break;
        }
        case BotState.IDLE: {
          switch (targetBotState) {
            case BotState.TELEOP:
              botStateRequest = BotStateRequest.CLAIM_TELEOP_CONTROL;
              break;
            case BotState.READY:
              botStateRequest = BotStateRequest.ASSIGN_TO_FIELD;
              break;
          }
          break;
        }
        case BotState.TELEOP: {
          switch (targetBotState) {
            case BotState.IDLE:
            case BotState.READY:
              botStateRequest = BotStateRequest.RELEASE_TELEOP_CONTROL;
              break;
          }
          break;
        }
        case BotState.READY: {
          switch (targetBotState) {
            case BotState.IDLE:
              botStateRequest = BotStateRequest.RELEASE_FROM_FIELD;
              break;
            case BotState.TELEOP:
              botStateRequest = BotStateRequest.CLAIM_TELEOP_CONTROL;
              break;
            case BotState.RUNNING:
              botStateRequest = BotStateRequest.START_AUTO_RUNNING;
              break;
          }
          break;
        }
        case BotState.RUNNING: {
          switch (targetBotState) {
            case BotState.IDLE:
              botStateRequest = BotStateRequest.RELEASE_FROM_FIELD;
              break;
            case BotState.READY:
              botStateRequest = BotStateRequest.STOP_AUTO_RUNNING;
              break;
            case BotState.TELEOP:
              botStateRequest = BotStateRequest.CLAIM_TELEOP_CONTROL;
              break;
          }
          break;
        }
        case BotState.AUTO_PAUSE: {
          switch (targetBotState) {
            case BotState.IDLE:
              botStateRequest = BotStateRequest.RELEASE_FROM_FIELD;
              break;
            case BotState.TELEOP:
              botStateRequest = BotStateRequest.CLAIM_TELEOP_CONTROL;
              break;
          }
          break;
        }
        case BotState.CUTTER_RESTART: {
          switch (targetBotState) {
            case BotState.IDLE:
              botStateRequest = BotStateRequest.RELEASE_FROM_FIELD;
              break;
            case BotState.TELEOP:
              botStateRequest = BotStateRequest.CLAIM_TELEOP_CONTROL;
              break;
          }
          break;
        }
      }
    }
    return botStateRequest;
  }

  public static sendStateRequest = (deviceInfo: DeviceInfo, ownerName: string, currentField?: Field, botStateRow?: BotStateRow) => {
    try {
      // Collect information needed for bot state request
      const requestedState = botStateRow ? BotStateUtility.mapBotStateRequest(botStateRow.currentBotState, botStateRow.targetBotState) : BotStateRequest.NONE;
      const fieldName = currentField ? (currentField.FieldContractId === 0 ? "None" : currentField.FieldName) : "";
      const data = {
        header: {
          robot_id: "robot" + deviceInfo.robotNumber
        },
        requested_bot_op_state_transition: requestedState,
        description: 'request from UI',
        field_id: fieldName,
        fleet_id: 'weedbot',
        fleet_member_id: 'weedbot' + deviceInfo.robotNumber,
        turker_id: ownerName,
        override_key: 1234,
        default_end_effector_on_state: [true, true]
      }
      // Reformat string for ROS publish data
      const dataString = JSON.stringify(data)
        .replace(/"([^"]+)":/g, '$1:')
        .replace(/\uFFFF/g, '\\"')
        .replaceAll('"', "'")
        .replaceAll(':', ': ')
        .replaceAll(',', ', ')
      // Send ros data to bot
      console.log("Data sent: " + dataString);
      const ROS_COMMAND = "ros2 service call /robot" + deviceInfo.robotNumber + "/BotStateTransition greenfield/srv/BotStateTransition ";
      deviceInfo.realtimeRosCommandChannel.send(ROS_COMMAND + '"' + dataString + '"');
    } catch (e) {
      console.warn("Error requesting state from device (" + deviceInfo.device.name + "): " + e);
    }
  }
}