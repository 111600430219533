export default interface BotEvent {
  stamp: {sec: number, nanosec: number},
  timestamp: Date,
  pathId: string,
  name: string,
  //severity: "info" | "advisory" | "caution" | "warning",
  severity: string,
  description: string,
  data: string,
}

export interface AcknowledgedEvent {
  event: BotEvent, 
  robotNumber: number, 
  timestamp: Date
}

export interface BotEventWithRobotNumber {
  event: BotEvent,
  robotNumber: number,
}

export const EVENT_ACKNOWLEDGEMENT_TIMEOUT = 6000;

export const eventsMatch = (e1: BotEvent, e2: BotEvent) => {
  return (e1.description === e2.description && e1.timestamp === e2.timestamp); 
}

export const eventIsAcknowledged = (event: BotEvent, robotNumber: number, acknowledgedEvents: AcknowledgedEvent[]) => {
  return acknowledgedEvents.some((e) => { 
    return (e.robotNumber === robotNumber && eventsMatch(e.event, event)); 
  });
}

export enum EventName {
  CROSSTRACK_TOO_LARGE = "CrossTrackTooLarge",
  CUTTER_MOTOR_LEFT_STALL = "LeftCutterMotorStall",
  CUTTER_MOTOR_RIGHT_STALL = "RightCutterMotorStall",
  DRIVE_MOTOR_ERROR = "DriveMotorError",
  LEFT_FEELER_FAILURE = "LeftFeelerFailure",
  RIGHT_FEELER_FAILURE = "RightFeelerFailure",
  GEOFENCE_INVALID = "GeofenceInvalid",
  GEOFENCE_BREACH = "GeofenceBreach",
  GPS_FIX_LOST = "GpsFixLost",
  INS_TEMPERATURE_TOO_HIGH = "InsTemperatureTooHigh",
  CPU_TEMPERATURE_TOO_HIGH = "CpuTemperatureTooHigh",
  MCU_COMMS_UNRESPONSIVE = "McuCommsUnresponsive",
  MCU_COMMS_UNRECOGNIZED = "McuCommsUnrecognized",
  VCU_AMBIENT_TEMP_TOO_HIGH = "VcuAmbientTemperatureTooHigh",
  PATH_ERROR_TOO_LARGE = "PathErrorTooLarge",
}

export enum EventDisplayName {
  CROSSTRACK_TOO_LARGE = "Robot is Off the Path",
  // . . .
  CUTTER_MOTOR_LEFT_STALL = "Left Cutter has Stalled",
  CUTTER_MOTOR_RIGHT_STALL = "Right Cutter has Stalled",
  // . . .
  LEFT_FEELER_FAILURE = "Left Feeler is Stuck",
  RIGHT_FEELER_FAILURE = "Right Feeler is Stuck",
  // . . .
  INS_TEMPERATURE_TOO_HIGH = "Pixhawk (INS) is Overheating",
  // . . .
  PATH_ERROR_TOO_LARGE = "Robot is Too Close to a Crop row",
}

export const getEventDisplayName = (eventName: string) => {
  switch(eventName) {
    case EventName.CROSSTRACK_TOO_LARGE:
      return EventDisplayName.CROSSTRACK_TOO_LARGE;
    case EventName.CUTTER_MOTOR_LEFT_STALL:
      return EventDisplayName.CUTTER_MOTOR_LEFT_STALL;
    case EventName.CUTTER_MOTOR_RIGHT_STALL:
      return EventDisplayName.CUTTER_MOTOR_RIGHT_STALL;
    case EventName.LEFT_FEELER_FAILURE:
      return EventDisplayName.LEFT_FEELER_FAILURE;
    case EventName.RIGHT_FEELER_FAILURE:
      return EventDisplayName.RIGHT_FEELER_FAILURE;
    case EventName.INS_TEMPERATURE_TOO_HIGH:
      return EventDisplayName.INS_TEMPERATURE_TOO_HIGH;
    case EventName.PATH_ERROR_TOO_LARGE:
      return EventDisplayName.PATH_ERROR_TOO_LARGE;
    default:
      return eventName;
  }
}