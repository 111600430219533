import React, { useState, useEffect } from "react";
import "./PauseRobotsButton.css";
import { Typography } from "@mui/material";
import SharedPropsInterface from "../../ts/interfaces/SharedPropsInterface";
import DeviceInfo from "../../ts/interfaces/DeviceInfo";

/**
 * Component for pausing all robots
 *
 * @returns {JSX.Element} React functional component
 */
export default function PauseRobotsButton(sharedProps: SharedPropsInterface): JSX.Element {
  const [disabled, setDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  
  // Only enable button when devices have loaded
  useEffect(() => {
    if (sharedProps.deviceInfo.length > 0 && !loading) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  },[sharedProps.deviceInfo]);

  useEffect(() => {
    if (loading) {
      const fieldContractId = sharedProps.sharedFilter.field.FieldContractId;
      sharedProps.deviceInfo.map((info) => {
        if (info.realtimeRosCommandChannel) {
          sendEStopEnableRequest(info, fieldContractId);
        }
      })
      setLoading(false);
      setDisabled(true);
    }
  }, [loading]);

  const sendEStopEnableRequest = (deviceInfo: DeviceInfo, fieldContractId: number) => {
    try {
      // Collect information needed for bot state request
      const currentTime = Date.now() / (10**3);
      const data = {
        stamp: {
          sec: Math.floor(currentTime),
          nanosec: Math.floor((currentTime % 1) * (10**6))
        },
        field_id: fieldContractId.toString(),
        e_stop_on: true
      }
      // Reformat string for ROS publish data
      const dataString = JSON.stringify(data)
        .replace(/"([^"]+)":/g, '$1:')
        .replace(/\uFFFF/g, '\\"')
        .replaceAll('"', "'")
        .replaceAll(':', ': ')
        .replaceAll(',', ', ')
      // Send ros data to bot
      console.log("Data sent: " + dataString);
      const ROS_COMMAND = "ros2 topic pub --once /EStop greenfield/msg/EStop ";
      deviceInfo.realtimeRosCommandChannel.send(ROS_COMMAND + '"' + dataString + '"');
    } catch (e) {
      console.warn("Error requesting state from device (" + deviceInfo.device.name + "): " + e);
    }
  }

  const handlePauseRobots = () => {
    setLoading(true);
    setDisabled(true);
  }

  const displayButtonText = () => {
    let element = (
      <Typography variant="h1" sx={{ color: "white" }}>Pause All Robots</Typography>
    );
    if (loading) {
      element = (
        <div className="PauseRobotsButtonSpinner"></div>
      )
    }
    return element;
  }

  return (
    <button className="PauseRobotsButton" disabled={disabled} onClick={() => handlePauseRobots()}>
      {displayButtonText()}
    </button>
  );
}
