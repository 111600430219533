import React, { useState, useEffect } from "react";
import "./FieldRegionButtons.css";
import { Box } from "@formant/ui-sdk";
import {
  MenuItem,
  Select,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import SharedPropsInterface from "../../ts/interfaces/SharedPropsInterface";
import APIActions from "../Utility/APIActions";
import FieldWithRegions from "../../ts/interfaces/FieldWithRegions";
import Field from "../../ts/interfaces/Field";
import Region from "../../ts/interfaces/Region";

/**
 * Buttons above map with select field/region dropdowns, number display buttons
 *
 * @param {SharedPropsInterface} sharedProps - Properties shared across OverallRobotView
 * @returns {JSX.Element} React functional component
 */
export default function FieldRegionButtons(
  sharedProps: SharedPropsInterface
): JSX.Element {
  const { sharedFilter, setSharedFilter } = sharedProps;

  const [fieldsArray, setFieldsArray] = useState<Field[]>([]);
  const [fieldNamesArray, setFieldNamesArray] = useState<string[]>([]);
  const [regionsArray, setRegionsArray] = useState<Region[]>([]);
  const [regionNamesArray, setRegionNamesArray] = useState<string[]>([]);
  const [currentField, setCurrentField] = useState(sharedProps.sharedFilter.field);
  const [currentRegion, setCurrentRegion] = useState(sharedProps.sharedFilter.region);
  const [isLoadingRegions, setIsLoadingRegions] = useState(false); // True if regions are loading, false otherwise

  // Set fields array when component loads
  useEffect(() => {
    (async () => {
      await getFieldsArray();
    })();
  }, []);

  // Whenever field changes, update regions array
  useEffect(() => {
    updateRegions();
  }, [currentField]);

  useEffect(() => {
    if (currentField !== sharedProps.sharedFilter.field) {
      setCurrentField(sharedProps.sharedFilter.field);
    }
    if (currentRegion !== sharedProps.sharedFilter.region) {
      setCurrentRegion(sharedProps.sharedFilter.region);
    }
  }, [sharedProps.sharedFilter]);

  /**
   * Fetches array of available fields
   *
   * @async
   */
  const getFieldsArray = async () => {
    const fieldsArray: Field[] = [];
    const fieldNamesArray: string[] = [];

    const fieldsAndRegionsArray = await APIActions.getFieldsAndRegionsJSON();

    fieldsAndRegionsArray.forEach((element: FieldWithRegions) => {
      fieldsArray.push({FieldName: element.FieldName, FieldContractId: element.FieldContractId} as Field);
      fieldNamesArray.push(element.FieldName);
    }); 
    setFieldsArray(fieldsArray);
    setFieldNamesArray(fieldNamesArray);
  };

  /**
   * Fetches array of available regions by name based on selected field
   *
   * @async
   * @returns {Promise<string[]>} Array of available regions by name
   */
  const getRegionsArray = async (): Promise<Region[]> => {
    const regionsArray: Region[] = [];
    const regionNamesArray: string[] = [];

    const fieldsAndRegionsArray = await APIActions.getFieldsAndRegionsJSON();

    fieldsAndRegionsArray.forEach((element: FieldWithRegions) => {
      if (element.FieldContractId === sharedFilter.field.FieldContractId) {
        element.Regions.forEach((region: Region) => {
          regionsArray.push(region);
          regionNamesArray.push(region.Name);
        });
      }
    });
    setRegionNamesArray(regionNamesArray);
    return regionsArray;
  };

  /**
   * Updates regions array state, sets region loading state
   *
   * @async
   * @returns {Promise<void>} Array of available regions
   */
  const updateRegions = async (): Promise<void> => {
    setIsLoadingRegions(true);
    setRegionsArray(await getRegionsArray());
    setIsLoadingRegions(false);
  };

  /**
   * Field select handler, sets shared filter and current field/region 
   * states if new field selected
   *
   * @param {SelectChangeEvent<string>} e
   */
  const onSelectField = (e: SelectChangeEvent<string>) => {
    const defaultField = {FieldName: "", FieldContractId: 0};
    const defaultRegion = {Name: "", RegionId: 0} as Region;
    const newSharedFilter = { ...sharedFilter };
    if (newSharedFilter.field.FieldName !== e.target.value) {
      const newField = fieldsArray.find((field) => field.FieldName === e.target.value);
      if (newField) {
        newSharedFilter.field = newField;
      } else {
        newSharedFilter.field = defaultField;
      }
      newSharedFilter.region = defaultRegion;
      setSharedFilter(newSharedFilter);
      setCurrentField(newSharedFilter.field);
      setCurrentRegion(defaultRegion);
    }
  };

  /**
   * Region select handler, sets shared filter and current region 
   * states if new region selected
   *
   * @param {SelectChangeEvent<string>} e
   */
  const onSelectRegion = (e: SelectChangeEvent<string>) => {
    const defaultRegion = {Name: "", RegionId: 0} as Region;
    const newSharedFilter = { ...sharedFilter };
    if (newSharedFilter.region.Name !== e.target.value) {
      const newRegion = regionsArray.find((region) => region.Name === e.target.value);
      if (newRegion) {
        newSharedFilter.region = newRegion;
      } else {
        newSharedFilter.region = defaultRegion;
      }
      setSharedFilter(newSharedFilter);
      setCurrentRegion(newSharedFilter.region);
    }
  };

  const getSelect = (
    value: string,
    label: string,
    onChange: (e: SelectChangeEvent<string>) => void,
    items: string[],
    disabled = false
  ) => {
    return (
      <FormControl>
        <InputLabel
          shrink={false}
          sx={{ color: "white", fontSize: 22 }}
          id={label}
        >
          {value === "" ? label : ""}
        </InputLabel>
        <Select
          labelId={label}
          value={value}
          label={label}
          disabled={disabled}
          onChange={(e) => onChange(e)}
          sx={{
            margin: "5px",
            backgroundColor: disabled ? "grey" : "#ffba42",
            border: "0px",
            color: "white",
            fontSize: "22px",
            width: "220px",
            height: "60px",
            borderRadius: 2,
            textAlign: "left",
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {items.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const getNumberDisplay = (
    numberString: string,
    text: string,
    showAltStyle = false
  ) => {
    return (
      <Box
        sx={{
          margin: "5px",
          backgroundColor: showAltStyle ? "transparent" : "#ffba42",
          color: showAltStyle ? "#ffba42" : "white",
          border: showAltStyle ? "1px solid #ffba42" : "",
          width: "220px",
          minWidth: "220px",
          height: "60px",
          borderRadius: 2,
          alignItems: "center",
          justifyContent: "space-between",
          display: "flex",
          textAlign: "left",
        }}
      >
          <Typography variant="h1" sx={{ margin: "15px", fontWeight: "bold" }}>
            {numberString}
          </Typography>
          <Typography variant={"body2"} sx={{ lineHeight: "18px", fontSize: 16 }}>
            {text}
          </Typography>
        </Box>
    );
  };

  return (
    <div className="FieldRegionButtonsContainer">
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          p: 2,
          justifySelf: "left",
          alignItems: "center",
          width: "100%",
          height: "100px",
        }}
      >
        {getNumberDisplay("02", "Number of Active bots in field")}
        {getNumberDisplay("00", "Low Battery Bots in Field", true)}
      </Box>
      <Box
        sx={{
          p: 2,
          justifySelf: "right",
          alignItems: "center",
          height: "100px",
        }}
      >
        {getSelect(currentField.FieldName, "Select Field", onSelectField, fieldNamesArray)}
        {getSelect(
          currentRegion.Name,
          isLoadingRegions ? "Loading..." : "Select Region",
          onSelectRegion,
          regionNamesArray,
          (sharedFilter.field.FieldContractId === 0 && regionsArray.length === 0) ||
            isLoadingRegions
        )}
      </Box>
    </div>
  );
}
