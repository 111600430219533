import React, { useEffect, useState, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Typography } from "@formant/ui-sdk";
import './DetailedRobotView.css';
import RealtimePlayer from '../../Components/UI/RealtimePlayer';
import DeviceInfo from "../../ts/interfaces/DeviceInfo";
import mapboxgl, { Map, GeoJSONSource, Popup } from "mapbox-gl";
import SharedPropsInterface from "../../ts/interfaces/SharedPropsInterface";
import APIActions from "../../Components/Utility/APIActions";
import Region from "../../ts/interfaces/Region";
import BotScaling from "../../Components/Utility/BotScaling";
import BotEvent from "../../ts/interfaces/BotEvent";
import { BotState } from "../../ts/interfaces/BotState";
import { BotStateRequest } from "../../ts/interfaces/BotStateRequest";
import BotStateUtility from "../../Components/Utility/BotStateUtility";
import { Authentication, IRtcStreamMessage } from "@formant/data-sdk";
import BotMarker from "../../ts/interfaces/BotMarker";
import EventsDialogBox from "../../Components/EventsDialogBox/EventsDialogBox";
import SideBar from "../../Components/Menus/SideBar";
import { UNKNOWN_SOFTWARE_VERSION } from "../../ts/interfaces/SoftwareVersions";
import Header from "../../Components/Menus/Header";
import { floor } from "lodash";
import SeverityNames from "../../Components/EventsDialogBox/SeverityNames";
import { eventIsAcknowledged } from "../../ts/interfaces/BotEvent";
import { BotComponentName, BotComponentState, getCutterStatusString, getFeelerStatusString } from "../../ts/interfaces/ComponentState";
import ComponentStateIcon from "../../Components/UI/ComponentStateIcon";
import Grid from '@mui/material/Unstable_Grid2/Grid2';

// Assets
import ChargeIcon from "../../Assets/Icons/Robots Inner Page/Mask group.png";
import CutterIcon from "../../Assets/Icons/Robots Inner Page/Mask group-3.png";
import PingIcon from "../../Assets/Icons/Robots Inner Page/Mask group-2.png";
import ChargeIconOrange from "../../Assets/Icons/Robots Inner Page/Mask group-orange.png";
import LightsIcon from "../../Assets/Icons/Dashboard/Group 2470.png";
import FanIcon from "../../Assets/Icons/Robots Inner Page/fan-small-white-2.png";
import FeelerIcon from "../../Assets/Icons/Robots Inner Page/touch-small-white-2.png";

type Position = number[];

/**
 * Detailed bot view, including robot info, focused camera, and diagnostics
 * 
 * @returns {JSX.Element} React functional component
 */
export default function DetailedRobotView(sharedProps: SharedPropsInterface): JSX.Element {
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || ""; 
  const defaultLongitude = 0;
  const defaultLatitude = 0;
  const defaultZoom = 22;
  const defaultMiniMapZoom = 12.84;
  const maxZoom = 24;
  const zoomSpeed = 0.25;
  const focusedVideoWidthOffset = -50;
  const focusedVideoWidthRatio = 16;
  const focusedVideoHeightRatio = 9;
  const botMarkerPopupOffset = [25, -150];
  const defaultMapStyle = "mapbox://styles/mapbox/satellite-v9";
  const alleyTextRenderThreshold = 22;
  const alleyLabelWidth = 75;
  const alleyLabelHeight = 25;
  const botImageHeight = 954;
  const botImageWidth = 1541;
  const startBotScaling = 20;
  const botPathDrawTime = 1000;
  const pendingStateChangeTimeout = 15;

  let botPathInterval;

  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<Map | null>(null);
  const miniMapContainer = useRef<HTMLDivElement>(null);
  const miniMap = useRef<Map | null>(null);
  const teleopEnableRef = useRef<boolean>(false);
  const joystickPosition = useRef({x: 0, z: 0});
  const videoFocusDimensions = useRef([0, 0]);
  const mapChannelRef = useRef(new BroadcastChannel("dualMonitorChannelMap"));
  const mapChannel = mapChannelRef.current;
  const cardChannelRef = useRef(new BroadcastChannel("dualMonitorChannelCards"));
  const cardChannel = cardChannelRef.current;
  const perimeterDataRef = useRef({type:"", coordinates:[]});
  const focusStateRef = useRef<string>("");
  const popup = useRef<Popup>(new Popup({ 
    offset: 10,
    closeButton: false,
    closeOnClick: false
  }));
  const deviceInfoRef = useRef<DeviceInfo[]>(sharedProps.deviceInfo);

  const [searchParams,] = useSearchParams();
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>(sharedProps.deviceInfo.find((device) => device.device.id === searchParams.get("deviceId")));
  //const [cutterStatus, setCutterStatus] = useState<string>(deviceInfo ? deviceInfo.cutterStatus : null);
  //const [lightsDesiredStatus, setLightsDesiredStatus] = useState<string>(deviceInfo ? deviceInfo.lightsStatus : null);
  //const [fanDesiredStatus, setFanDesiredStatus] = useState<string>(deviceInfo ? deviceInfo.vcuFanStatus : null);
  const [focusState, setFocusState] = useState<string>("map");
  const [longitude, setLongitude] = useState<number>(deviceInfo ? deviceInfo.longitude : defaultLongitude);
  const [latitude, setLatitude] = useState<number>(deviceInfo ? deviceInfo.latitude : defaultLatitude);
  const [joystickListenerExists, setJoystickListenerExists] = useState<boolean>(false);
  const [dualMonitorMode, setDualMonitorMode] = useState<boolean>(sharedProps.isDualMonitorInstance);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [miniMapBounds, setMiniMapBounds] = useState<mapboxgl.LngLatBounds>(null);
  const [completedPaths, setCompletedPaths] = useState([]);
  const [jobCompletionPercentage, setJobCompletionPercentage] = useState<string>(null);
  const [drawPath, setDrawPath] = useState<boolean>(false);
  const [botPathPoints, setBotPathPoints] = useState([]);
  const [realtimePathFetched, setRealtimePathFetched] = useState<boolean>(false);
  const [displayOwnershipPrompt, setDisplayOwnershipPrompt] = useState<boolean>(false);
  const [targetBotState, setTargetBotState] = useState<BotState>(null);
  const [pendingStateChange, setPendingStateChange] = useState<boolean>(false);
  const [markers, setMarkers] = useState<BotMarker[]>([]);
  const [deviceInfoIndex, setDeviceInfoIndex] = useState<number>(0);
  const [eventsDialogOpen, setEventsDialogOpen] = useState<boolean>(false);
  const [formantAgentVersion, setFormantAgentVersion] = useState<string>(UNKNOWN_SOFTWARE_VERSION);

  const navigate = useNavigate();

  const currentSoftwareVersions = {
    ...sharedProps.appVersions,
    weedbot: deviceInfo.softwareVersions.weedbot,
    mcu: deviceInfo.softwareVersions.mcu,
    pixhawk: deviceInfo.softwareVersions.pixhawk,
    formantAgent: formantAgentVersion,
    formantAdapter: deviceInfo.softwareVersions.formantAdapter,
  };

  // Initialize video container size
  useEffect(() => {
    videoFocusDimensions.current = getVideoContainerSize();

    // initialize event listeners
    // Set realtime video dimensions whenever the windo is resized
    window.addEventListener("resize", () => {
      videoFocusDimensions.current = getVideoContainerSize();
    });

    // Channel listener for dual monitor mode
    mapChannel.onmessage = (event) => {
      switch (event.data.message) {
        case "detailed-view": 
          window.open(process.env.REACT_APP_SITE_URL + '/detailedView?deviceId=' + event.data.deviceId + '&auth=' + event.data.accessToken + '&dualMonitor=true',
            "dualMonitorMode",
            "popup width=" + window.innerWidth + "px height=" + window.innerHeight + "px"
          );
          break;
        case "map-init":
          if (event.data.sharedFilter) {
            sharedProps.setSharedFilter(event.data.sharedFilter);
          }
          if (event.data.mapStyle) {
            sharedProps.setMapStyle(event.data.mapStyle);
          }
          setInitialized(true);
          break;
      }
    };

    // Allow bot marker info popup to follow the mouse
    document.addEventListener("mousemove", (event) => {
      const element = document.getElementById("popup");
      element.style.left = String(event.pageX + botMarkerPopupOffset[0]) + "px";
      element.style.top = String(event.pageY + botMarkerPopupOffset[1]) + "px";
    });

    // Keydown listener for teleop
    document.addEventListener("keydown", (event) => {
      switch (event.key) {
        case "ArrowUp":
          updateJoystickPosition(1, null);
          break;
        case "ArrowDown":
          updateJoystickPosition(-1, null);
          break;
        case "ArrowLeft":
          updateJoystickPosition(null, -1);
          break;
        case "ArrowRight":
          updateJoystickPosition(null, 1);
          break;
        default:
          break;
      }
    });

    // Keyup listener for teleop
    document.addEventListener("keyup", (event) => {
      switch (event.key) {
        case "ArrowUp":
        case "ArrowDown":
          updateJoystickPosition(0, null);
          break;
        case "ArrowLeft":
        case "ArrowRight":
          updateJoystickPosition(null, 0);
          break;
        default:
          break;
      }
    });

    // Set dual monitor mode if query parameter is present
    if (dualMonitorMode || searchParams.get("dualMonitor") === "true") {
      cardChannel.postMessage({message: "map-init"});
      setDualMonitorMode(true);
    }

    // Set minimap bounds from field/region info
    if (sharedProps.sharedFilter.field.FieldContractId !== 0 && sharedProps.sharedFilter.region.RegionId !== 0) {
      // get api data of targeted region
      fetchMiniMapBounds(sharedProps.sharedFilter.field.FieldContractId, sharedProps.sharedFilter.region.RegionId);
    }

    //TODO: As aprt of ROS-354 move listeners to separate module so detail/overall don't have to re-establish each time
    cleanRealtimeListeners();
  }, []);

  // Handle device info updates
  useEffect(() => {
    deviceInfoRef.current = sharedProps.deviceInfo;
    initializeRealtimeLocationChannels();
    initializeRealtimeEventArrayChannel();
    // Update map locations when device info changes
    updateMapLocations();
    // Update Job Status
    updateJob();
    updateJobCompletionPercentage();
    drawAlleyPaths();
    const targetDeviceInfo = sharedProps.deviceInfo.find((device) => device.device.id === searchParams.get("deviceId"));
    if (targetDeviceInfo) {
      setDeviceInfo(targetDeviceInfo);
      // Render bot path
      if (targetDeviceInfo.path !== "" && map.current && !realtimePathFetched) {
        displayPath(targetDeviceInfo.path);
      }
    }
  }, [sharedProps.deviceInfoFetched]);

  // Initialize map when we load the page or change the focus view
  useEffect(() => {
    if(map.current) {
      map.current.remove();
    }
    if (mapContainer.current && (!dualMonitorMode || initialized)) {
      initializeMap();
      if (sharedProps.deviceInfo.length > 0) {
        sharedProps.deviceInfo.map((info) => {
          const targetMarker = markers.find((marker) => marker.name === info.device.name);
          if (targetMarker) {
            targetMarker.marker.addTo(map.current);
          }
        });
      }
      adjustBotMarkerZoom(defaultZoom);
    }
    // The "init" states work as a buffer for the realtime video player element to load the proper feed
    // into focus view. This prevents the video from not changing when swapping cameras in focus view.
    if (focusState === "front-init") {
      setFocusState("front");
    } else if (focusState === "back-init") {
      setFocusState("back");
    }
    // Store focus state in ref for gps listener
    focusStateRef.current = focusState;

  }, [focusState, mapContainer.current, initialized]);

  // Initialize mini map when we load the page or change the focus view
  useEffect(() => {
    if(miniMap.current) {
      miniMap.current.remove();
    }
    if (miniMapContainer.current && (!dualMonitorMode || initialized) && focusState === "map") {
      initializeMiniMap();
    }
  }, [focusState, miniMapContainer.current, miniMapBounds]);

  useEffect(() => {
    if (sharedProps.currentJob.length > 0) {
      updateJobCompletionPercentage();
    }
  }, [completedPaths]);

  useEffect(() => {
    if (botPathPoints.length > 0 && map.current?.getSource('active-bot-path-lines')) {
      // Set data for drawn bot path
      (map.current?.getSource('active-bot-path-lines') as GeoJSONSource).setData({
        type: "FeatureCollection",
        features: [{
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: botPathPoints
          }
        }]
      });
    }
    const coordinates = {longitude: longitude, latitude: latitude};
    if (drawPath) {
      botPathInterval = !botPathInterval && setInterval(() => {
        if (botPathPoints.length === 0) {
          setBotPathPoints([[coordinates.longitude, coordinates.latitude]]);
        } else {
          setBotPathPoints(botPathPoints => botPathPoints.concat([
            [coordinates.longitude, coordinates.latitude]
          ]));
        }
      }, botPathDrawTime);
    } else if (botPathInterval) {
      clearInterval(botPathInterval);
    }
    return () => clearInterval(botPathInterval);
  }, [drawPath, botPathPoints])

  // Handle device state change
  useEffect(() => {
    if (pendingStateChange) {
      let pendingStateChangeTimeoutSeconds = pendingStateChangeTimeout;
      // Check if state has been updated every second for 15 seconds
      const pendingStateChangesInterval = setInterval(() => {
        if (pendingStateChangeTimeoutSeconds > 0) {
          // End interval once bot state has changed
          if (deviceInfo.botState === targetBotState) {
            pendingStateChangeTimeoutSeconds = 0;
          }
          pendingStateChangeTimeoutSeconds -= 1;
        }
        if (pendingStateChangeTimeoutSeconds <= 0) {
          // Warn user if state failed to update
          if (deviceInfo.botState !== targetBotState) {
            console.warn("Device " + deviceInfo.device.name + " failed to update state");
          }
          setPendingStateChange(false);
          setTargetBotState(null);
          clearInterval(pendingStateChangesInterval);
        }
      }, 1000);
    }
  }, [pendingStateChange])

  // Handle bot state functions
  useEffect(() => {
    const botState = deviceInfo.botState;
    const currentUser = Authentication.getCurrentUser();
    if (botState !== BotState.TELEOP) {
      teleopEnableRef.current = false;
    }
    // Only perform state action if user owns bot
    if (deviceInfo.botOwner === currentUser.firstName + " " + currentUser.lastName) {
      switch (botState) {
        case BotState.TELEOP:
          teleopEnableRef.current = true;
          handleTeleopControl();
          break;
        case BotState.RUNNING:
          startRMF();
          break;
      }
    }
  }, [deviceInfo.botState])

  // query for and set a state to save the current device's formant agent version
  // this is done here (rather than deviceFetch) to reduce the frequency of the API call
  useEffect(() => {
    getFormantAgentVersion(deviceInfo.id);
  }, [])

  const getFormantAgentVersion = async(deviceId) => {
    const options = {
      method: 'GET',
      headers: {authorization: 'Bearer ' + Authentication.token}
    };
    const deviceFromApi = await fetch("https://api.formant.io/v1/admin/devices/" + deviceId, options);
    const deviceJson = await deviceFromApi.json();
    setFormantAgentVersion(deviceJson.state.agentVersion || UNKNOWN_SOFTWARE_VERSION);
  }

  const generateMapIcons = () => {
    // Generate icon for alley path labels
    const bytePerPixel = 4

    if (!map.current?.hasImage('alley_path_label_white')) {
      const whiteLabelData = new Uint8Array(alleyLabelWidth * alleyLabelHeight * bytePerPixel);
      for (let i = 0; i < alleyLabelWidth * alleyLabelHeight; i++) {
        const offset = i * bytePerPixel;

        whiteLabelData[offset + 0] = 255; // red
        whiteLabelData[offset + 1] = 255; // green
        whiteLabelData[offset + 2] = 255; // blue
        whiteLabelData[offset + 3] = 255; // alpha
      }
      map.current?.addImage('alley_path_label_white', { width: alleyLabelWidth, height: alleyLabelHeight, data: whiteLabelData});
    }
    if (!map.current?.hasImage('alley_path_label_green')) {
      const greenLabelData = new Uint8Array(alleyLabelWidth * alleyLabelHeight * bytePerPixel);
      for (let i = 0; i < alleyLabelWidth * alleyLabelHeight; i++) {
        const offset = i * bytePerPixel;
        
        greenLabelData[offset + 0] = 0; // red
        greenLabelData[offset + 1] = 255; // green
        greenLabelData[offset + 2] = 0; // blue
        greenLabelData[offset + 3] = 255; // alpha
      }
      map.current?.addImage('alley_path_label_green', { width: alleyLabelWidth, height: alleyLabelHeight, data: greenLabelData});
    }
  }

  const updateJob = async() => {
    if (sharedProps.sharedFilter.field.FieldContractId !== 0) {
      const fetchedJob = await APIActions.getJobsByFieldContract(sharedProps.sharedFilter.field.FieldContractId);
      sharedProps.setCurrentJob(fetchedJob);
    } else {
      sharedProps.setCurrentJob([]);
    }
  }

  const updateJobCompletionPercentage = async() => {
    try {
      if (sharedProps.sharedFilter.field.FieldContractId !== 0) {
        const completionPercentage = await APIActions.getJobCompletionPercentage(sharedProps.sharedFilter.field.FieldContractId);
        setJobCompletionPercentage(completionPercentage);
      } else {
        setJobCompletionPercentage(null);
      }
    } catch(e) {
      setJobCompletionPercentage(null);
    }
  }

  const updateCompletedAlleyPaths = async() => {
    let jobPaths = [];
    if (sharedProps.currentJob.length > 0 && sharedProps.sharedFilter.field.FieldContractId !== 0) {
      try {
        jobPaths = await APIActions.getCompletedPolyPathIds(sharedProps.sharedFilter.field.FieldContractId);
      } catch (e) {
        console.warn(e);
      }
    }
    setCompletedPaths(jobPaths);
    return jobPaths;
  }

  /**
   * Initialize region perimeter geojson data
   * 
   * @async
   * @param {number} fieldContractId - field contract id
   * @param {number} regionId - region id
   */
  const initRegionPerimeter = async (fieldContractId: number, regionId: number) => {
    perimeterDataRef.current = await APIActions.getPerimeter(fieldContractId, regionId);
  };

  /**
   * Create map for detailed view
   */
  const initializeMap = () => {
    if (sharedProps.mapStyle === "") {
      sharedProps.setMapStyle(defaultMapStyle);
    }
    map.current = new Map({
      container: mapContainer.current,
      attributionControl: false,
      style: sharedProps.mapStyle,
      center: [longitude, latitude],
      zoom: defaultZoom,
      maxZoom: maxZoom,
      interactive: false
    });
    map.current?.on('load', function () {
      createSourcesAndLayers();
      if (sharedProps.sharedFilter.field.FieldContractId !== 0 && sharedProps.sharedFilter.region.RegionId !== 0) {
        updateRegionSource(sharedProps.sharedFilter.field.FieldContractId, sharedProps.sharedFilter.region);
      }
      map.current?.on('zoom', () => {
        adjustBotMarkerZoom();
      });
    });
  }

  /**
   * Create mini map for detailed view
   */
  const initializeMiniMap = () => {
    if (sharedProps.sharedFilter.region.RegionId !== 0) {
      miniMap.current = new Map({
        container: miniMapContainer.current,
        attributionControl: false,
        style: sharedProps.mapStyle,
        bounds: miniMapBounds,
        fitBoundsOptions: {
          padding: 10
        },
        interactive: false
      });
    } else {
      miniMap.current = new Map({
        container: miniMapContainer.current,
        attributionControl: false,
        style: defaultMapStyle,
        center: [defaultLongitude, defaultLatitude],
        zoom: defaultMiniMapZoom,
        interactive: false
      })
    }
    miniMap.current?.on('load', function () {
      if (sharedProps.sharedFilter.field.FieldContractId !== 0 && sharedProps.sharedFilter.region.RegionId !== 0) {
        createMiniMapSourcesAndLayers(sharedProps.sharedFilter.field.FieldContractId, sharedProps.sharedFilter.region.RegionId);
      }
      drawMiniMapBotMarker();
    });
  }

  /**
   * Create realtime listeners for fetching gps and odom data from the agent
   * 
   * @async
   * @param {number} fieldContractId - field contract id
   * @param {number} regionId - region id
   */
  const fetchMiniMapBounds = async(fieldContractId: number, regionId: number) => {
    if (perimeterDataRef.current.type === "") {
      await initRegionPerimeter(fieldContractId, regionId);
    }
    const perimeter_coordinates = perimeterDataRef.current.coordinates as Position[];
    const bounds = new mapboxgl.LngLatBounds(
      perimeter_coordinates[0] as mapboxgl.LngLatLike,
      perimeter_coordinates[0] as mapboxgl.LngLatLike
    );
    perimeter_coordinates.forEach((point) => {
      bounds.extend(point as mapboxgl.LngLatLike);
    });
    setMiniMapBounds(bounds);
  }

  /**
   * Create sources and layers for minimap display and render fence for selected field/region
   * 
   * @async
   * @param {number} fieldContractId - field contract id
   * @param {number} regionId - region id
   */
  const createMiniMapSourcesAndLayers = async(fieldContractId: number, regionId: number) => {
    // get api data of targeted region
    if (perimeterDataRef.current.type === "") {
      await initRegionPerimeter(fieldContractId, regionId);
    }
    const perimeter_type = perimeterDataRef.current.type as "LineString";
    const perimeter_coordinates = perimeterDataRef.current.coordinates as Position[];

    // geojson source for fetched perimeter coordinate data
    if (!miniMap.current?.getSource('perimeter-lines')) {
      miniMap.current?.addSource('perimeter-lines', {
        type: 'geojson',
        data: {
          type: "FeatureCollection",
          features: [{
            type: "Feature",
            properties: {},
            geometry: {
              type: perimeter_type,
              coordinates: perimeter_coordinates
            }
          }]
        }
      });
    }
    // render geojson data for perimeters as pink lines
    if (!miniMap.current?.getLayer('perimeter-lines-layer')) {
      miniMap.current?.addLayer({
        'id': 'perimeter-lines-layer',
        'type': 'line',
        'source': 'perimeter-lines',
        'paint': {
          'line-color': '#ffba42',
          'line-width': 2,
        }
      });
    }
  }

  /**
   * Draw mini map bot marker on mini map to show bot's location
   */
  const drawMiniMapBotMarker = () => {
    // Create source and layer for bot's minimap location
    if (!miniMap.current?.getSource('mini-map-location-point')) {
      miniMap.current?.addSource('mini-map-location-point', {
        type: 'geojson',
        data: {
          type: 'Point',
          coordinates: [longitude, latitude]
        }
      });
    }

    if (!miniMap.current?.getLayer('mini-map-location-point-layer1')) {
      miniMap.current?.addLayer({
        'id': 'mini-map-location-point-layer1',
        'type': 'circle',
        'source': 'mini-map-location-point',
        'paint': {
          'circle-radius': 6,
          'circle-color': '#1b2c50',
        }
      });
    }
    if (!miniMap.current?.getLayer('mini-map-location-point-layer0')) {
      miniMap.current?.addLayer({
        'id': 'mini-map-location-point-layer0',
        'type': 'circle',
        'source': 'mini-map-location-point',
        'paint': {
          'circle-radius': 4,
          'circle-color': 'white',
        }
      });
    }
  };

  const drawAlleyPaths = async () => {
    try {
      const fieldContractId = sharedProps.sharedFilter.field.FieldContractId;
      const region = sharedProps.sharedFilter.region;
      
      if (fieldContractId !== 0 && region.RegionId !== 0) {
        // get api data of targeted region alley paths
        const alleyPathData = await APIActions.getAlleyPaths(fieldContractId, region.RegionId);
        const alleyPathCoordinates = alleyPathData.coordinates as Position[][];
        const alleyPathNumbers = await APIActions.getAlleyPathNumbers(fieldContractId, region.RegionId);
        const alleyPathNumbersForRegion = alleyPathNumbers[region.Name];
        const alleyPathsCompleted = await updateCompletedAlleyPaths();

        // Set data for alley paths
        const alleyPathFeatures = []
        alleyPathCoordinates.forEach((coords, index) => {
          const alleyPathNumber: string = alleyPathNumbersForRegion ? alleyPathNumbersForRegion[index].RowNumber : index + 1;
          const description = 'Alley ' + alleyPathNumber;
          const isCompleted = alleyPathNumbersForRegion && alleyPathsCompleted.find((path) => path.polyPathId === alleyPathNumbersForRegion[index].PolyPathId) ? true : false;
          alleyPathFeatures.push({
            'type': 'Feature',
            'properties': {
              'description': description,
              'completed': isCompleted
            },
            'geometry': {
              'type': 'LineString',
              'coordinates': coords
            }
          })
        });
        (map.current?.getSource('alley-path-lines') as GeoJSONSource).setData({
          type: "FeatureCollection",
          features: alleyPathFeatures
        });
      }
    } catch (e){
      console.warn(e);
    }
  }

  const cleanRealtimeListeners = () => {
    sharedProps.deviceInfo.forEach((info) => {
      if (info.realtimeGpsChannel && info.realtimeGpsChannel.listeners.length > 0) {
        for (let i = 0; i < info.realtimeGpsChannel.listeners.length; i++) {
          info.realtimeGpsChannel.removeListener(info.realtimeGpsChannel.listeners[i]);
        }
      }
      if (info.realtimeOdomChannel && info.realtimeOdomChannel.listeners.length > 0) {
        for (let i = 0; i < info.realtimeOdomChannel.listeners.length; i++) {
          info.realtimeOdomChannel.removeListener(info.realtimeOdomChannel.listeners[i]);
        }
      }
      if (info.realtimePathChannel && info.realtimePathChannel.listeners.length > 0) {
        for (let i = 0; i < info.realtimePathChannel.listeners.length; i++) {
          info.realtimePathChannel.removeListener(info.realtimePathChannel.listeners[i]);
        }
      }
      if (info.realtimeEventArrayChannel && info.realtimeEventArrayChannel.listeners.length > 0) {
        for (let i = 0; i < info.realtimeEventArrayChannel.listeners.length; i++) {
          info.realtimeEventArrayChannel.removeListener(info.realtimeEventArrayChannel.listeners[i]);
        }
      }
    });
  }

  const initializeRealtimeLocationChannels = () => {
    sharedProps.deviceInfo.forEach((info) => {
      if(info.realtimeGpsChannel){
        if (info.realtimeGpsChannel.listeners.length <= 0) {
          info.realtimeGpsChannel.addListener((message) => {
            const data = JSON.parse(message);
            updateBotPosition(info, data.longitude, data.latitude);
          });
        } 
      }
      if (info.realtimeOdomChannel) {
        if (info.realtimeOdomChannel.listeners.length <= 0) {
          info.realtimeOdomChannel.addListener((message) => {
            const data = JSON.parse(JSON.parse(message));
            updateYaw(info, data.twist.twist.angular.z);
          });
        }
      }
      if (info.realtimePathChannel && info.realtimePathChannel.listeners.length <= 0) {
        info.realtimePathChannel.addListener((message) => {
          const data = JSON.parse(message);
          displayPath(data);
          setBotPathPoints([]);
          setDrawPath(true);
          updateJobCompletionPercentage();
          drawAlleyPaths();
          setRealtimePathFetched(true);
        });
      }
    });
  }

  const initializeRealtimeEventArrayChannel = () => {
    sharedProps.deviceInfo.forEach((info) => {
      if (info.realtimeEventArrayChannel) {
        if (info.realtimeEventArrayChannel.listeners.length <= 0) {
          info.realtimeEventArrayChannel.addListener((message) => {
            const data = JSON.parse(message);
            const eventRobotNumber = data.header.robot_id.slice(5);
            if (eventRobotNumber === info.robotNumber) {
              const currentTimestamp = new Date(Date.now());
              updateBotEventsWithRealtimeData(info, data, currentTimestamp);
            }
          });
        }
      }
    });
  }

  const updateBotEventsWithRealtimeData = (info, jsonData, timestamp) => {
    const events = [];
    jsonData.events.forEach((event) => {
      const newEvent: BotEvent = {
        stamp: event.stamp,
        timestamp: new Date(event.stamp.sec * 1000 + floor(event.stamp.nanosec / 1000000)),
        pathId: event.path_id,
        name: event.event_name,
        severity: event.severity,
        description: event.description,
        data: event.event_data,
      };
      // info-level events should not be visible in rosie
      if (newEvent.severity !== SeverityNames.info) {
        events.push(newEvent);
      }
    });
    const newDeviceInfoList = sharedProps.deviceInfo.map((i) => {
      if (i.id !== info.id) {
        return i;
      } else {
        return {
          ...i,
          events: events,
          telemetryTimestamps: {
            ...i.telemetryTimestamps,
            eventArray: timestamp,
          }
        }
      }
    });
    sharedProps.setDeviceInfo(newDeviceInfoList);
  }

  /**
   * Update bot's position by fetching recent gps telemetry data
   * 
   * @async
   */
  const updateBotPosition = (info: DeviceInfo, longitude: number, latitude: number) => {
    const targetMarker = markers.find((marker) => marker.name === info.device.name);
    if (targetMarker) {
      targetMarker.marker.setLngLat([longitude, latitude]);
      if (targetMarker.name === deviceInfo.device.name) {
        map.current.setCenter([longitude, latitude]);
        if (focusStateRef.current === "map" && miniMap.current.getSource('mini-map-location-point')) {
          (miniMap.current.getSource('mini-map-location-point') as GeoJSONSource).setData({
            type: 'FeatureCollection',
            features: [{
              type: "Feature",
              properties: {},
              geometry: {
                type: 'Point',
                coordinates: [longitude, latitude]
              }
            }]
          })
          if (miniMap.current && sharedProps.sharedFilter.region.RegionId !== 0) {
            miniMap.current.setCenter([longitude, latitude]);
          }
        }
        setLongitude(longitude);
        setLatitude(latitude);
      }
    }
  };

  /**
   * Fetch the size of the focused view container
   * 
   * @return {[number, number]} - array of numbers containing width and height of container
   */
  const getVideoContainerSize = () => {
    const element = document.getElementById("focus-view-container");
    const width = element.clientWidth + focusedVideoWidthOffset;
    const height = width * focusedVideoHeightRatio / focusedVideoWidthRatio;
    return [width, height];
  };

  /**
   * Fetch and display current bot path
   * 
   * @param {string} data - bot path data
   */
  const displayPath = (data: string) => {
    const jsonData = JSON.parse(data);
    const coordinates = [];
    if (jsonData.path) {
      const points = jsonData.path;
      points.forEach((point) => {
        coordinates.push([point.location.lon_deg, point.location.lat_deg]);
      });

      const path_type = "LineString";
      const path_coordinates = coordinates as Position[];

      // assign geojson data to applicable source
      if (map.current?.getSource('bot-path-lines')) {
        (map.current?.getSource('bot-path-lines') as GeoJSONSource).setData({
          type: "FeatureCollection",
          features: [{
            type: "Feature",
            properties: {},
            geometry: {
              type: path_type,
              coordinates: path_coordinates
            }
          }]
        });
      }
    }
  }

  /**
   * Change color of battery node depending on threshold to visually indicate battery level
   * 
   * @param {number} threshold - Value at which charge must be greater than to be green
   * @return {JSX.Element} - Battery charge node element 
   */
  const displayBatteryCharge = (threshold: number) => {
    let batteryChargeNodeElement = <button className="BatteryChargeNodeOff"></button>;
    if(deviceInfo && deviceInfo.battery > threshold) {
      batteryChargeNodeElement = <button className="BatteryChargeNodeOn"></button>;
    }
    return batteryChargeNodeElement;
  }

  /**
   * Set the display name depending on if robot number is present or not
   * 
   * @return {JSX.Element} - Typography with display name as text 
   */
  const getRobotDisplayName = () => {
    let robotNumberDisplay = "Loading..." 
    if (deviceInfo) {
      if(deviceInfo.robotNumber !== 0) {
        robotNumberDisplay = "Robot " + deviceInfo.robotNumber;
      } else {
        robotNumberDisplay = "Robot -- (" + deviceInfo.device.name + ")";
      }
    }
    return robotNumberDisplay;
  };

  /**
   * Fetch the odometry information and convert to degree value to rotate bot on map to
   * 
   * @async
   * @param {Marker} marker - device marker on map
   * @param {number | undefined} angle - radian angle value of device
   * @param {string | undefined} url - url string for odometry json
   */
   const updateYaw = async(info: DeviceInfo, angle?: number, url?: string) => {
    let newAngle = angle ? angle : 0; 
    const marker = markers.find((marker) => marker.name === info.device.name);
    if (marker) {
      if (url) {
        const res = await fetch(url);
        const json = await res.json();
        newAngle = json.twist.twist.angular.z;
      }
      const markerAngle = ((360 - (newAngle * (180/Math.PI)))) % 360;
      marker.marker.setRotation(markerAngle);
    }
  }

  /**
   * Handle render of front view slot in detailed view
   * 
   * @return {JSX.Element} rendered element 
   */
  const frontViewSlot = (): JSX.Element => {
    let element = (
      <div onClick={() => setFocusState("front-init")}>
        <Typography className="ScreenTitle" variant="h6" sx={{ color: "grey", fontSize: 10 }}>Front View</Typography>
        <div className="Screen"> {!deviceInfo?.isOnline ? "offline" : <RealtimePlayer deviceInfo={deviceInfo} width={315} height={177} videoIndex={0}/>}</div>
      </div>
    );
    if (focusState === "front" || focusState === "front-init") {
      element = (
        <div>
          <Typography className="ScreenTitle" variant="h6" sx={{ color: "grey", fontSize: 10 }}>Map View</Typography>
          <div onClick={() => setFocusState("map")} ref={mapContainer} className="SmallMapContainer" onWheel={(event) => zoomMap(event)}></div>
        </div>
      );
    }
    return element;
  }

  /**
   * Handle render of back view slot in detailed view
   * 
   * @return {JSX.Element} rendered element 
   */
  const backViewSlot = (): JSX.Element => {
    let element = (
      <div onClick={() => setFocusState("back-init")}>
        <Typography className="ScreenTitle" variant="h6" sx={{ color: "grey", fontSize: 10 }}>Back View</Typography>
        <div className="Screen"> {!deviceInfo?.isOnline ? "offline" : <RealtimePlayer deviceInfo={deviceInfo} width={315} height={177} videoIndex={1}/>}</div>
      </div>
    );
    if (focusState === "back" || focusState === "back-init") {
      element = (
        <div>
          <Typography className="ScreenTitle" variant="h6" sx={{ color: "grey", fontSize: 10 }}>Map View</Typography>
          <div onClick={() => setFocusState("map")} ref={mapContainer} className="SmallMapContainer" onWheel={(event) => zoomMap(event)}></div>
        </div>
      );
    }
    return element;
  }

  /**
   * Handle scroll event over map and control 
   * 
   * @return {JSX.Element} rendered element 
   */
  const zoomMap = (event: React.WheelEvent<HTMLDivElement>) => {
    if (map.current) {
      const zoomFactor = zoomSpeed * Math.sign(event.deltaY);
      const newZoom = map.current.getZoom() - zoomFactor;
      map.current.setZoom(newZoom);
    }
  };

  /**
   * Handle render of focused view slot in detailed view
   * 
   * @return {JSX.Element} rendered element 
   */
  const focusViewSlot = (): JSX.Element => {
    let element = (
      <div className="FocusedViewContainer" id="focus-view-container">
        <Typography className="ScreenTitle" variant="h6" sx={{ color: "grey", fontSize: 10 }}>Map View</Typography>
        <div className="MapFocusViewContainer">
          <div ref={mapContainer} className="MapContainer" onWheel={(event) => zoomMap(event)}></div>
          <div className="MiniMap">
            <div ref={miniMapContainer} className="MiniMapMapContainer"></div>
          </div>
        </div>
      </div>
    );
    switch (focusState) {
      case "front":
        element = (
          <div className="FocusedViewContainer" id="focus-view-container">
            <Typography className="ScreenTitle" variant="h6" sx={{ color: "grey", fontSize: 10 }}>Front View</Typography>
            <div className="FocusedScreen"> {!deviceInfo?.isOnline ? "offline" : <RealtimePlayer deviceInfo={deviceInfo} width={videoFocusDimensions.current[0]} height={videoFocusDimensions.current[1]} videoIndex={0}/>}</div>
          </div>
        );
        break;
      case "back":
        element = (
          <div className="FocusedViewContainer" id="focus-view-container">
            <Typography className="ScreenTitle" variant="h6" sx={{ color: "grey", fontSize: 10 }}>Back View</Typography>
            <div className="FocusedScreen"> {!deviceInfo?.isOnline ? "offline" : <RealtimePlayer deviceInfo={deviceInfo} width={videoFocusDimensions.current[0]} height={videoFocusDimensions.current[1]} videoIndex={1}/>}</div>
          </div>
        );
        break;
      case "front-init":
        element = (
          <div className="FocusedViewContainer" id="focus-view-container"></div>
        );
        break;
      case "back-init":
        element = (
          <div className="FocusedViewContainer" id="focus-view-container"></div>
        );
        break;
    }
    return element;
  }

  const handleTeleopControl = () => {
    // Collect joystick input and send through custom data channel to formant agent
    try {
      setBotPathPoints([]);
      setDrawPath(false);
      if (deviceInfo && deviceInfo.device.rtcClient && !joystickListenerExists) {
        console.log("Initializing teleop");
        setJoystickListenerExists(true);
        document.addEventListener("joystick", (element: CustomEvent) => {
          if (teleopEnableRef.current) {
            const data = {
              header: {
                stream: {
                  entityId: deviceInfo.id, 
                  streamName: "Joystick", 
                  streamType: "twist"
                }, 
                created: Date.now()
              }, 
              payload: {
                twist: {
                  linear: {
                    x: element.detail.x, 
                    y: 0, 
                    z: 0
                  }, 
                  angular: {
                    x: 0, 
                    y: 0, 
                    z: element.detail.z
                  }
                }
              }
            };
            deviceInfo.device.sendRealtimeMessage(data as IRtcStreamMessage);
            console.log("Sending joystick teleop data:");
            console.log(data);
          }
        });
      }
    } catch (e) {
      console.warn("DetailedRobot: ", e);
    }
  }

  const startRMF = async() => {
    try {
      if (deviceInfo.realtimeRmfChannel) {
        const currentFieldContractId = sharedProps.sharedFilter.field.FieldContractId;
        const currentJob = await APIActions.getJobsByFieldContract(currentFieldContractId);
        const currentJobId = currentJob[0].jobsId;
        const res = await APIActions.postRmf(currentFieldContractId, currentJobId);
        console.log(res);
      }
    } catch (e) {
      console.warn(deviceInfo.device.name + " rmfChannel Error: " + e);
    }
  }

  /**
   * Create sources and layers for rendering geoJson data
   */
  //TODO: Breakup into smaller functions and move to separate module so can be used
  //in overall and detail view
   const createSourcesAndLayers = () => {
    // geojson source for fetched waypoint coordinate data
    if (!map.current?.getSource('alley-path-lines')) {
      map.current?.addSource('alley-path-lines', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': []
        }
      });
    }
    if (!map.current?.getLayer('alley-path-lines-layer')) {
      map.current?.addLayer({
        'id': 'alley-path-lines-layer',
        'type': 'line',
        'source': 'alley-path-lines',
        'paint': {
          'line-color': [
            'case', 
            ['==', ['get', 'completed'], true],
            '#00ff00',
            'white'
          ],
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            20, ['number', 1],
            24, ['number', 10]
          ],
        }
      });
    }
    if (!map.current?.getLayer('alley-path-text-layer')) {
      map.current?.addLayer({
        'id': 'alley-path-text-layer',
        'type': 'symbol',
        'source': 'alley-path-lines',
        'minzoom': alleyTextRenderThreshold,
        'layout': {
          'icon-image': [
            'case',
            ['==', ['get', 'completed'], true],
            'alley_path_label_green',
            'alley_path_label_white'
          ],
          'icon-allow-overlap': true,
          'symbol-placement': 'line',
          'symbol-spacing': [
            'interpolate',
            ['linear'],
            ['zoom'],
            20, ['number', 400],
            24, ['number', 200]
          ],
          'text-field': ['get', 'description'],
          'text-size': 16
        }
      });
    }

    // geojson source for fetched crop row lines
    if (!map.current?.getSource('crop-row-lines')) {
      map.current?.addSource('crop-row-lines', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': []
        }
      });
    }
    if (!map.current?.getLayer('crop-row-lines-layer')) {
      map.current?.addLayer({
        'id': 'crop-row-lines-layer',
        'type': 'line',
        'source': 'crop-row-lines',
        'paint': {
          'line-color': '#e200ff',
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            20, ['number', 1],
            24, ['number', 7]
          ],
        }
      });
    }

    // geojson source for fetched perimeter coordinate data
    if (!map.current?.getSource('perimeter-lines')) {
      map.current?.addSource('perimeter-lines', {
        type: 'geojson',
        data: {
          type: "LineString",
          coordinates: []
        }
      });
    }
    if (!map.current?.getLayer('perimeter-lines-layer')) {
      map.current?.addLayer({
        'id': 'perimeter-lines-layer',
        'type': 'line',
        'source': 'perimeter-lines',
        'paint': {
          'line-color': '#ffba42',
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            20, ['number', 3],
            24, ['number', 10]
          ],
          'line-dasharray': [2, 1]
        }
      });
    }

    //geojson source for fetched detail pins data
    if (!map.current?.getSource('detail-pins')) {
      map.current?.addSource('detail-pins', {
        type: 'geojson',
        data: {
          type: "LineString",
          coordinates: []
        }
      });
    }
    if (!map.current?.getLayer('detail-pins-layer')) {
      map.current?.addLayer({
        'id': 'detail-pins-layer',
        'type': 'circle',
        'source': 'detail-pins',
        'paint': {
          'circle-color': 'red',
          'circle-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            20, ['number', 5],
            24, ['number', 20]
          ]
        }
      })
    }

    //geojson source for bots' home path
    if (!map.current?.getSource('home-lines')) {
      map.current?.addSource('home-lines', {
        type: 'geojson',
        data: {
          type: 'LineString',
          coordinates: []
        }
      })
    }
    if (!map.current?.getLayer('home-lines-layer')) {
      map.current?.addLayer({
        'id': 'home-lines-layer',
        'type': 'line',
        'source': 'home-lines',
        'paint': {
          'line-color': 'yellow',
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            20, ['number', 1],
            24, ['number', 10]
          ],
        }
      })
    }

    //geojson source for manual zones
    if (!map.current?.getSource('manual-zone-lines')) {
      map.current?.addSource('manual-zone-lines', {
        type: 'geojson',
        data: {
          type: 'LineString',
          coordinates: []
        }
      })
    }
    if (!map.current?.getLayer('manual-zone-lines-layer')) {
      map.current?.addLayer({
        'id': 'manual-zone-lines-layer',
        'type': 'line',
        'source': 'manual-zone-lines',
        'paint': {
          'line-color': '#2d7cc3',
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            20, ['number', 1],
            24, ['number', 7]
          ],
        }
      })
    }
    if (!map.current?.getLayer('manual-zone-fill-layer')) {
      map.current?.addLayer({
        'id': 'manual-zone-fill-layer',
        'type': 'fill',
        'source': 'manual-zone-lines',
        'paint': {
          'fill-color': '#2d7cc3',
          'fill-opacity': 0.5,
        }
      })
    }

    // geojson data for bot path
    if (!map.current?.getSource('bot-path-lines')) {
      map.current?.addSource('bot-path-lines', {
        type: 'geojson',
        data: {
          type: 'LineString',
          coordinates: []
        }
      });
    }
    if (!map.current?.getLayer('bot-path-lines-layer')) {
      map.current?.addLayer({
        'id': 'bot-path-lines-layer',
        'type': 'line',
        'source': 'bot-path-lines',
        'paint': {
          'line-color': '#00b1b5',
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            20, ['number', 1],
            24, ['number', 10]
          ],
        }
      })
    }

    //geojson source for on ramps
    if (!map.current?.getSource('on-ramp-lines')) {
      map.current?.addSource('on-ramp-lines', {
        type: 'geojson',
        data: {
          type: 'LineString',
          coordinates: []
        }
      })
    }
    if (!map.current?.getLayer('on-ramp-lines-layer')) {
      map.current?.addLayer({
        'id': 'on-ramp-lines-layer',
        'type': 'line',
        'source': 'on-ramp-lines',
        'paint': {
          'line-color': '#000000',
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            20, ['number', 1],
            24, ['number', 7]
          ],
        }
      })
    }

    //geojson source for active bot path
    if (!map.current?.getSource('active-bot-path-lines')) {
      map.current?.addSource('active-bot-path-lines', {
        type: 'geojson',
        data: {
          type: 'LineString',
          coordinates: []
        }
      })
    }
    if (!map.current?.getLayer('active-bot-path-lines-layer')) {
      map.current?.addLayer({
        'id': 'active-bot-path-lines-layer',
        'type': 'line',
        'source': 'active-bot-path-lines',
        'paint': {
          'line-color': '#ffac00',
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            20, ['number', 2],
            24, ['number', 11]
          ],
        }
      })
    }

    //geojson source for highways
    if (!map.current?.getSource('highway-lines')) {
      map.current?.addSource('highway-lines', {
        type: 'geojson',
        data: {
          type: 'MultiLineString',
          coordinates: []
        }
      })
    }
    if (!map.current?.getLayer('highway-lines-layer')) {
      map.current?.addLayer({
        'id': 'highway-lines-layer',
        'type': 'line',
        'source': 'highway-lines',
        'paint': {
          'line-color': '#ff0000',
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            20, ['number', 1],
            24, ['number', 7]
          ],
          'line-dasharray': [2, 1],
        }
      })
    }

    // Order layers
    map.current?.moveLayer('manual-zone-fill-layer');
    map.current?.moveLayer('manual-zone-lines-layer');
    map.current?.moveLayer('bot-path-lines-layer');
    map.current?.moveLayer('detail-pins-layer');
    map.current?.moveLayer('active-bot-path-lines-layer');
    map.current?.moveLayer('alley-path-text-layer');
  }

  /**
   * Fetch geojson data for specified region in specified field
   * 
   * @async
   * @param {number} fieldContractId - field name
   * @param {Region} region - region
   */
   const updateRegionSource = async(fieldContractId: number, region: Region) => {
    // Generate icon for alley path labels
    generateMapIcons();

    // Draw alley paths
    drawAlleyPaths();

    try {
      // get api data of targeted region crop rows
      const cropRowData = await APIActions.getCropRows(fieldContractId, region.RegionId);
      const cropRowCoordinates = cropRowData.coordinates as Position[][];

      // Set data for crop rows
      const cropRowFeatures = [];
      cropRowCoordinates.forEach((coords) => {
        cropRowFeatures.push({
          'type': 'Feature',
          'properties': {},
          'geometry': {
            'type': 'LineString',
            'coordinates': coords
          }
        })
      });
      (map.current?.getSource('crop-row-lines') as GeoJSONSource).setData({
        type: "FeatureCollection",
        features: cropRowFeatures
      });
    } catch (e) {
      console.warn(e);
    }

    try {
      // get api data of targeted region
      if (perimeterDataRef.current.type === "") {
        await initRegionPerimeter(fieldContractId, region.RegionId);
      }
      const perimeter_type = perimeterDataRef.current.type as "LineString";
      const perimeter_coordinates = perimeterDataRef.current.coordinates as Position[];

      // Set data to perimeter lines
      (map.current?.getSource('perimeter-lines') as GeoJSONSource).setData({
        type: "FeatureCollection",
        features: [{
          type: "Feature",
          properties: {},
          geometry: {
            type: perimeter_type,
            coordinates: perimeter_coordinates
          }
        }]
      });
    } catch (e) {
      console.warn(e);
    }

    try {
      // get api data for targeted region's detail pins
      const pin_data = await APIActions.getDetailPins(fieldContractId, region.RegionId);

      // Set data for detail pins
      (map.current?.getSource('detail-pins') as GeoJSONSource).setData(pin_data);
      // Handle detail pin popups
      map.current.on('mouseenter', 'detail-pins-layer', (e) => {
        map.current.getCanvas().style.cursor = 'pointer';
        const coordinates = e.lngLat;
        const description = e.features[0].properties.Name;
        popup.current.setLngLat([coordinates.lng, coordinates.lat])
          .setHTML(description)
          .addTo(map.current)
      });
      map.current.on('mousemove', 'detail-pins-layer', (e) => {
        const coordinates = e.lngLat;
        popup.current.setLngLat([coordinates.lng, coordinates.lat]);
      });
      map.current.on('mouseleave', 'detail-pins-layer', () => {
        map.current.getCanvas().style.cursor = '';
        popup.current.remove();
      });
    } catch (e) {
      console.warn(e);
    }

    try {
      // get api data for targeted region's home path
      const home_data = await APIActions.getHome(fieldContractId, region.RegionId);
      const home_type = "LineString";
      const home_coordinates = home_data.coordinates as Position[];

      // Set data for home lines
      (map.current?.getSource('home-lines') as GeoJSONSource).setData({
        type: "FeatureCollection",
        features: [{
          type: "Feature",
          properties: {},
          geometry: {
            type: home_type,
            coordinates: home_coordinates
          }
        }]
      })
    } catch (e) {
      console.warn(e);
    }

    try {
      // get api data for targeted region's manual zones
      const manualZoneData = await APIActions.getManualZones(fieldContractId, region.RegionId);
      const manualZoneCoordinates = manualZoneData.coordinates as Position[][];

      // Set data for manual zones
      (map.current?.getSource('manual-zone-lines') as GeoJSONSource).setData({
        type: "FeatureCollection",
        features: [{
          type: "Feature",
          properties: {},
          geometry: {
            type: "MultiLineString",
            coordinates: manualZoneCoordinates
          }
        }]
      });
    } catch (e) {
      console.warn(e);
    }

    try {
      // get api data for targeted region's manual zones
      const onRampData = await APIActions.getOnRamps(fieldContractId, region.RegionId);
      const onRampCoordinates = onRampData.coordinates as Position[][];

      // Set data for manual zones
      (map.current?.getSource('on-ramp-lines') as GeoJSONSource).setData({
        type: "FeatureCollection",
        features: [{
          type: "Feature",
          properties: {},
          geometry: {
            type: "MultiLineString",
            coordinates: onRampCoordinates
          }
        }]
      });
    } catch (e) {
      console.warn(e);
    }

    try {
      // get api data for targeted region's highways
      const highwayData = await APIActions.getHighways(fieldContractId, region.RegionId);
      const highwayCoordinates = highwayData.coordinates as Position[][];

      // Set data for manual zones
      (map.current?.getSource('highway-lines') as GeoJSONSource).setData({
        type: "FeatureCollection",
        features: [{
          type: "Feature",
          properties: {},
          geometry: {
            type: "MultiLineString",
            coordinates: highwayCoordinates
          }
        }]
      });
    } catch (e) {
      console.warn(e);
    }
  }

  /**
   * Handle bot info popup by rendering popup when mouse hovers over bot on map
   * 
   * @async
   * @param {boolean} mouseIsOverMarker - onmouseover is called
   * @param {string} robotName - (optional) name of robot mouse is hovering over
   */
  const botMarkerPopupHandler = (mouseIsOverMarker: boolean, robotName?: string) => {
    const popupElement = document.getElementById("popup");
    if (mouseIsOverMarker && focusStateRef.current === "map") {
      const markerDeviceInfo = sharedProps.deviceInfo.findIndex((info) => info.device.name === robotName);
      if (markerDeviceInfo >= 0) {
        setDeviceInfoIndex(markerDeviceInfo);
        popupElement.style.display = "grid";
      }    } else {
      popupElement.style.display = "none";
    }
  }

  /**
   * Update joystick position data and dispatch event containing x and z data
   * 
   * @param {number} x - x axis of joystick input
   * @param {number} z = z axis of joystick input
   */
  const updateJoystickPosition = (x: number, z: number) => {
    if (x !== null) {
      joystickPosition.current.x = x;
    }
    if (z !== null) {
      joystickPosition.current.z = z;
    }
    const event = new CustomEvent("joystick", {
      detail: {
        x: joystickPosition.current.x,
        z: joystickPosition.current.z
      }
    });
    document.dispatchEvent(event);
    console.log(event);
  };

  /**
   * Handle onclick action for Header back button and route browser accordingly
   */
  const handleBackButtonPress = () => {
    if (dualMonitorMode) {
      navigate('/dualMonitorMapView');
    } else {
      navigate('/');
    }
  }

  const popupBotNameDisplay = (info: DeviceInfo) => {
    const robotNumberDisplay = info.robotNumber === 0 ? "Robot --" : "Robot " + info.robotNumber;
    let alleyNumberDisplay = " - Alley ###";
    if (info.path !== "") {
      alleyNumberDisplay = " - Alley " + JSON.parse(info.path).path_name;
    }
    return robotNumberDisplay + alleyNumberDisplay;
  }

  const adjustBotMarkerZoom = (zoom = map.current?.getZoom()) => {
    const newScale = BotScaling.computeNewZoom(zoom,  map.current?.getCenter().lat, startBotScaling)

    if (newScale != undefined) {
      markers.forEach((marker) => {
        const botmarkerElement = marker.marker.getElement();
        botmarkerElement.style.height = botImageHeight * newScale+ "px";
        botmarkerElement.style.width = botImageWidth * newScale + "px";
        botmarkerElement.style.fontSize = 8 * newScale * 100 + "px";
      })
    }
  }

  const jobCompletionPercentageDisplay = () => {
    let percentage = "N/A";
    if (jobCompletionPercentage) {
      percentage = jobCompletionPercentage.substring(0, jobCompletionPercentage.indexOf('.') + 3) + "%";
    }
    return "Job Completion: " + percentage;
  }
    
  /**
   * Handle onclick action for ownership prompt buttons
   */
  const handleOwnershipPromptButtonPressed = (selection: string) => {
    if (selection == "no") {
      setTargetBotState(null);
      setDisplayOwnershipPrompt(false);
    } else if (selection == "yes") {
      setPendingStateChange(true);
      sendStateRequest(targetBotState);
      setDisplayOwnershipPrompt(false);
    }
  };

  const sendStateRequest = (botState: BotState) => {
    try {
      // Collect information needed for bot state request
      const currentBotState = deviceInfo.botState;
      const currentField = sharedProps.sharedFilter.field;
      const ownerName = Authentication.getCurrentUser().firstName + ' ' + Authentication.getCurrentUser().lastName;
      const data = {
        header: {
          robot_id: "robot" + deviceInfo.robotNumber
        },
        requested_bot_op_state_transition: BotStateUtility.mapBotStateRequest(currentBotState, botState),
        description: 'request from UI',
        field_id: currentField.FieldContractId === 0 ? "None" : currentField.FieldName,
        fleet_id: 'weedbot',
        fleet_member_id: 'weedbot' + deviceInfo.robotNumber,
        turker_id: ownerName,
        override_key: 1234,
        default_end_effector_on_state: [true, true]
      }
      // Reformat string for ROS publish data
      const dataString = JSON.stringify(data)
        .replace(/"([^"]+)":/g, '$1:')
        .replace(/\uFFFF/g, '\\"')
        .replaceAll('"', "'")
        .replaceAll(':', ': ')
        .replaceAll(',', ', ')
      // Send ros data to bot
      console.log("Data sent: " + dataString);
      const ROS_COMMAND = "ros2 service call /robot" + deviceInfo.robotNumber + "/BotStateTransition greenfield/srv/BotStateTransition ";
      deviceInfo.realtimeRosCommandChannel.send(ROS_COMMAND + '"' + dataString + '"');
    } catch (e) {
      console.warn("Error requesting state from device (" + deviceInfo.device.name + "): " + e);
    }
  }

  const handleBotStateClick = (botState: BotState, disabled: boolean) => {
    const botClaimedByDifferentOwner = (
      deviceInfo.botOwner !== "None" &&
      deviceInfo.botOwner !== Authentication.getCurrentUser().firstName + " " + Authentication.getCurrentUser().lastName
    );
    if (!disabled) {
      setTargetBotState(botState);
      if (botClaimedByDifferentOwner) {
        setDisplayOwnershipPrompt(true);
      } else {
        setPendingStateChange(true);
        sendStateRequest(botState);
      }
    }
  };

  const displayBotStateText = (botState: BotState, index: number) => {
    const currentBotState = deviceInfo.botState;
    const disabled = (
      pendingStateChange ||
      !deviceInfo.realtimeRosCommandChannel ||
      (
        BotStateUtility.mapBotStateRequest(currentBotState, botState) === BotStateRequest.NONE &&
        currentBotState !== botState
      )
    );
    return (
      <Typography 
        key={index}
        className="StateText"
        variant="h6"
        onDoubleClick={() => handleBotStateClick(botState, disabled)}
        sx={{
          color: botState === currentBotState ? "#00ff00" : botState === targetBotState ? "#ffba42" : "#ffffff",
          fontSize: "10px",
          alignSelf: "center",
          cursor: botState === targetBotState || disabled ? "default" : "pointer",
          textDecoration: botState === targetBotState ? "underline" : "none",
          opacity: disabled ? 0.3 : 1,
          userSelect: 'none',
        }}>
        {botState.toString()}
      </Typography>
    );
  };

  /**
   * Iterate through every device and update map based on device info
   */
  const updateMapLocations = () => {
    sharedProps.deviceInfo.forEach((info) => {
      if (!info.realtimeOdomChannel || (info.realtimeOdomChannel && info.realtimeOdomChannel.listeners.length <= 0)) {
        // fetch odometry info from device if they are rendered on map
        const markerIndex = markers.findIndex((marker) => marker.name === info.device.name);
        if (info.odometryURL !== "" && markerIndex !== -1) {
          updateYaw(info, null ,info.odometryURL);
        }
      }
    });
    // Update bot markers
    updateMarkers();
  }

  /**
   * Return bot marker HTML element based on passed name, robot number
   * 
   * @param {string} name - Robot name
   * @param {number} robotNumber - Robot number
   * @param {string} deviceId - Robot deviceId
   * @return {HTMLElement} Bot marker HTML element
   */
  const getBotMarkerElement = (name: string, robotNumber: number): HTMLElement => {
    const element = document.createElement('div');
    element.className = "OverAllBotMarker";
    element.style.cursor = "pointer";
    element.onmouseover = () => botMarkerPopupHandler(true, name);
    element.onmouseout = () => botMarkerPopupHandler(false);
    if (name) {
      element.innerText = robotNumber === 0 ? name.slice(name.length - 3, name.length) : String(robotNumber);
    }
    return element;
  }

  /**
   * Create a new bot marker
   * 
   * @param {BotMarker[]} markerArray - array of bot markers
   * @param {string} name - bot name to create marker for
   * @param {number} robotNumber - Robot number for marker
   * @param {string} deviceId - Robot device id
   * @param {number} longitude - longitude coordinate of bot
   * @param {number} latitude - latitude coordinate of bot
   */
  const createMarker = (markerArray: BotMarker[], name: string, robotNumber: number, longitude: number, latitude: number) => {
    if (map.current) {
      const marker = new mapboxgl.Marker(getBotMarkerElement(name, robotNumber))
        .setLngLat([longitude, latitude])
        .addTo(map.current)
      const newMarker: BotMarker = { name: name, marker: marker };
      markerArray.push(newMarker);
    }
  }

  /**
   * Update all the bot markers, accounting for device filters
   */
  const updateMarkers = () => {
    const newMarkersArray: BotMarker[] = markers;
    sharedProps.deviceInfo.forEach((info) => {
      const markerIndex = markers.findIndex((marker) => marker.name === info.device.name);
      // Markers match filter
      if(info.latitude !== null && info.longitude !== null) {
        if (markerIndex === -1) {
          // Create new marker if one does not exist
          createMarker(newMarkersArray, info.device.name, info.robotNumber, info.longitude, info.latitude);
          adjustBotMarkerZoom(defaultZoom);
        } else if (!info.realtimeOdomChannel || (info.realtimeOdomChannel && info.realtimeOdomChannel.listeners.length <= 0)) {
          // Update position
          if (info.id === deviceInfo.id) {
            map.current.setCenter([info.longitude, info.latitude]);
          }
          newMarkersArray[markerIndex].marker.setLngLat([info.longitude, info.latitude]);
        }
      }
    });
    setMarkers(newMarkersArray);
  }

  const handleEventsDialog = (open: boolean) => {
    setEventsDialogOpen(open);
  };

  const controlRobotComponent = (robotNumber: number, componentName: BotComponentName, desiredState: BotComponentState) => {
    if (deviceInfo.realtimeRosCommandChannel) {
      const ROS_COMMAND = `ros2 topic pub -1 /robot${robotNumber}/ComponentCommand greenfield/msg/ComponentCommand '{header: {robot_id: robot${robotNumber}}, component: {component_name: ${componentName}, component_state: ${desiredState}}}'`;
      console.log(`Requesting ${componentName} on robot${robotNumber} be set to ${desiredState} (${ROS_COMMAND})`);
      deviceInfo.realtimeRosCommandChannel.send(ROS_COMMAND);
      // TODO: update weedbot code to publish new botstate after component command is processed - then remove the following request
      BotStateUtility.sendStateRequest(deviceInfo, deviceInfo.botOwner);
    } else {
      console.warn("Realtime ROS Command Channel for robot" + deviceInfo.robotNumber + " does not exist.");
    }
  }
  
  return (
    <div className="MainContainerDetailed">
      {displayOwnershipPrompt && deviceInfo &&
        <div className="OwnershipPromptContainer">
          <div className="OwnershipPromptBackground"/>
          <div className="OwnershipPrompt">
            <Typography className="OwnershipPromptText" variant="h1" sx={{ color: "white", fontSize: 32 }}>
              {"Take teleop control of " + getRobotDisplayName() + "?"}
            </Typography>
            <Typography className="OwnershipPromptText" variant="h6" sx={{ color: "#ffba42", fontSize: 24 }}>
              WARNING
            </Typography>
            <Typography className="OwnershipPromptText" variant="h6" sx={{ color: "grey", fontSize: 16 }}>
              {"Selecting \"Yes\" will revoke control from anyone currently using this device's teleop"}
            </Typography>
            <Typography className="OwnershipPromptText" variant="h6" sx={{ color: "white", fontSize: 18 }}>
              {"This device is currently occupied by:"}
            </Typography>
            <Typography className="OwnershipPromptText" variant="h6" sx={{ color: "white", fontSize: 18 }}>
              {deviceInfo.botOwner}
            </Typography>
            <div className="DetailedOwnershipPromptButtonContainer">
              <button className="DetailedOwnershipPromptButtonYes" onClick={() => handleOwnershipPromptButtonPressed("yes")}>
                <Typography variant="h6" sx={{ color: "white", fontSize: 16 }}>Yes</Typography>
              </button>
              <button className="DetailedOwnershipPromptButtonNo" onClick={() => handleOwnershipPromptButtonPressed("no")}>
                <Typography variant="h6" sx={{ color: "white", fontSize: 16 }}>No</Typography>
              </button>
            </div>
          </div>
        </div>
      }
      <SideBar/>
      <div>
        <Header 
          handleBackButton={handleBackButtonPress} 
          currentVersions={currentSoftwareVersions} 
          robotDisplayName={getRobotDisplayName()} 
          handleEventsButton={() => handleEventsDialog(true)} 
          eventsCount={deviceInfo.events.filter((e) => { return !eventIsAcknowledged(e, deviceInfo.robotNumber, sharedProps.acknowledgedEvents); }).length}/>
        <EventsDialogBox 
          dialogIsOpen={eventsDialogOpen} 
          handleClose={() => handleEventsDialog(false)} 
          deviceInfo={sharedProps.deviceInfo} 
          selectedDevices={[deviceInfo.device]}
          acknowledgedEvents={sharedProps.acknowledgedEvents}
          setAcknowledgedEvents={sharedProps.setAcknowledgedEvents}/>
        <div className="DetailedViewContainer">
          <div className="RobotDetails">
            <Typography className="ActionStatus" variant="h6" sx={{ color: "#ffba42", fontSize: 16 }}>Lorem ipsum</Typography>
            {frontViewSlot()}
            {backViewSlot()}
            <div className="StateSelectContainer">
              {Object.values(BotState).map((value, index) => displayBotStateText(value, index))}
            </div>
            {pendingStateChange &&
              <div className="SaveBatchSettingsSpinner"></div>
            }
            <Grid container sx={{ paddingBottom: "0.5rem" }}>
              <ComponentStateIcon componentName="Ping" componentState={deviceInfo.pingMs}/>
              <ComponentStateIcon componentName={BotComponentName.CUTTERS} componentState={deviceInfo.cutterStatus}/>
              <ComponentStateIcon componentName={BotComponentName.FEELERS} componentState={deviceInfo.feelerStatus}/>
              <ComponentStateIcon componentName={BotComponentName.EXTERNAL_VCU_FAN} componentState={deviceInfo.vcuFanStatus}/>
              <ComponentStateIcon componentName={BotComponentName.LIGHTS} componentState={deviceInfo.lightsStatus}/>
              <button className="Odometer">
                <Typography variant="h6" sx={{ color: "white", fontSize: 20 }}>Speed</Typography>
                <div className="OdometerTextContainer">
                  <Typography variant="h6" sx={{ color: "white", fontSize: 24, justifySelf: 'end' }}>0.0</Typography>
                  <Typography variant="h6" sx={{ color: "white", fontSize: 10, justifySelf: 'start' }}>MPH</Typography>
                </div>
              </button>
            </Grid>
            <div className="BotControlsContainer">
              <div className="ComponentStatusAndControl">
                <button className="ComponentControlButton" onClick={() => { controlRobotComponent(deviceInfo.robotNumber, BotComponentName.EXTERNAL_VCU_FAN, BotComponentState.EXTERNAL_VCU_FAN_ON); }}>
                  <img src={FanIcon} alt="Missing Asset"></img>
                  <Typography variant="h6" className="ComponentControlText">Turn Fans On</Typography>
                </button>
                <button className="ComponentControlButton" onClick={() => { controlRobotComponent(deviceInfo.robotNumber, BotComponentName.EXTERNAL_VCU_FAN, BotComponentState.EXTERNAL_VCU_FAN_AUTO); }}>
                  <img src={FanIcon} alt="Missing Asset"></img>
                  <Typography variant="h6" className="ComponentControlText">Allow VCU to Control Fans</Typography>
                </button>
              </div>
              <div className="ComponentStatusAndControl">
                <button className="ComponentControlButton" onClick={() => { controlRobotComponent(deviceInfo.robotNumber, BotComponentName.LIGHTS, BotComponentState.LIGHTS_ON); }}>
                  <img src={LightsIcon} alt="Missing Asset"></img>
                  <Typography variant="h6" className="ComponentControlText">Turn Lights On</Typography>
                </button>
                <button className="ComponentControlButton" onClick={() => { controlRobotComponent(deviceInfo.robotNumber, BotComponentName.LIGHTS, BotComponentState.LIGHTS_OFF); }}>
                  <img src={LightsIcon} alt="Missing Asset"></img>
                  <Typography variant="h6" className="ComponentControlText">Turn Lights Off</Typography>
                </button>
              </div>
            </div>
            <div className="BatteryContainer">
              <img className="ChargeIcon" src={ChargeIcon} alt="Missing Asset"></img>
              {displayBatteryCharge(0)}
              {displayBatteryCharge(10)}
              {displayBatteryCharge(20)}
              {displayBatteryCharge(30)}
              {displayBatteryCharge(40)}
              {displayBatteryCharge(50)}
              {displayBatteryCharge(60)}
              {displayBatteryCharge(70)}
              {displayBatteryCharge(80)}
              {displayBatteryCharge(90)}
              <Typography className="BatteryText" variant="h6" sx={{ color: "white", fontSize: 12 }}>{deviceInfo ? Math.round(deviceInfo.battery) + "%" : "N/A"}</Typography>
            </div>
            <div className="DetailedJobCompletionPercentage">
              <Typography variant="h6" sx={{ color: "white", fontSize: 16 }}>{jobCompletionPercentageDisplay()}</Typography>
            </div>
          </div>
          <div>
            {focusViewSlot()}
          </div>
        </div>
      </div>
      {sharedProps.deviceInfo[deviceInfoIndex] &&
        <div className="BotMarkerPopup" id="popup">
          <Typography variant="h6" sx={{ color: "black", fontSize: 12 }}>{popupBotNameDisplay(sharedProps.deviceInfo[deviceInfoIndex])}</Typography>
          <div className="BotMarkerIconContainer">
            <button className={sharedProps.deviceInfo[deviceInfoIndex].cutterStatus.includes("On") ? "CutterButtonOn" : "CutterButtonOff"}>
              <img src={CutterIcon} alt="Missing Asset" style={{ height: "25px", backgroundColor: "#ffba42" }}></img>
              <Typography variant="h6" sx={sharedProps.deviceInfo[deviceInfoIndex].cutterStatus.includes("On") ? { color: "white", fontSize: 12 } : { color: "#ffba42", fontSize: 12 }}>{sharedProps.deviceInfo[deviceInfoIndex].cutterStatus}</Typography>
            </button>
            <button className="PingButton">
              <img src={PingIcon} alt="Missing Asset" style={{ height: "25px", backgroundColor: "#ffba42" }}></img>
              <Typography variant="h6" sx={{ color: "#ffba42", fontSize: 12 }}>{sharedProps.deviceInfo[deviceInfoIndex] && sharedProps.deviceInfo[deviceInfoIndex].pingMs !== 0 ? "Ping: " + sharedProps.deviceInfo[deviceInfoIndex].pingMs + "ms" : "Ping: N/A"}</Typography>
            </button>
            <button className="Odometer">
              <Typography variant="h6" sx={{ color: "white", fontSize: 18 }}>Speed</Typography>
              <div className="OdometerTextContainer">
                <Typography variant="h6" sx={{ color: "white", fontSize: 24, justifySelf: 'end' }}>0.0</Typography>
                <Typography variant="h6" sx={{ color: "white", fontSize: 10, justifySelf: 'start' }}>MPH</Typography>
              </div>
            </button>
            <button className="BotMarkerPopupBattery">
              <img src={ChargeIconOrange} alt="Missing Asset" style={{ height: "25px" }}></img>
              <Typography variant="h6" sx={{ color: "#ffba42", fontSize: 12 }}>{"Power: " + ((sharedProps.deviceInfo[deviceInfoIndex] && sharedProps.deviceInfo[deviceInfoIndex].battery !== 0) ? Math.round(sharedProps.deviceInfo[deviceInfoIndex].battery) + "%" : "N/A")}</Typography>
            </button>
          </div>
        </div>
      }
    </div>
  );
}