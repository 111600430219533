// ordered from least severe to most severe
const SEVERITY_NAME_INFO = "info";
const SEVERITY_NAME_ADVISORY = "advisory";
const SEVERITY_NAME_CAUTION = "caution";
const SEVERITY_NAME_WARNING = "warning";

const FORMANT_SEVERITY_NAME_INFO = "info";
const FORMANT_SEVERITY_NAME_WARNING = "warning";
const FORMANT_SEVERITY_NAME_ERROR = "error";
const FORMANT_SEVERITY_NAME_CRITICAL = "critical";

export default {
  info: SEVERITY_NAME_INFO, 
  advisory: SEVERITY_NAME_ADVISORY,
  caution: SEVERITY_NAME_CAUTION,
  warning: SEVERITY_NAME_WARNING,
}

export function convertToRosieSeverityName(formantSeverityName: string) {
  let rosieSeverityName = "unknown";
  switch(formantSeverityName) {
    case FORMANT_SEVERITY_NAME_INFO:
      rosieSeverityName = SEVERITY_NAME_INFO;
      break;
    case FORMANT_SEVERITY_NAME_WARNING:
      rosieSeverityName = SEVERITY_NAME_ADVISORY;
      break;
    case FORMANT_SEVERITY_NAME_ERROR:
      rosieSeverityName = SEVERITY_NAME_CAUTION;
      break;
    case FORMANT_SEVERITY_NAME_CRITICAL:
      rosieSeverityName = SEVERITY_NAME_WARNING;
      break;
  }
  return rosieSeverityName;
}

export function convertToFormantSeverityName(rosieSeverityName: string) {
  let formantSeverityName = "unknown";
  switch(rosieSeverityName) {
    case SEVERITY_NAME_INFO:
      formantSeverityName = FORMANT_SEVERITY_NAME_INFO;
      break;
    case SEVERITY_NAME_ADVISORY:
      formantSeverityName = FORMANT_SEVERITY_NAME_WARNING;
      break;
    case SEVERITY_NAME_CAUTION:
      formantSeverityName = FORMANT_SEVERITY_NAME_ERROR;
      break;
    case SEVERITY_NAME_WARNING:
      formantSeverityName = FORMANT_SEVERITY_NAME_CRITICAL;
      break;
  }
  return formantSeverityName;
}