import React from "react";
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, Icon, IconName } from "@formant/ui-sdk";
import SeverityNames from "./SeverityNames";
import DeviceInfo from "../../ts/interfaces/DeviceInfo";
import { Device } from "@formant/data-sdk";
import BotEvent, { AcknowledgedEvent, BotEventWithRobotNumber, EventName, eventIsAcknowledged, eventsMatch, getEventDisplayName } from "../../ts/interfaces/BotEvent";
import "./EventsDialogBox.css";

interface EventCardProps {
  event: BotEvent,
  robotNumber?: number,
}

// TODO: try passing BotEventWithRobotNumber[] instead of DeviceInfo[] and Device[]
interface EventsDialogBoxProps {
  dialogIsOpen: boolean,
  handleClose: () => void,
  deviceInfo: DeviceInfo[],
  selectedDevices: Device[],
  acknowledgedEvents: AcknowledgedEvent[],
  setAcknowledgedEvents: React.Dispatch<React.SetStateAction<AcknowledgedEvent[]>>,
}

export function generateEventText(event: BotEvent, robotNumber?: number) {
  if (robotNumber) {
    return "Robot" + robotNumber + " - " + getEventDisplayName(event.name) + " (" + event.description + ") [" + event.timestamp.toLocaleString() + "]"; 
  }
  return "(" + event.name + ") " + event.description + " [" + event.timestamp.toLocaleString() + "]";
}

export default function EventsDialogBox(props: EventsDialogBoxProps) {
  const selectedDeviceInfo: DeviceInfo[] = props.deviceInfo.filter((info) => { return props.selectedDevices.some((device) => { return device.id === info.id; }); });
  const eventsWithRobotNumber: BotEventWithRobotNumber[] = [];
  selectedDeviceInfo.forEach((info) => {
    info.events.forEach((event) => {
      eventsWithRobotNumber.push({
        event: {...event}, 
        robotNumber: props.selectedDevices.length >= 1 ? info.robotNumber : null
      });
    });
  });
  const totalEventCount = eventsWithRobotNumber.length;
  const displayedEvents = eventsWithRobotNumber.filter((e) => {return e.event.severity === SeverityNames.warning});
  displayedEvents.sort((a, b) => { if (a.event.timestamp > b.event.timestamp) { return -1; } else { return 1; }});
  const otherEvents = eventsWithRobotNumber.filter((e) => { return e.event.severity !== SeverityNames.warning });
  // sort other events in reverse order so we can pop them off the list into the correct order
  otherEvents.sort((a, b) => { if (a.event.timestamp > b.event.timestamp) { return 1; } else { return -1; }});
  while (otherEvents.length > 0) {
    displayedEvents.push(otherEvents.pop());
  }

  const setEventAcknowledged = (event: BotEvent, robotNumber: number, acknowledged: boolean) => {
    const newAcknowledgedEvents = [...props.acknowledgedEvents];
    if (acknowledged) {
      if (eventIsAcknowledged(event, robotNumber, props.acknowledgedEvents)) {
        return
      } 
      newAcknowledgedEvents.push({event: event, robotNumber: robotNumber, timestamp: new Date(Date.now())});
      props.setAcknowledgedEvents(newAcknowledgedEvents);
      return
    } 
    const index = props.acknowledgedEvents.findIndex((e) => { return eventsMatch(e.event, event); });
    if (index !== -1) {
      newAcknowledgedEvents.splice(index, 1);
      props.setAcknowledgedEvents(newAcknowledgedEvents);
    }
  }

  function EventCard(cardProps: EventCardProps) {
    let iconName: IconName = "event";
    switch(cardProps.event.severity) {
      case SeverityNames.caution:
        iconName = "error";
        break;
      case SeverityNames.warning:
        iconName = "critical";
        break;
    }
    let cardClassName = "EventCardDefault";
    switch(cardProps.event.name) {
      case EventName.INS_TEMPERATURE_TOO_HIGH:
        cardClassName = "EventCardInsTemperatureTooHigh";
        break;
      case EventName.PATH_ERROR_TOO_LARGE:
        cardClassName = "EventCardPathErrorTooLarge";
        break;
      case EventName.CROSSTRACK_TOO_LARGE:
        cardClassName = "EventCardCrossTrackTooLarge";
        break;
      case EventName.LEFT_FEELER_FAILURE:
        cardClassName = "EventCardFeelerFailure";
        break;
      case EventName.RIGHT_FEELER_FAILURE:
        cardClassName = "EventCardFeelerFailure";
        break;
    }
    return (
      <div className={cardClassName}>
        <Icon name={iconName}/>
        <Typography variant="body1" className={eventIsAcknowledged(cardProps.event, cardProps.robotNumber, props.acknowledgedEvents) ? "EventTextAcknowledged" : "EventText"}>{generateEventText(cardProps.event, cardProps.robotNumber)}</Typography>
        {!eventIsAcknowledged(cardProps.event, cardProps.robotNumber, props.acknowledgedEvents) ? <Button className="AcknowledgementButton" onClick={() => { setEventAcknowledged(cardProps.event, cardProps.robotNumber, true); }}>acknowledge</Button> : <div></div>}
      </div>
    )
  }

  const generateEventsList = () => {
    const cards = displayedEvents.map((e, i) => { return (<EventCard key={i} event={e.event} robotNumber={e.robotNumber}/>); });
    return (
      <div>
        {cards}
      </div>
    )
  }

  // TODO: add filtering/sorting to the the popup (maybe only when multiple bots?)
  return (
    <>
    <Dialog open={props.dialogIsOpen} onClose={props.handleClose} fullWidth maxWidth="lg">
      {selectedDeviceInfo.length === 1 ? 
        <DialogTitle>{totalEventCount} Events for Robot #{selectedDeviceInfo[0].robotNumber}</DialogTitle>
      :
        <DialogTitle>{totalEventCount} Events for {props.selectedDevices.length} Robots</DialogTitle>
      }
      <DialogContent dividers>
        {generateEventsList()}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Hide Events</Button>
      </DialogActions>
    </Dialog>
    </>
  )
}