import React from "react";
import { Snackbar, Icon } from "@formant/ui-sdk";
import { Alert } from "@mui/material";
import DeviceInfo from "../../ts/interfaces/DeviceInfo";
import BotEvent from "../../ts/interfaces/BotEvent";
import { Device } from "@formant/data-sdk";
import { generateEventText } from "../EventsDialogBox/EventsDialogBox";
import SeverityNames from "../EventsDialogBox/SeverityNames";
import { AcknowledgedEvent, eventIsAcknowledged, EventName } from "../../ts/interfaces/BotEvent";

interface EventsSnackbarProps {
  deviceInfo: DeviceInfo[],
  selectedDevices: Device[],
  acknowledgedEvents: AcknowledgedEvent[],
  setAcknowledgedEvents: React.Dispatch<React.SetStateAction<AcknowledgedEvent[]>>,
}

export default function EventsSnackbar(props: EventsSnackbarProps) {
  const eventNeedsDisplaying = (e: BotEvent, robotNumber: number) => { return !eventIsAcknowledged(e, robotNumber, props.acknowledgedEvents) }
  const selectedDeviceInfo = props.deviceInfo.filter((info) => { return props.selectedDevices.some((device) => { return device.id === info.id; }); });
  // get the first event from the first bot to populate the alert
  const currentDeviceInfo = selectedDeviceInfo.find((info) => { return info.events.some((e) => { return eventNeedsDisplaying(e, info.robotNumber); }); });
  // if null, there are no events to display, the snackbar will remain closed
  const currentEvent = currentDeviceInfo ? currentDeviceInfo.events.find((e) => { return eventNeedsDisplaying(e, currentDeviceInfo.robotNumber); }) : null;
  let iconName: "event" | "error" | "critical" = "event";
  let toastColor = "lightgreen";
  if (currentEvent) {
    switch(currentEvent.severity) {
      case SeverityNames.caution:
        iconName = "error";
        break;
      case SeverityNames.warning:
        iconName = "critical";
        break;
    }
    switch(currentEvent.name) {
      case EventName.INS_TEMPERATURE_TOO_HIGH:
        toastColor = "red";
        break;
      case EventName.PATH_ERROR_TOO_LARGE:
        toastColor = "yellow";
        break;
      case EventName.CROSSTRACK_TOO_LARGE:
        toastColor = "#6E260E";
        break;
      case EventName.LEFT_FEELER_FAILURE:
        toastColor = "#000000";
        break;
      case EventName.RIGHT_FEELER_FAILURE:
        toastColor = "#000000";
        break;
    }
  }

  // update acknowledgedEvents list with event acknowledgement
  // when the snackbar is re-rendered by this, it will pick up the next event
  const acknowledgeCurrentEvent = () => {
    const newAcknowledgedEvents = [...props.acknowledgedEvents];
    newAcknowledgedEvents.push({event: currentEvent, robotNumber: currentDeviceInfo.robotNumber, timestamp: new Date(Date.now())});
    props.setAcknowledgedEvents(newAcknowledgedEvents);
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    acknowledgeCurrentEvent();
  }

  return (
    <>
    <Snackbar open={!!currentEvent} onClose={handleClose}>
      <Alert onClose={handleClose} sx={{color: toastColor, backgroundColor: "#000058", borderRadius: "1rem", borderColor: toastColor, borderStyle: "solid" }} icon={<Icon name={iconName} sx={{color: toastColor}} />}>
        {currentEvent ? generateEventText(currentEvent, currentDeviceInfo.robotNumber) : ""}
      </Alert>
    </Snackbar>
    </>
  )
}