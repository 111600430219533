import React, { useState, useEffect, useCallback, useRef } from 'react';
import './RobotCards.css';
import { Typography, Box, Icon } from "@formant/ui-sdk";
import { Chip, Stack } from "@mui/material";
import LoadSpinner from '../UI/spinner';
import SharedPropsInterface from '../../ts/interfaces/SharedPropsInterface';
import DeviceInfo from '../../ts/interfaces/DeviceInfo';
import { AcknowledgedEvent, EventName, eventIsAcknowledged } from "../../ts/interfaces/BotEvent";
import { NavigateFunction, useNavigate } from 'react-router-dom';
import SeverityNames from "../EventsDialogBox/SeverityNames";
import { SortOption } from '../../ts/enums/SortOption';
import { Authentication } from '@formant/data-sdk';
import { BotComponentName, BotComponentState } from '../../ts/interfaces/ComponentState';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import ComponentStateIcon from '../UI/ComponentStateIcon';

// Assets
import StatusIcon from "../../Assets/Icons/Robots Inner Page/Group 2413.png";
import ConnectionIcon from "../../Assets/Icons/Dashboard/Mask group-1.png";
import BatteryIcon from "../../Assets/Icons/Dashboard/Mask group-2.png";
import CutterIcon from "../../Assets/Icons/Robots Inner Page/Mask group-3.png";

interface RobotCardProps {
  deviceInfo: DeviceInfo,
  isDualMonitorInstance: boolean,
  navigate: NavigateFunction,
  mapChannelRef: React.MutableRefObject<BroadcastChannel>,
  acknowledgedEvents: AcknowledgedEvent[],
}

function RobotCard(props: RobotCardProps): JSX.Element {
  const missingAssetText = "Missing Asset";
  const mapChannel = props.mapChannelRef.current;
  const unacknolwedgedEvents = props.deviceInfo.events.filter((e) => { return !eventIsAcknowledged(e, props.deviceInfo.robotNumber, props.acknowledgedEvents); });
  const eventNames: string[] = unacknolwedgedEvents.map((e) => { return e.name; });
  const temperatureTooHigh = eventNames.some((n) => { return n === EventName.INS_TEMPERATURE_TOO_HIGH; });
  const pathErrorTooLarge = eventNames.some((n) => { return n === EventName.PATH_ERROR_TOO_LARGE; });
  const crossTrackTooLarge = eventNames.some((n) => { return n === EventName.CROSSTRACK_TOO_LARGE; });
  const feelerFailure = eventNames.some((n) => { return (n === EventName.LEFT_FEELER_FAILURE || n === EventName.RIGHT_FEELER_FAILURE); });
  const cutterStall = eventNames.some((n) => { return (n === EventName.CUTTER_MOTOR_LEFT_STALL || n === EventName.CUTTER_MOTOR_RIGHT_STALL); });
  //const warningEvents = unacknolwedgedEvents.filter((e) => { return (e.severity === SeverityNames.warning); });
  const leftCutterOn = props.deviceInfo.cutterStatus === BotComponentState.CUTTERS_LEFT_ON || props.deviceInfo.cutterStatus === BotComponentState.CUTTERS_BOTH_ON;
  const rightCutterOn = props.deviceInfo.cutterStatus === BotComponentState.CUTTERS_RIGHT_ON || props.deviceInfo.cutterStatus === BotComponentState.CUTTERS_BOTH_ON;
  const [flashAnimationName, setFlashAnimationName] = useState<string>("none");
  

  useEffect(() => {
    const cycleColorFlashInterval = setInterval(() => {
      let newAnimationName = "none";
      if (eventNames.length > 0) {
        if (flashAnimationName === "none") {
          if (temperatureTooHigh) {
            newAnimationName = "FlashRed";
          } else if (pathErrorTooLarge) {
            newAnimationName = "FlashYellow";
          } else if (cutterStall) {
            newAnimationName = "FlashOrange";
          } else if (crossTrackTooLarge) {
            newAnimationName = "FlashBrown";
          } else if (feelerFailure) {
            newAnimationName = "FlashBlack";
          }
        } else if (flashAnimationName === "FlashRed") {
          if (pathErrorTooLarge) {
            newAnimationName = "FlashYellow";
          } else if (cutterStall) {
            newAnimationName = "FlashOrange";
          } else if (crossTrackTooLarge) {
            newAnimationName = "FlashBrown";
          } else if (feelerFailure) {
            newAnimationName = "FlashBlack";
          }
        } else if (flashAnimationName === "FlashYellow") {
          if (cutterStall) {
            newAnimationName = "FlashOrange";
          } else if (crossTrackTooLarge) {
            newAnimationName = "FlashBrown";
          } else if (feelerFailure) {
            newAnimationName = "FlashBlack";
          } else if (temperatureTooHigh) {
            newAnimationName = "FlashRed";
          }
        } else if (flashAnimationName === "FlashOrange") {
          if (crossTrackTooLarge) {
            newAnimationName = "FlashBrown";
          } else if (feelerFailure) {
            newAnimationName = "FlashBlack";
          } else if (temperatureTooHigh) {
            newAnimationName = "FlashRed";
          } else if (pathErrorTooLarge) {
            newAnimationName = "FlashYellow";
          }
        } else if (flashAnimationName === "FlashBrown") {
          if (feelerFailure) {
            newAnimationName = "FlashBlack";
          } else if (temperatureTooHigh) {
            newAnimationName = "FlashRed";
          } else if (pathErrorTooLarge) {
            newAnimationName = "FlashYellow";
          } else if (cutterStall) {
            newAnimationName = "FlashOrange";
          }
        } else if (flashAnimationName === "FlashBlack") {
          if (temperatureTooHigh) {
            newAnimationName = "FlashRed";
          } else if (pathErrorTooLarge) {
            newAnimationName = "FlashYellow";
          } else if (cutterStall) {
            newAnimationName = "FlashOrange";
          } else if (crossTrackTooLarge) {
            newAnimationName = "FlashBrown";
          }
        }
      }
      setFlashAnimationName(newAnimationName);
    }, 1000);
    return () => { clearInterval(cycleColorFlashInterval); }
  });

  /**
 * Get display name for robot based on if number exists or not
 * 
 * @param {DeviceInfo} deviceInfo - device info to fetch name/number from
 * @return {JSX.Element} - react DOM element for robot display text
 */
  const getRobotDisplayName = () => {
    const robotNumberDisplay = props.deviceInfo.robotNumber === 0 ? "Robot --" : "Robot " + props.deviceInfo.robotNumber;
    let alleyNumberDisplay = "Alley ###";
    if (props.deviceInfo.path !== "") {
      alleyNumberDisplay = JSON.parse(props.deviceInfo.path).path_name;
    }
    return (
      <>
      <Typography className="Name" variant="h1" sx={{ textDecoration: "bold", color: "white", fontSize: 18 }}>{robotNumberDisplay}</Typography>
      <Typography variant="h1" sx={{ textDecoration: "bold", color: "white", fontSize: 18 }}>{alleyNumberDisplay}</Typography>
      </>
    )
  };

  /**
   * Navigate to detailed view for the corresponding bot
   */
  const cardDoubleClickHandler = () => {
    if (props.isDualMonitorInstance) {
      mapChannel.postMessage({
        message: "detailed-view",
        deviceId: props.deviceInfo.id,
        accessToken: Authentication.token
      });
    } else {
      props.navigate('/detailedView?deviceId=' + props.deviceInfo.id)
    }
  }

  // TODO: add button (or use Chip) to launch EventsDialogBox for this particular bot
  const cardContent = (
    <Grid className={"CardBackground"} 
          onDoubleClick={cardDoubleClickHandler} 
          key={props.deviceInfo.id} 
          sx={{ animationName: flashAnimationName }}>
      <div className="CardHeader">
        <img className="StatusIcon" src={StatusIcon} alt={missingAssetText}/>
        <Chip icon={<Icon name="event"/>} label={unacknolwedgedEvents.length} color="error" size="small" variant="outlined" sx={{width: "75%", ml: "10%", mt: "2.5%"}}/>
        <div className="BatteryAndWifi">
          <Typography variant="body1" sx={{ color: "white", fontSize: 8 }}>{props.deviceInfo.pingMs === 0 ? "N/A" : props.deviceInfo.pingMs + "ms"}</Typography>
          <img className="MaskGroup" src={ConnectionIcon} alt={missingAssetText} />
          <Typography variant="body1" sx={{ color: "#02ff1b", fontSize: 8 }}>{Math.round(props.deviceInfo.battery) + "%"}</Typography>
          <img className="MaskGroup" src={BatteryIcon} alt={missingAssetText} />
        </div>
      </div>
      {getRobotDisplayName()}
      <Grid container>
        <ComponentStateIcon componentName={BotComponentName.CUTTERS} componentState={props.deviceInfo.cutterStatus}/>
        <ComponentStateIcon componentName={BotComponentName.FEELERS} componentState={props.deviceInfo.feelerStatus}/>
        <ComponentStateIcon componentName={BotComponentName.EXTERNAL_VCU_FAN} componentState={props.deviceInfo.vcuFanStatus}/>
        <ComponentStateIcon componentName={BotComponentName.LIGHTS} componentState={props.deviceInfo.lightsStatus}/>
      </Grid>
      <Stack className="KeyStatusContainer">
        <Typography variant="h6">{temperatureTooHigh ? "Robot is overheating!" : "-"}</Typography>
        <Typography variant="h6">{pathErrorTooLarge ? "Robot is too close to the crop!" : "-"}</Typography>
        <Typography variant="h6">{crossTrackTooLarge ? "Robot is off the path!" : "-"}</Typography>
        <Typography variant="h6">{feelerFailure ? "Robot Feelers are broken!" : "-"}</Typography>
      </Stack>
    </Grid>
  );

  return (
    <>
      {cardContent}
    </>
  );
}

/**
 * Robot cards that display video thumbnail, latency, cutter status, battery, and name
 * 
 * @param {SharedPropsInterface} sharedProps - Properties shared across OverallRobotView
 * @returns {JSX.Element} React functional component
 */
export default function RobotCards(sharedProps: SharedPropsInterface): JSX.Element {
  const [filteredDevicesInfo, setFilteredDevicesInfo] = useState<DeviceInfo[]>([]);
  const navigate = useNavigate();

  const mapChannelRef = useRef(new BroadcastChannel("dualMonitorChannelMap"));

  /**
   * Render cards based on current filter
   */
  const renderCards = useCallback(() => {

    let filteredDevicesInfo = sharedProps.deviceInfo;

    // Filter by connection status
    filteredDevicesInfo = filteredDevicesInfo.filter((info) => {
      return ((info.isOnline && sharedProps.sharedFilter.connection_status.includes("online"))
        || (!info.isOnline && sharedProps.sharedFilter.connection_status.includes("offline"))
      )
    });

    // Sort based on selected option
    filteredDevicesInfo.sort((a, b) => {
      let sortingResult = 0;
      if (sharedProps.sharedFilter.sortOption === SortOption.SORT_BY_BATTERY) {
        if (a.battery < b.battery) {
          sortingResult = -1;
        } else if (a.battery > b.battery) {
          sortingResult = 1;
        }
      } else if (sharedProps.sharedFilter.sortOption === SortOption.SORT_BY_PING){
        if (a.pingMs < b.pingMs) {
          sortingResult = -1;
        } else if (a.pingMs > b.pingMs) {
          sortingResult = 1;
        }      
      } else if (sharedProps.sharedFilter.sortOption === SortOption.SORT_BY_ROBOT_NUMBER) {
        if (a.robotNumber < b.robotNumber) {
          sortingResult = -1;
        } else if (a.robotNumber > b.robotNumber) {
          sortingResult = 1;
        }
      }
      return sortingResult;
    })

    setFilteredDevicesInfo(filteredDevicesInfo);
  },
    [sharedProps.deviceInfoFetched, sharedProps.sharedFilter],
  );

  // Render cards with current filter
  useEffect(() => {
    renderCards();
  }, [sharedProps.sharedFilter, sharedProps.deviceInfoFetched, renderCards]);

  return (
    <Box className="RobotCards" sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
      {sharedProps.deviceInfo.length === 0 && (
        <LoadSpinner width={"50%"}/>
      )}
      <Grid container sx={{ overflow: 'auto', minWidth: '400px' }}>
        {filteredDevicesInfo.map((deviceInfo) =>
          <RobotCard
            key={deviceInfo.id} 
            deviceInfo={deviceInfo} 
            navigate={navigate} 
            mapChannelRef={mapChannelRef} 
            isDualMonitorInstance={sharedProps.isDualMonitorInstance}
            acknowledgedEvents={sharedProps.acknowledgedEvents}
          />
        )}
      </Grid>
    </Box>
  );
}