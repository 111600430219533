export enum BotComponentState {
  LIGHTS_ON = "LightsOn",
  LIGHTS_OFF = "LightsOff",
  EXTERNAL_VCU_FAN_ON = "ExternalVcuFanOn",
  EXTERNAL_VCU_FAN_OFF = "ExternalVcuFanOff",
  EXTERNAL_VCU_FAN_AUTO = "ExternalVcuFanAuto",
  CUTTERS_OFF = "BothOff",
  CUTTERS_LEFT_ON = "LeftOn",
  CUTTERS_RIGHT_ON = "RightOn",
  CUTTERS_BOTH_ON = "BothOn",
  FEELERS_OFF = "FeelersOff",
  FEELERS_ON = "FeelersOn",
}

export enum BotComponentName {
  LIGHTS = "Lights",
  EXTERNAL_VCU_FAN = "ExternalVcuFan",
  CUTTERS = "Cutters",
  FEELERS = "Feelers",
}

export const getCutterStatusString = (cutterStatus) => {
  switch(cutterStatus) {
    case BotComponentState.CUTTERS_OFF:
      return "Both Cutters are Off";
    case BotComponentState.CUTTERS_LEFT_ON:
      return "Left Cutter is On";
    case BotComponentState.CUTTERS_RIGHT_ON:
      return "Right Cutter is On";
    case BotComponentState.CUTTERS_BOTH_ON:
      return "Both Cutters are On";
    default:
      console.warn("Got unknown cutter status '" + cutterStatus + "'");
      return "Unknown Cutter Status";
  }
}

export const getFeelerStatusString = (feelerStatus) => {
  switch(feelerStatus) {
    case BotComponentState.FEELERS_OFF:
      return "Both Feelers are Off";
    case BotComponentState.FEELERS_ON:
      return "Both Feelers are On";
    default:
      console.warn("Got unknown feeler status '" + feelerStatus + "'");
      return "Unknown Feeler Status";
  }
}