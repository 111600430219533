
/**
 * Contains helper functions for scaling bot image on map
 */
export default class BotScaling {
    private static PIXEL_WIDTH_OF_BOT_IN_IMAGE = 724
    private static BOT_WIDTH_IN_METERS = 0.69215
    private static DEG_2_RADS = Math.PI/180
    private static EARTH_RADIUS_METERS = 6378134
    private static MAPBOX_TILE_SIZE = 512

  public static computeNewZoom(currZoom: number, latitude: number, startZoomLevel: number) {
    if (currZoom >= startZoomLevel) {

      const MetersPerPixel = BotScaling.getMetersPerPixelAtLatitude(latitude, currZoom);
      const botWidthInPixels = (BotScaling.BOT_WIDTH_IN_METERS / MetersPerPixel)
      const newScale = (botWidthInPixels) / BotScaling.PIXEL_WIDTH_OF_BOT_IN_IMAGE;

      return newScale
    }
  }

  private static getMetersPerPixelAtLatitude(latitude: number, zoom: number) {
    return Math.cos(latitude * BotScaling.DEG_2_RADS) * Math.PI * 2 * BotScaling.EARTH_RADIUS_METERS / (Math.pow(2, zoom) * BotScaling.MAPBOX_TILE_SIZE)
  }

}