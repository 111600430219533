import * as UiCore from "@formant/ui-sdk-realtime-player-core";
import React, { useEffect, useRef, useState } from "react";
import workerScript from '../../ts/workers/worker';
import DeviceInfo from "../../ts/interfaces/DeviceInfo";
import CSS from 'csstype';
import LoadSpinner from "./spinner";
import { Typography } from "@formant/ui-sdk";

interface RealtimePlayerProps {
    deviceInfo: DeviceInfo;
    width: number;
    height: number;
    videoIndex: number;
}

/**
 * Realtime video player for robot card thumbnails
 * 
 * @param {RealtimePlayerProps} props - properties for realtime video player
 * @returns {JSX.Element} React functional component
 */
export default function RealtimePlayer(props: RealtimePlayerProps): JSX.Element {
    const lifeCheckTimer = 3000;
    const noImageThreshold = 2500;
    const restartTimer = 2000;

    const workerRef = useRef<Worker>(null);
    const h264BytestreamCanvasDrawerRef = useRef(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const playerCanvasStyle: CSS.Properties = {
        height: String(props.height) + 'px',
        width: String(props.width) + 'px',
        backgroundColor: "black",
        borderRadius: "10px"
    };
    const listenerRef = useRef(null);

    const [videoIndex, setVideoIndex] = useState<number>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [noVideo, setNoVideo] = useState<boolean>(false);

    useEffect(() => {
        if (videoIndex !== null) {
            (async () => {
                await start();
            })();
            return function cleanup() {
                (async () => {
                    await stop(true);
                })();
            }
        }
    }, [videoIndex]);

    useEffect(() => {
        if (props.videoIndex !== videoIndex) {
            setVideoIndex(props.videoIndex);
        }
    })

    /**
     * Initialize video player
     * 
     * @async
     */
    const start = async () => {
        const device = props.deviceInfo.device;
        console.debug("Initializing video " + props.videoIndex + " for device " + device.name);
        setLoading(true);
        let restart = true;
        try {
            const videoStreams = await device.getRealtimeVideoStreams();
            if (videoStreams.length <= props.videoIndex) {
                restart = false;
                throw new Error("Video index is out of range for fetched video streams");
            }
            const videoStream = videoStreams[props.videoIndex];

            if(workerRef.current) {
                workerRef.current.terminate();
            }
            
            workerRef.current = new Worker(workerScript);
            h264BytestreamCanvasDrawerRef.current = new UiCore.H264BytestreamCanvasDrawer(
                () => workerRef.current
            );

            listenerRef.current = (_, message) => {
                if (message && message.header.stream.streamName === videoStream?.name) {
                    h264BytestreamCanvasDrawerRef.current.receiveEncodedFrame(
                        message.payload.h264VideoFrame
                    );
                }
            };
            device.addRealtimeListener(listenerRef.current);
            await device.startListeningToRealtimeVideo(videoStream);
            h264BytestreamCanvasDrawerRef.current.setCanvas(canvasRef.current);
            h264BytestreamCanvasDrawerRef.current.start();
            videoLiveCheck();
            setLoading(false);
        } catch (e) {
            if (restart) {
                console.log("Attempting to restart realtime video player for " + device.name);
                if (listenerRef.current) {
                    device.removeRealtimeListener(listenerRef.current);
                }
                try {
                    await stop(true);
                } catch (e) {
                    console.warn(e);
                }
                await new Promise(resolve => setTimeout(resolve, restartTimer));
                await start();
            } else {
                console.warn("Realtime video start error for ", device.name, ": ", e);
                setNoVideo(true);
                workerRef.current.terminate();
            }
        }
    };

    /**
     * Deconstruct video player
     * 
     * @async
     */
    const stop = async (deleteWorker: boolean) => {
        const device = props.deviceInfo.device;
        try {
            if (device.rtcClient) {
                const videoStreams = await device.getRealtimeVideoStreams();
                const videoStream = videoStreams[props.videoIndex];

                await device.stopListeningToRealtimeVideo(videoStream);
                console.log("Realtime video stop successful for ", device.name);
            }
        } catch (e) {
            console.warn("Realtime video stop error for ", device.name, ": ", e);
        }

        try {
            if (h264BytestreamCanvasDrawerRef.current) {
                h264BytestreamCanvasDrawerRef.current.stop();
                h264BytestreamCanvasDrawerRef.current = null;
                console.info("Stopping canvas drawer for", device.name);
            }
        } catch (e) {
            console.warn("Error stopping h264bytestreamcanvasdrawer", device.name, ": ", e);
        }

        try {
            if (listenerRef.current) {
                console.info("Removing listener for", device.name);
                device.removeRealtimeListener(listenerRef.current)
                listenerRef.current = null;
            }
        } catch (e) {
            console.warn("Error removing realtime listener", device.name, ": ", e);
        }

        if (deleteWorker) {
            console.info("Deleting worker for", device.name);
            workerRef.current.terminate();
            workerRef.current = null;
        }
    };

    const videoLiveCheck = async() => {
        await new Promise(resolve => setTimeout(resolve, lifeCheckTimer));
        const currentImage = canvasRef.current.toDataURL();
        if (currentImage.length < noImageThreshold) {
            console.warn("Image not found for index " + props.videoIndex + ". Restarting...");
            await stop(true);
            start();
        } else {
            console.log("Realtime video start successful for ", props.deviceInfo.device.name);
        }
    } 

    const noVideoDisplay = () => {
        let element = <div></div>;
        if (loading) {
            if (noVideo) {
                element = <Typography style={{position: "relative", textAlign: "center", width: props.width+"px"}}>Video Unavailable</Typography>
            } else {
                element = <LoadSpinner width={props.width+"px"} height={((props.height / 2)-33)+"px"}></LoadSpinner>
            }
        }
        return element;
    }

    return (
        <div className="RealtimePlayerContainer" style={{width: props.width + "px", height: props.height + "px"}}>
            {noVideoDisplay()}
            <canvas className="RealtimePlayerCanvas" ref={canvasRef} style={playerCanvasStyle} />
        </div>
    );
}