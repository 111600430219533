import DeviceInfo from "../../ts/interfaces/DeviceInfo";
import { Authentication, Device, SessionType } from "@formant/data-sdk";
import { BotState } from "../../ts/interfaces/BotState";
import BotEvent from "../../ts/interfaces/BotEvent";
import SeverityNames, { convertToFormantSeverityName } from "../EventsDialogBox/SeverityNames";
import { floor } from "lodash";
import { MISSING_SOFTWARE_VERSION, UNKNOWN_SOFTWARE_VERSION, softwareNameLookup } from "../../ts/interfaces/SoftwareVersions";
import { BotComponentName, BotComponentState } from "../../ts/interfaces/ComponentState";

/**
 * Contains helper functions for fetching device data from Formant
 * and parsing into DeviceInfo array element(s)
 */
export default class DeviceFetch {
  private static batteryStreamName = "battery";
  private static odometryStreamName = "odom.heading";
  private static geoIPStreamName = "gps.fix";
  private static pathStreamName = "path";
  private static botOpStateStreamName = "BotState";
  private static eventArrayStreamName = "EventArray";
  private static botRegisterStreamName = "BotRegister";

  private static videoChannelOptions = {
    ordered: false,
    maxRetransmits: 0,
  };

  /**
   * Fetches telemetry data and parses into updated deviceInfo array
   * 
   * @async
   * @param {DeviceInfo} deviceInfo - DeviceInfo array to be updated 
   * @returns Updated DeviceInfo array
   */
  public static async updateDeviceInfo(device: Device, currentDeviceOnline: boolean, deviceInfo?: DeviceInfo): Promise<DeviceInfo> {
    let realtimePing = 0;
    let rtcInitialized = false;
    let connectionStatus = "disconnected";

    try {
      if (currentDeviceOnline && device.rtcClient) {
        const remoteDevicePeerId = device.remoteDevicePeerId ?? (await device.getRemotePeer()).id
        connectionStatus = device.rtcClient.getConnectionStatus(remoteDevicePeerId);
        rtcInitialized = connectionStatus === "connected";
      }
    } catch (e) {
      console.warn(e);
    }

    try {
      if(currentDeviceOnline && !rtcInitialized) {
        if (device.rtcClient && !(connectionStatus === "connecting")) {
          device.rtcClient.connect((await device.getRemotePeer()).id);
        } else if (!(connectionStatus === "connecting")){
          await device.startRealtimeConnection(SessionType.Observe);
        } else if (deviceInfo) {
          this.cleanRealtimeListeners(deviceInfo);
        }
      }
    } catch (e) {
      console.warn(e);
    }

    try {
      if (currentDeviceOnline && rtcInitialized) {
        const res = device.getRealtimePing();
        if (res !== null && res !== undefined) {
          realtimePing = res;
        }
      }
    } catch (e) {
      console.warn(e);
    }

    let newDeviceInfo = deviceInfo ??
    {
      device: device,
      id: device.id,
      isOnline: currentDeviceOnline,
      lightsStatus: BotComponentState.LIGHTS_OFF,
      cutterStatus: BotComponentState.CUTTERS_OFF,
      vcuFanStatus: BotComponentState.EXTERNAL_VCU_FAN_OFF,
      feelerStatus: BotComponentState.FEELERS_OFF,
      robotNumber: 0,
      battery: 0,
      pingMs: realtimePing,
      videoDate: new Date("1900"),
      odometryURL: "",
      longitude: null,
      latitude: null,
      path: "",
      videoStreamChannelFront: null,
      videoStreamChannelRear: null,
      realtimeGpsChannel: null,
      realtimeOdomChannel: null,
      realtimePathChannel: null,
      realtimeRmfChannel: null,
      realtimeRosCommandChannel: null,
      realtimeEventArrayChannel: null,
      botState: null,
      botOwner: "None",
      events: [],
      telemetryTimestamps: {
        robotNumber: new Date("1900"),
        gps: new Date("1900"),
        odometry: new Date("1900"),
        path: new Date("1900"),
        battery: new Date("1900"),
        botOpState: new Date("1900"),
        eventArray: new Date("1900"),
        botRegister: new Date("1900"),
      },
      softwareVersions: {
        weedbot: UNKNOWN_SOFTWARE_VERSION,
        mcu: UNKNOWN_SOFTWARE_VERSION,
        pixhawk: UNKNOWN_SOFTWARE_VERSION,
        formantAdapter: UNKNOWN_SOFTWARE_VERSION,
      }
    };

    if (newDeviceInfo.softwareVersions.formantAdapter === UNKNOWN_SOFTWARE_VERSION) {
      const deviceConfiguration = await device.getConfiguration();
      let formantAdapterVersion = "missing";
      if (deviceConfiguration) {
        if (deviceConfiguration.adapters) {
          // TODO: support devices with multiple adapters
          formantAdapterVersion = deviceConfiguration.adapters[0].name;
        }
      } else {
        console.warn("Configuration could not be retrieved for device " + device.id);
      }
      newDeviceInfo.softwareVersions.formantAdapter = formantAdapterVersion;
    }

    let robotTopicName = "robot" + newDeviceInfo.robotNumber + ".";

    let streamName = "";
    if (newDeviceInfo.isOnline && rtcInitialized) {
      try {
        if (!newDeviceInfo.realtimeGpsChannel) {
          streamName = 'gps_info';
          newDeviceInfo.realtimeGpsChannel = await device.createCustomDataChannel(streamName);
        }
        if (!newDeviceInfo.realtimeOdomChannel) {
          streamName = 'odom_info';
          newDeviceInfo.realtimeOdomChannel = await device.createCustomDataChannel(streamName);
        }
        if (!newDeviceInfo.realtimePathChannel) {
          streamName = 'path_info';
          newDeviceInfo.realtimePathChannel = await device.createCustomDataChannel(streamName);
        }
        if (!newDeviceInfo.realtimeRmfChannel) {
          streamName = 'rmf-ms-command';
          newDeviceInfo.realtimeRmfChannel = await device.createCustomDataChannel(streamName);
        }
        if (!newDeviceInfo.realtimeRosCommandChannel) {
          streamName = 'ros-command';
          newDeviceInfo.realtimeRosCommandChannel = await device.createCustomDataChannel(streamName);
        }
        if (!newDeviceInfo.realtimeEventArrayChannel) {
          streamName = "EventArray"
          newDeviceInfo.realtimeEventArrayChannel = await device.createCustomDataChannel(streamName);
        }
      } catch (e) {
        console.warn(newDeviceInfo.device.name + ": " + e + " (" + streamName + ")");
      }
    }

    const softwareVersionMissing = Object.keys(newDeviceInfo.softwareVersions).some((k) => { return newDeviceInfo.softwareVersions[k] === UNKNOWN_SOFTWARE_VERSION });
    if (newDeviceInfo.isOnline && rtcInitialized && newDeviceInfo.realtimeRosCommandChannel && softwareVersionMissing) {
      // set versions to "missing" to indicate they have been queried for
      newDeviceInfo.softwareVersions.weedbot = MISSING_SOFTWARE_VERSION;
      newDeviceInfo.softwareVersions.mcu = MISSING_SOFTWARE_VERSION;
      newDeviceInfo.softwareVersions.pixhawk = MISSING_SOFTWARE_VERSION;
      // send bot register request
      const ROS_COMMAND = "ros2 topic pub -1 /BotRegisterRequest std_msgs/msg/String 'data: Request'";
      console.log("Requesting bot register information (" + ROS_COMMAND + ")");
      newDeviceInfo.realtimeRosCommandChannel.send(ROS_COMMAND);
    }

    newDeviceInfo.isOnline = currentDeviceOnline;
    newDeviceInfo.pingMs = realtimePing;
    
    const telemetries = await device.getLatestTelemetry();
    for (let j = 0; j < telemetries.length; j++) {
      const telemetry = telemetries[j];
      // use the currentValueTime to determine if the telemetry is giving new data ("new" is relative to the data already saved in deviceInfo)
      // if telemetry is giving us new data, update the corresponding deviceInfo field and telemetryTimestamps field
      const telemetryTimestamp = new Date(telemetry.currentValueTime);
      const canUpdateRobotNumber = telemetryTimestamp > newDeviceInfo.telemetryTimestamps.robotNumber;
      if (newDeviceInfo.robotNumber === 0 && telemetry.streamName.includes("robot") && canUpdateRobotNumber) {
        newDeviceInfo.robotNumber = parseInt(telemetry.streamName.replace("robot", "").split(".")[0]);
        robotTopicName = "robot" + newDeviceInfo.robotNumber + ".";
        newDeviceInfo.telemetryTimestamps.robotNumber = telemetryTimestamp;
      }
      switch (telemetry.streamName) {
        case (robotTopicName + this.batteryStreamName):
          if (telemetryTimestamp > newDeviceInfo.telemetryTimestamps.battery) {
            newDeviceInfo.battery = (telemetry.currentValue as Record<string, unknown>).percentage as number;
            newDeviceInfo.telemetryTimestamps.battery = telemetryTimestamp;
          }
          break;
        case (robotTopicName + this.odometryStreamName):
          if (!newDeviceInfo.realtimeOdomChannel && telemetryTimestamp > newDeviceInfo.telemetryTimestamps.odometry) {
            newDeviceInfo.odometryURL = telemetry.currentValue as string;
            newDeviceInfo.telemetryTimestamps.odometry = telemetryTimestamp;
          }
          break;
        case (robotTopicName + this.geoIPStreamName):
          if (!newDeviceInfo.realtimeGpsChannel && telemetryTimestamp > newDeviceInfo.telemetryTimestamps.gps) {
            newDeviceInfo.latitude = (telemetry.currentValue as Record<string, unknown>).latitude as number;
            newDeviceInfo.longitude = (telemetry.currentValue as Record<string, unknown>).longitude as number;
            newDeviceInfo.telemetryTimestamps.gps = telemetryTimestamp;
          }
          break;
        case (robotTopicName + this.pathStreamName):
          if (!newDeviceInfo.realtimePathChannel && telemetryTimestamp > newDeviceInfo.telemetryTimestamps.path) {
            newDeviceInfo = await this.setBotPath(telemetry.currentValue as string, newDeviceInfo);
            newDeviceInfo.telemetryTimestamps.path = telemetryTimestamp;
          }
          break;
        case (robotTopicName + this.botOpStateStreamName):
          if (telemetryTimestamp > newDeviceInfo.telemetryTimestamps.botOpState) {
            newDeviceInfo = await this.setBotOpState(telemetry.currentValue as string, newDeviceInfo);
            newDeviceInfo.telemetryTimestamps.botOpState = telemetryTimestamp;
          }
          break;
        case (this.eventArrayStreamName):
          if (telemetryTimestamp > newDeviceInfo.telemetryTimestamps.eventArray) {
            newDeviceInfo = await this.setBotEvents(telemetry.currentValue as string, newDeviceInfo);
            newDeviceInfo.telemetryTimestamps.eventArray = telemetryTimestamp;
          }
          break;
        case (this.botRegisterStreamName):
          if (telemetryTimestamp > newDeviceInfo.telemetryTimestamps.botRegister) {
            newDeviceInfo = await this.setDeviceSoftwareVersions(telemetry.currentValue as string, newDeviceInfo);
            newDeviceInfo.telemetryTimestamps.botRegister = telemetryTimestamp;
          }
          break;
        default:
          break;
      }
    }

    console.debug("Device: " + newDeviceInfo.robotNumber + " Connection status: " + connectionStatus + " Realtime ping: " + newDeviceInfo.pingMs);

    return newDeviceInfo;
  }

  /**
   * Returns updated DeviceInfo object with bot path data
   * @async
   * @param {string} url - Bot path URL
   * @param {DeviceInfo} info - Device info to be updated and returned
   * @returns {DeviceInfo} Updated DeviceInfo object based on passed info parameter
   */
   private static async setBotPath(url: string, info: DeviceInfo): Promise<DeviceInfo> {
    const json = await getTelemetryJSON(url);
    info.path = JSON.stringify(json);
    return info;
  }

  // TODO: rename to setBotState
  /**
   * Returns updated DeviceInfo object with bot path data
   * @async
   * @param {string} url - Bot Op State URL
   * @param {DeviceInfo} info - Device info to be updated and returned
   * @returns {DeviceInfo} Updated DeviceInfo object based on passed info parameter
   */
  private static async setBotOpState(url: string, info: DeviceInfo): Promise<DeviceInfo> {
    const json = await getTelemetryJSON(url);

    info.botOwner = json.state.turker_id === "" ? "None" : json.state.turker_id;

    const botOpState = json.state.bot_op_state;
    switch (botOpState) {
      case 1:
        info.botState = BotState.E_STOP;
        break;
      case 2:
        info.botState = BotState.IDLE;
        break;
      case 3:
        info.botState = BotState.RC;
        break;
      case 4:
        info.botState = BotState.TELEOP;
        break;
      case 5:
        info.botState = BotState.READY;
        break;
      case 6:
        info.botState = BotState.RUNNING;
        break;
      case 7:
        info.botState = BotState.AUTO_PAUSE;
        break;
      case 8:
        info.botState = BotState.CUTTER_RESTART;
        break;
      default:
        info.botState = null;
        break;
    }
    json.state.components.forEach((component) => {
      switch(component.component_name) {
        case (BotComponentName.LIGHTS):
          info.lightsStatus = component.component_state;
          break;
        case (BotComponentName.CUTTERS):
          info.cutterStatus = component.component_state;
          break;
        case (BotComponentName.EXTERNAL_VCU_FAN):
          info.vcuFanStatus = component.component_state;
          break;
        case (BotComponentName.FEELERS):
          info.feelerStatus = component.component_state;
          break;
        default:
          console.warn("Got status for unknown component '" + component.component_name + "'");
      }
    });
    return info;
  }

  private static async setDeviceSoftwareVersions(url: string, info: DeviceInfo): Promise<DeviceInfo> {
    const json = await getTelemetryJSON(url);
    const registerRobotNumber = json.header.robot_id.slice(5);
    if (Number(registerRobotNumber) !== Number(info.robotNumber)) {
      return info;
    }
    const resourceInfo = json.resource_info;
    for (let k = 0; k < resourceInfo.length; k++) {
      const resourceId = resourceInfo[k].resource_id;
      const version = resourceInfo[k].version;
      switch (resourceId) {
        case softwareNameLookup.weedbot:
          info.softwareVersions.weedbot = version;
          break;
        case softwareNameLookup.mcu:
          info.softwareVersions.mcu = version;
          break;
        case softwareNameLookup.pixhawk:
          info.softwareVersions.pixhawk = version;
          break;
      }
    }
    return info;
  }

  /**
   * Clears any listeners that are setup on RTC channels
   * @async
   * @param {DeviceInfo} device - Device to have listeners cleared
   */
  public static cleanRealtimeListeners(device: DeviceInfo): void {
    console.debug("Clearing realtime listeners for bot: " + device.robotNumber);

    if (device.realtimeGpsChannel && device.realtimeGpsChannel.listeners.length > 0) {
      for (let i = 0; i < device.realtimeGpsChannel.listeners.length; i++) {
        device.realtimeGpsChannel.removeListener(device.realtimeGpsChannel.listeners[i]);
      }
    }
    if (device.realtimeOdomChannel && device.realtimeOdomChannel.listeners.length > 0) {
      for (let i = 0; i < device.realtimeOdomChannel.listeners.length; i++) {
        device.realtimeOdomChannel.removeListener(device.realtimeOdomChannel.listeners[i]);
      }
    }
    if (device.realtimePathChannel && device.realtimePathChannel.listeners.length > 0) {
      for (let i = 0; i < device.realtimePathChannel.listeners.length; i++) {
        device.realtimePathChannel.removeListener(device.realtimePathChannel.listeners[i]);
      }
    }
  }

  private static async setBotEvents(url: string, info: DeviceInfo): Promise<DeviceInfo> {
    const json = await getTelemetryJSON(url);
    const eventRobotNumber = json.header.robot_id.slice(5);
    if (Number(eventRobotNumber) !== Number(info.robotNumber)) {
      return info;
    }
    const newEvents = [];
    const deviceEvents = json.events;
    deviceEvents.forEach((event) => {
      // TODO: generate IDs for events (at least unique within this bot)
      //       unless the IDs come from the bots, we still need this fuzzy matching to determine if a new ID is needed
      //const oldMatchingEvent = info.events.find((e) => { return eventsMatch(e, event); });
      const timestampDateObject = new Date(event.stamp.sec * 1000 + floor(event.stamp.nanosec / 1000000));
      const newEvent = {
        stamp: event.stamp,
        timestamp: timestampDateObject,
        pathId: event.path_id,
        name: event.event_name,
        severity: event.severity,
        description: event.description,
        data: event.event_data,
      };
      // info-level events should not be viewable in rosie
      if (newEvent.severity !== SeverityNames.info) {
        newEvents.push(newEvent);
      }
      this.postBotEventToFormant(info.id, newEvent);
    });
    info.events = newEvents;
    return info;
  }

  private static async postBotEventToFormant(deviceId: string, botEvent: BotEvent) {
    const eventType = "custom";
    const formantSeverity = convertToFormantSeverityName(botEvent.severity);

    const getOptions = {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
        authorization: 'Bearer ' + Authentication.token,
      },
      body: JSON.stringify({
        deviceIds: [deviceId],
        start: botEvent.timestamp,
        severities: [formantSeverity],
        message: botEvent.description,
      }),
    }
    const response = await fetch("https://api.formant.io/v1/admin/events/query", getOptions);
    const json = await response.json();
    if (json.items.length > 0) {
      console.info("Event already present in Formant with Event ID " + json.items[0].id);
      return
    }

    const postOptions = {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
        authorization: 'Bearer ' + Authentication.token,
      },
      body: JSON.stringify({
        type: eventType, 
        severity: formantSeverity,
        time: botEvent.timestamp.toISOString(),
        message: botEvent.description,
        deviceId: deviceId,
      }),
    }
    try {
      await fetch("https://api.formant.io/v1/admin/custom-events", postOptions);
    } catch (e) {
      // TODO: determine if this printed message is sufficient or if we need to print extra info
      console.warn(e)
    }
  }
}

export async function getTelemetryJSON(value: string) {
  let json = null;
  let valueIsUrl = null;
  try {
    valueIsUrl = Boolean(new URL(value));
  } catch(e) { 
    valueIsUrl = false;
  }
  if (valueIsUrl) {
    const res = await fetch(value);
    json = res.json();
  } else {
    json = JSON.parse(value);
  }
  return json;
}