import React, { useState, useEffect } from "react";
import "./RobotFilteringMenus.css";
import { Box } from "@formant/ui-sdk";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import SharedPropsInterface from "../../ts/interfaces/SharedPropsInterface";
import { SortOption } from "../../ts/enums/SortOption";
import { FilterOption } from "../../ts/enums/FilterOption";

/**
 * Dropdowns for filtering and sorting robot cards
 *
 * @param {SharedPropsInterface} sharedProps - Properties shared across OverallRobotView
 * @returns {JSX.Element} React functional component
 */
export default function RobotFilteringMenus(
  sharedProps: SharedPropsInterface
): JSX.Element {
  const { sharedFilter, setSharedFilter } = sharedProps;

  const [filterOption, setFilterOption] = useState(FilterOption.SHOW_ALL);
  const [sortOption, setSortOption] = useState(SortOption.SORT_BY_ROBOT_NUMBER);

  // If shared filter changes, set dropdown state
  useEffect(() => {
    switch (sharedFilter.connection_status) {
      case ["online", "offline"]:
        setFilterOption(FilterOption.SHOW_ALL);
        break;
      case ["online"]:
        setFilterOption(FilterOption.AVAILABLE_ONLY);
        break;
      case ["offline"]:
        setFilterOption(FilterOption.UNAVAILABLE_ONLY);
        break;
      default:
        break;
    }
  }, [sharedFilter]);

  /**
   * On sort option select handler, set shared filter and sort option state on select
   *
   * @param {SelectChangeEvent<string>} e
   */
  const onSelectSortOption = (e: SelectChangeEvent<string>) => {
    const newSharedFilter = { ...sharedFilter };
    newSharedFilter.sortOption = e.target.value as SortOption;
    setSharedFilter(newSharedFilter);
    setSortOption(e.target.value as SortOption);
  };

  /**
   * On filter select handler, set sharedFilter based on selection
   *
   * @param {SelectChangeEvent<string>} e
   */
  const onSelectFilterOption = (e: SelectChangeEvent<string>) => {
    const newSharedFilter = { ...sharedFilter };
    switch (e.target.value) {
      case FilterOption.SHOW_ALL:
        newSharedFilter.connection_status = ["online", "offline"];
        break;
      case FilterOption.AVAILABLE_ONLY:
        newSharedFilter.connection_status = ["online"];
        break;
      case FilterOption.UNAVAILABLE_ONLY:
        newSharedFilter.connection_status = ["offline"];
        break;
      default:
        break;
    }
    setSharedFilter(newSharedFilter);
    setFilterOption(e.target.value as FilterOption);
  };

  /**
   * Return select component based on params
   * @param {string} value - Current value of Select component
   * @param {SelectChangeEvent<string>} onChange - On change handler to be called
   * @param {string[]} items - String array of menu items to render
   * @returns {JSX.Element} React functional component
   */
  const getSelect = (
    value: string,
    onChange: (e: SelectChangeEvent<string>) => void,
    items: string[]
  ) => {
    return (
      <Select
        value={value}
        defaultValue={value}
        onChange={(e) => onChange(e)}
        sx={{
          marginRight: "10px",
          backgroundColor: "transparent",
          border: "0px",
        }}
      >
        {items.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    );
  };

  return (
    <div>
      <Box
        sx={{
          p: 2,
          justifyContent: "right",
          alignItems: "center",
          display: "flex",
        }}
      >
        {getSelect(sortOption, onSelectSortOption, Object.values(SortOption))}
        {getSelect(
          filterOption,
          onSelectFilterOption,
          Object.values(FilterOption)
        )}
      </Box>
    </div>
  );
}
