// Assets
import CutterIcon from "../../Assets/Icons/Robots Inner Page/Mask group-3.png";
import PingIcon from "../../Assets/Icons/Robots Inner Page/Mask group-2.png";
import LightsIcon from "../../Assets/Icons/Dashboard/Group 2470.png";
import FanIcon from "../../Assets/Icons/Robots Inner Page/fan-small-white-2.png";
import FeelerIcon from "../../Assets/Icons/Robots Inner Page/touch-small-white-2.png";
import "./ComponentStateIcon.css";

import React, { useState } from "react";
import { BotComponentName, BotComponentState, getCutterStatusString, getFeelerStatusString } from "../../ts/interfaces/ComponentState";
import { Collapse, Popover, Stack, Typography } from "@mui/material";

interface ComponentStateIconProps {
  componentName: BotComponentName | "Ping",
  componentState: BotComponentState | number,
}

export default function ComponentStateIcon (props: ComponentStateIconProps) {
  const [statePopoverAnchor, setStatePopoverAnchor] = useState<HTMLElement | null>(null);
  const statePopoverOpen = Boolean(statePopoverAnchor);
  const handleStatePopoverClose = () => { setStatePopoverAnchor(null); }
  const handleStatePopoverOpen = (event) => { setStatePopoverAnchor(event.currentTarget); }

  let componentImageSource = null;
  let componentIsOn = false;
  let componentDetailedState = "";

  switch(props.componentName) {
    case BotComponentName.CUTTERS:
      componentImageSource = CutterIcon;
      componentIsOn = props.componentState === BotComponentState.CUTTERS_OFF ? false : true;
      componentDetailedState = getCutterStatusString(props.componentState);
      break;
    case BotComponentName.LIGHTS:
      componentImageSource = LightsIcon;
      componentIsOn = props.componentState === BotComponentState.LIGHTS_OFF ? false : true;
      componentDetailedState = props.componentState === BotComponentState.LIGHTS_OFF ? "Lights are Off" : "Lights are On";
      break;
    case BotComponentName.EXTERNAL_VCU_FAN:
      componentImageSource = FanIcon;
      componentIsOn = props.componentState === BotComponentState.EXTERNAL_VCU_FAN_OFF ? false : true;
      componentDetailedState = props.componentState === BotComponentState.EXTERNAL_VCU_FAN_OFF ? "VCU Fan is Off" : "VCU Fan is On";
      break;
    case BotComponentName.FEELERS:
      componentImageSource = FeelerIcon;
      componentIsOn = props.componentState === BotComponentState.FEELERS_OFF ? false : true;
      componentDetailedState = getFeelerStatusString(props.componentState);
      break;
    case "Ping":
      componentImageSource = PingIcon;
      componentIsOn = props.componentState as number <= 0 ? false : true;
      componentDetailedState = props.componentState as number <= 0 ? "Ping: " + props.componentState as string + "ms" : "Ping: N/A";
      break;
  }

  return (
    <button 
      className={componentIsOn ? "ComponentStateIconOn" : "ComponentStateIconOff"}
      onMouseEnter={handleStatePopoverOpen} 
      onMouseLeave={handleStatePopoverClose}
    >
      <img src={componentImageSource} alt="Missing Asset" style={{ minWidth: "3rem" }}/>
      <Popover 
        sx={{
          pointerEvents: 'none',
        }}
        slotProps={{
          paper: {
            sx: {
              boxShadow: "1px 1px lightblue"
            },
          }
        }}
        TransitionComponent={Collapse}
        open={statePopoverOpen}
        anchorEl={statePopoverAnchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handleStatePopoverClose}
      >
        <Typography variant="h6" className="ComponentStateText">{statePopoverOpen && componentDetailedState}</Typography>
      </Popover>
    </button>
  );
}