import React from "react";
import "./spinner.css";

interface LoadSpinnerProps {
  width?: string;
  height?: string;
}

/**
  * Loading spinner
  */
export default function LoadSpinner(props: LoadSpinnerProps) {
  return (
    <div className="SpinnerContainer" style={{width: props.width, marginTop: props.height}}>
      <div className="LoadingSpinner"></div>
      Loading...
    </div>
  );
}